// handleSubTableRowClick
import API from '@/api';
import _ from 'lodash';
import dayjs from 'dayjs';
export default {
  /**@Description :  ******************************************* 子任务点击查看按钮，显示任务详情抽屉
   **@Date: 2024-05-15 09:27:31
   */
  async handleSubTableRowClick(subIndex, row, column, mainIndex, mainRow) {
    console.log('子任务列表查看，子任务索引', subIndex);
    console.log('子任务列表查看，子任务行数据', row);
    console.log('子任务列表查看，子任务查看标签', column);
    console.log('子任务列表查看，主任务行索引', mainIndex);
    console.log('子任务列表查看，主任务行数据', mainRow);
    this.currentMainTaskIndex = mainIndex;
    // 获取该子任务的主任务
    // this.task.taskDetailData.mainTaskName = mainRow.taskName;
    // this.task.taskDetailData.mainTaskId = mainRow.id;
    // this.task.taskDetailData.mainTaskcreateTime = mainRow.createTime;
    // this.task.taskDetailData.mainTaskType = mainRow.taskType == 'once' ? '单次任务' : '周期任务';
    // this.task.taskDetailData.mainTaskSource =  mainRow.source == 'default' ? '手动新建' : '警情通知';
    // this.task.taskDetailData.mainTaskTarget =  _.find(this.taskPurposeList, (item) => item.value == mainRow.taskTarget)?.label || '';
    // this.task.taskDetailData.subtaskList = mainRow.subtaskList;
    this.task.taskDetailData = mainRow;
    this.task.subTaskSelectedIndex = subIndex;
    if (row.taskStatus == 0) {
      this.task.subtaskCancelFlag = true;
      this.task.subtaskExecuteFlag = true;
    } else {
      this.task.subtaskCancelFlag = false;
      this.task.subtaskExecuteFlag = false;
    }
    // 如果是点击 立即执行 ，则不显示任务详情抽屉
    if (column?.label == '立即执行') {
      this.task.drawerTaskDetailVisible = false;
    } else if (column?.label == '查看' || column?.label == '周期任务') {
      this.task.drawerTaskDetailVisible = true;
      // 获取当前子任务的航线详细信息，根据子任务id去查询航线信息
      let res = await API.TASK.FlightDetail(row.id);
      console.log('根据任务id获取的航线详情', res);
      if (res.flightCourseJson) {
        let flightCourseJson = JSON.parse(res.flightCourseJson);
        let points = flightCourseJson.points;
        this.initCesiumViewer(points, row);
      } else {
        this.initCesiumViewer([], row);
      }
      // 获取子任务的操作记录
      this.task_getSubTaskLogList(row.id);
    }
  },
  /**@Description :  ******************************************* 从任务目的列表中根据id获取目的名称
   **@Date: 2024-07-05 10:16:50
  */
  getTaskTargetById(v) {
    return _.find(this.taskPurposeList, (item) => item.value == v)?.label || '';
  },
  /**@Description :  ******************************************* 格式化坐标，保留四位小数点 (119.092321, 20.232122)
   **@Date: 2024-05-15 11:35:55
   */
  formatCoord(coord) {
    let c = '';
    try {
      let a = coord.replace('(', '').replace(')', '');
      // console.log('a', a);
      let b = a.split(',');
      c =
        '(' +
        parseFloat(b[0]).toFixed(4) +
        ',' +
        parseFloat(b[1]).toFixed(4) +
        ')';
      return c;
    } catch (e) {
      return '';
    }
  },
  /**@Description :  ******************************************* 点击任务详情子任务切换子任务信息
   **@Date: 2024-05-15 12:01:24
   */
  async handleSubTaskSelected(item, index) {
    this.task.subTaskSelectedIndex = index;
    // 每一个子任务都绑定的有一条航线，周期任务可以理解为公用一条航线
    let res = await API.TASK.FlightDetail(item.id);
    console.log('根据子任务的id查询任务关联的航线的返回值', res);
    if (!res.flightCourseJson) {
      this.$message({
        type: 'warning',
        message: `该任务航线信息不存在`,
      });
      return;
    }
    let flightCourseJson = JSON.parse(res.flightCourseJson);
    let points = flightCourseJson.points;
    // 绘制航线
    this.initCesiumViewer(points, item);
    this.task_getSubTaskLogList(item.id);
  },
  /**@Description :  ******************************************* 获取子任务操作记录
   **@Date: 2024-05-15 14:56:52
   */
  async getSubTaskLogList(subId) {
    let res = await API.WHXF.getSubTaskLog(subId);
    console.log('子任务操作记录', res);
    this.task.subTaskLogList = res;
  },
  /**@Description :  ******************************************* 任务详情取消订单  取消子任务
   **@Date: 2024-05-15 15:48:44
   */
  async handleDrawerTaskDetailCancel() {
    console.log(
      '任务详情里面，子任务取消，子任务选中索引',
      this.task.subTaskSelectedIndex
    );
    console.log(
      '任务详情里面，子任务取消，选中的子任务数据',
      this.task.taskDetailData.subtaskList[this.task.subTaskSelectedIndex]
    );
    // 如果该订单已经处于取消状态，则提示
    let subTask =
      this.task.taskDetailData.subtaskList[this.task.subTaskSelectedIndex];
    let text = '';
    if (subTask.taskStatus == 0) {
      text = '待生效';
    } else if (subTask.taskStatus == 3) {
      text = '执行中';
    } else if (subTask.taskStatus == 99) {
      text = '已取消';
    } else if (subTask.taskStatus == 1) {
      text = '已完成';
    }
    if (
      subTask.taskStatus == 99 ||
      subTask.taskStatus == 1 ||
      subTask.taskStatus == 3
    ) {
      this.$message({
        type: 'warning',
        message: `该子任务已经处于 ${text} 状态，请勿重复操作！`,
      });
      return;
    }
    this.$confirm(`确认要取消该子任务吗?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        // 子任务取消
        let data = { taskId: subTask.id, status: 99 };
        let res = await API.WHXF.subTaskStatusUpdate(data);
        if (res) {
          let mainRow = this.tableData[this.currentMainTaskIndex];
          let res1 = await API.WHXF.getSubTaskList(mainRow.id);
          let resSubTask = null;
          // 根据已选择的子任务的id,选择更新后后台返回的子任务列表里面对应的子任务
          res1.subtaskList.forEach((v, index) => {
            if (v.id == subTask.id) {
              resSubTask = v;
            }
          });
          // 主任务列表里面子任务更新
          mainRow.subtaskList.forEach((v, index) => {
            if (v.id == subTask.id) {
              v.taskStatus = resSubTask.taskStatus;
            }
          });
          // 主任务状态更新
          mainRow.status = res1.status;
          // 任务详情里面子任务状态更新
          this.task.taskDetailData.subtaskList.forEach((v, index) => {
            if (v.id == subTask.id) {
              v.taskStatus = resSubTask.taskStatus;
            }
          });
          //请求操作记录
          this.task_getSubTaskLogList(subTask.id);
        } else {
          this.$message({
            type: 'error',
            message: '当前子任务无法取消',
          });
        }
      })
      .catch(() => { });
  },
  /**@Description :  ******************************************* 任务详情执行订单 立即执行子任务，需要跳转到协同视图页面
   **@Date: 2024-05-15 15:49:27
   */
  async handleDrawerTaskDetailExecute() {
    console.log('任务详情中选中的子任务索引', this.task.subTaskSelectedIndex);
    console.log(
      '任务详情里面选中的子任务',
      this.task.taskDetailData.subtaskList[this.task.subTaskSelectedIndex]
    );
    console.log('任务详情里面主任务索引', this.currentMainTaskIndex);
    console.log(
      '任务详情里面主任务',
      this.tableData[this.currentMainTaskIndex]
    );
    // 只有待生效的时候，才能进行立即执行
    let subTask =
      this.task.taskDetailData.subtaskList[this.task.subTaskSelectedIndex];
    let text = '';
    if (subTask.taskStatus == 0) {
      text = '待生效';
    } else if (subTask.taskStatus == 3) {
      text = '执行中';
    } else if (subTask.taskStatus == 99) {
      text = '已取消';
    } else if (subTask.taskStatus == 1) {
      text = '已完成';
    }
    if (subTask.taskStatus != 0) {
      this.$message({
        type: 'warning',
        message: `该子任务已经处于 ${text} 状态，请勿重复操作！`,
      });
      return;
    }
    this.$confirm(`确认要立即执行该子任务吗?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        // 将子任务状态修改为 立即执行
        let mainRow = this.tableData[this.currentMainTaskIndex];
        let subIndex = this.task.subTaskSelectedIndex;
        let data2 = {
          taskId: subTask.id,
          status: 3,
        };
        let res3 = await API.WHXF.subTaskStatusUpdate(data2);
        if (res3) {
          /** 
         * 1 要更新主任务的任务状态
           2 更新该子任务的预计起飞时间
           3 更新该子任务的任务状态
        **/
          // 根据主任务id，查询主任务详情
          let res1 = await API.WHXF.getSubTaskList(mainRow.id);
          console.log('主任务详情', res1);
          // 1 要更新主任务的任务状态
          mainRow.status = res1.status;
          // 2 更新该子任务的预计起飞时间
          let resSubTask = null;
          res1.subtaskList.forEach((v, index) => {
            if (v.id == subTask.id) {
              resSubTask = v;
            }
          });
          subTask.startedAt = resSubTask.startedAt;
          // 3 更新该子任务的任务状态
          subTask.taskStatus = resSubTask.taskStatus;
          // 4 更新操作记录
          this.task_getSubTaskLogList(subTask.id);
        }
      })
      .catch(() => { });
  },
  /**@Description :  ******************************************* 任务详情里面，点击协同视图按钮
   **@Date: 2024-05-15 15:49:55
   */
  handleDrawerTaskDetailCoordination() {
    this.$nextTick(async () => {
      // 主任务详情
      let mainTask = this.tableData[this.currentMainTaskIndex];
      this.task_handleMainTaskCollaborativeView(this.currentMainTaskIndex, mainTask);
    });
  },
  /**@Description :  ******************************************* 主任务列表点击协同视图按钮，要跳转到协同视图页面
 **@Date: 2024-05-20 10:48:11
 */
  handleMainTaskCollaborativeView(index, mainTask) {
    // console.log('主任务行索引', index);
    console.log('主任务行数据', mainTask);
    this.currentMainTaskId = mainTask.id;
    this.$nextTick(async () => {
      // 根据主任务id查询主任务详情
      let res1 = await API.WHXF.getSubTaskList(mainTask.id);
      console.log('根据主任务id查询出来的详情', res1);
      let flag = typeof res1;
      if (flag != 'object') {
        this.$message({
          type: 'warning',
          message: res1,
        });
        return;
      }
      // 给协同视图里面主任务数据赋值
      // console.log('给协同视图里面主任务数据赋值', res1);
      this.task.taskDetailData = res1;
      // console.log('给协同视图里面主任务数据赋值1', this.task.taskDetailData);
      // this.task.taskDetailData.subtaskList = res1.subtaskList;
      // this.task.taskDetailData.mainTaskName = mainTask.taskName;
      // this.task.taskDetailData.mainTaskId = mainTask.id;
      // this.task.taskDetailData.mainTaskcreateTime = mainTask.createTime;
      // this.task.taskDetailData.mainTaskType = mainTask.taskType == 'once' ? '单次任务' : '周期任务';
      // this.task.taskDetailData.mainTaskSource = mainTask.source == 'default' ? '手动新建' : '警情通知';
      // this.task.taskDetailData.mainTaskTarget = _.find(this.taskPurposeList, (item) => item.value == mainTask.taskTarget)?.label || '';
      // console.log('给协同视图里面主任务数据赋值2', this.task.taskDetailData);
      // 默认选择当前的第一个子任务
      this.task.subTaskSelectedIndex = 0;
      // console.log( '根据主任务id查询主任务详情中的子任务列表', this.task.taskDetailData.subtaskList );
      let len = this.task.taskDetailData.subtaskList.length;
      // 根据主任务里面的子任务的航线id查询航线坐标
      this.task.whxfSubTaskAirLineList = [];
      let lon = [], lat = [], alt = [];
      // 如果是周期任务，则只加载一条航线即可，因为周期任务飞的是同一条航线
      if (mainTask.taskType == 'period') {
        len = 1;
      }
      // 遍历子任务
      for (let i = 0; i < len; i++) {
        let subTask = this.task.taskDetailData.subtaskList[i];
        // 1 根据子任务id查询关联的航线信息
        let res1 = await API.TASK.FlightDetail(subTask.id);
        console.log('航线信息', res1);
        if (res1.flightCourseJson) {
          let flightCourseJson = JSON.parse(res1.flightCourseJson);
          let points = flightCourseJson.points;
          let cList = [];
          for (let j = 0; j < points.length; j++) {
            let point = points[j];
            let c = new Cesium.Cartesian3.fromDegrees(
              parseFloat(point.lon),
              parseFloat(point.lat),
              parseFloat(point.alt)
            );
            lon.push(parseFloat(point.lon));
            lat.push(parseFloat(point.lat));
            alt.push(parseFloat(point.alt));
            cList.push(c);
          }
          let temp = {
            flagId: subTask.taskTitle + '_' + subTask.deviceHardId,
            deviceHardId: subTask.deviceHardId,
            cList: cList,
            taskStatus: subTask.taskStatus,
          };
          this.task.whxfSubTaskAirLineList.push(temp);
        } else {
          this.$message({
            type: 'warning',
            message: `该任务 ${subTask.taskTitle} 航线信息不存在`,
          });
        }
      }
      console.log('格式化后的航线列表数据', this.task.whxfSubTaskAirLineList);
      // 根据航线坐标计算出最大最小经纬度，航线所在范围的四至
      let maxLon = Math.max(...lon);
      let minLon = Math.min(...lon);
      let maxLat = Math.max(...lat);
      let minLat = Math.min(...lat);
      let maxAlt = Math.max(...alt);
      let minAlt = Math.min(...alt);
      this.task.whxfXTSTAirLineSqrt = {
        maxLon: maxLon,
        minLon: minLon,
        maxLat: maxLat,
        minLat: minLat,
        maxAlt: maxAlt,
        minAlt: minAlt,
      };
      // 创建航线面板，主页面板，任务详情面板隐藏，协同视图里面面板显示
      this.createLinePanelVisible = false;
      this.pageShow = false;
      this.task.drawerTaskDetailVisible = false;
      this.flyUvaPanelVisible = true;
    });
  },
  /**@Description :  ******************************************* 主任务状态  取消
   * 新状态：执行3，取消99，完成1
   * item: 主任务对象
   **@Date: 2024-05-16 17:09:49
   */
  async handleMainTaskCancel(index, item) {
    //判断周期任务
    console.log('主任务数据', item);
    let title = '确认要取消该主任务吗?';
    if (item.taskType == 'period') {
      title = `取消当前周期任务？ <br> 取消后，该周期任务失效，后续将不再循环`; //实现html的读取方法
    }
    this.$confirm(title, '提示', {
      dangerouslyUseHTMLString: true, //加入这句话后，el-message可以读取br解析为html显示
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        let data = { groupId: item.id, status: 99 };
        let res = await API.WHXF.mainTaskStatusUpdate(data);
        console.log('主任务列表取消状态返回值', res);
        // 重新请求表格数据
        this.taskListPage();
      })
      .catch(() => { });
  },
  /**@Description :  ******************************************* 子任务状态 取消
   * 新状态：执行3，取消99，完成1
   * item: 子任务对象
   **@Date: 2024-05-16 17:59:11
   */
  async subTaskHandleCancel(index, row, mainIndex, mainTaskItem) {
    console.log('子任务取消,子任务索引', index);
    console.log('子任务取消,子任务数据', row);
    console.log('子任务取消,主任务索引', mainIndex);
    console.log('子任务取消,主任务数据', mainTaskItem);
    this.$confirm(`确认要取消该子任务吗?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        let data = { taskId: row.id, status: 99 };
        let res = await API.WHXF.subTaskStatusUpdate(data);
        console.log('子任务取消状态返回值', res);
        // 重新请求数据
        let res1 = await API.WHXF.getSubTaskList(mainTaskItem.id);
        let subTask = null;
        res1.subtaskList.forEach((v, index) => {
          if (v.id == row.id) {
            subTask = v;
          }
        });
        // 1 子任务状态更新
        row.taskStatus = subTask.taskStatus;
        // 2 主任务状态更新
        mainTaskItem.status = res1.status;
      })
      .catch(() => { });
  },
  /**@Description :  ******************************************* 子任务立即执行按钮 index, item, mainIndex, mainTaskItem
   **@Date: 2024-05-16 18:14:11
   */
  async subTaskHandleExecute(subIndex, row, mainIndex, mainRow) {
    console.log('子任务立即执行的子任务', row);
    console.log('子任务立即执行上的主任务', mainRow);
    this.$confirm(`确认要立即执行该子任务吗?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        // 将子任务状态修改为 立即执行
        let data2 = {
          taskId: row.id,
          status: 3,
        };
        let res3 = await API.WHXF.subTaskStatusUpdate(data2);
        if (res3) {
          /** 
         * 1 要更新主任务的任务状态
           2 更新该子任务的预计起飞时间
           3 更新该子任务的任务状态
        **/
          // 根据主任务id，查询主任务详情
          let res1 = await API.WHXF.getSubTaskList(mainRow.id);
          console.log('主任务详情', res1);
          // 1 要更新主任务的任务状态
          mainRow.status = res1.status;
          // 2 更新该子任务的预计起飞时间
          let subTask = null;
          res1.subtaskList.forEach((v, index) => {
            if (v.id == row.id) {
              subTask = v;
            }
          });
          row.startedAt = subTask.startedAt;
          // 3 更新该子任务的任务状态
          row.taskStatus = subTask.taskStatus;
        }
      })
      .catch(() => { });
  },
  /**@Description :  ******************************************* 选择无人机， 下拉面板显示与隐藏，已选择的设置不能再选择
   **@Date: 2024-05-17 14:03:07
  */
  async handleSelectUavPanelVisible(v, item) {
    // return;
    if (!v) return;
    this.$nextTick(async () => {
      // console.log('this.lineCardList', this.lineCardList);
      // console.log('item', item);
      // console.log('v', v);
      // console.log('this.task.combinationUvaList', this.task.combinationUvaList);
      // 如果是编辑状态
      let freeList = await API.WHXF.getDeviceListForLogistics(item.goodsId);
      this.task.combinationUvaList = this.task_handleCombinationUavList(item, freeList, this.lineCardList);
      // 强制更新，临时方案
      this.$forceUpdate()
    });
  },
  /**@Description :  ******************************************* 飞手列表
   **@Date: 2024-05-17 14:29:38
   */
  handleSelectFlyerPanelVisible(v, item) {
    if (!v) return;
    //重置飞手列表，都可以选择
    this.task.flyerList.forEach((v, index) => { v.disabled = false; });
    this.$nextTick(() => {
      // console.log('v', v);
      // console.log('item', item);
      // console.log('this.lineCardList', this.lineCardList);
      for (let i = 0; i < this.lineCardList.length; i++) {
        let selectedItem = this.lineCardList[i];
        // 如果该任务取消，则释放该任务关联的飞手
        if (selectedItem.taskStatus != 99) { // 已取消的任务不参与选择
          let flyIds = selectedItem.flyIds;
          // console.log('flyIds', flyIds);
          if (flyIds) {
            flyIds.forEach((flyId) => {
              // console.log('flyId', flyId);
              let flyer = _.find(this.task.flyerList, { 'id': flyId });
              // console.log('flyerflyer', flyer);
              if (flyer) {
                flyer.disabled = true;
                // console.log('flyer', flyer);
              }
            });
          }
        }
      }
      // 强制更新，临时方案
      this.$forceUpdate()
    });
  },
  /**@Description :  ******************************************* 根据devicehardId获取deviceName
   **@Date: 2024-06-03 16:11:47
   */
  getDeviceNameByDeviceHardId(deviceHardId) {
    // console.log('this.uav.deviceList', this.uav.deviceList);
    let deviceName = '';
    this.uav.deviceList.forEach((item) => {
      if (deviceHardId == item.deviceHardId) {
        deviceName = item.deviceName;
      }
    });
    return deviceName;
  },
  /**@Description :  ******************************************* 点击编辑任务，组合无人机列表数据
   * @param list1 接口获取非占用无人机列表，list2 子任务列表
   **@Date: 2024-07-02 13:57:53
  */
  handleCombinationUavList(item, list1, list2) {
    console.log('接口获取非占用无人机列表', list1);
    console.log('子任务列表 card list', list2);
    // 1 先把已存在的子任务占用的无人机添加到列表中
    this.lineCardList.forEach(v => {
      console.log('v', v);
      console.log('item', item);
      if (v?.deviceHardId) {
        if (v.goodsId == item.goodsId) {
          let dev = _.find(list1, { 'deviceHardId': v.deviceHardId });
          if (!dev) {
            let temp = { 'deviceHardId': v.deviceHardId, 'deviceName': v.deviceName };
            list1.unshift(temp); //放到第一个位置
          }
        }
      }

    });
    // 2 全部设置为可选
    _.map(list1, v => {
      v.disabled = false;
    });

    for (let i = 0; i < this.lineCardList.length; i++) {
      let selectedItem = this.lineCardList[i];
      console.log('selectedItem', selectedItem);
      if (selectedItem.taskStatus != 99) { // 已取消的任务不参与选择
        let id = selectedItem?.deviceHardId || '';
        console.log('id', id);
        let findItem = _.find(list1, { 'deviceHardId': selectedItem?.deviceHardId || '' });
        console.log('findItem', findItem);
        if (findItem) {
          findItem.disabled = true;
        }
      }
    }
    console.log('list1', list1);
    return list1;
  },
  /**@Description :  ******************************************* 飞手列表移除tag
   **@Date: 2024-07-03 11:37:46
  */
  handleFlyerListRemoveTag(v, item) {
    this.task.flyerList.forEach((v, index) => { v.disabled = false; });
    this.task_handleSelectFlyerPanelVisible(v, item);
  },
  /**@Description :  ******************************************* 获取起降场列表
   **@Date: 2024-07-03 16:39:34
  */
  async getUavLandingFieldList() {
    return await API.UAV.uavLandingFieldList();
  },
  /**@Description :  ******************************************* 在任务详情面板显示周期任务的循环方式
   **@Date: 2024-07-09 15:15:49
  */
  getPeriodDataStr(item) {
    if (item?.periodData) {
      let periodData = JSON.parse(item.periodData);
      let str = `每 ${periodData.day} 天 ${periodData.hour} 时 ${periodData.minute} 分`;
      return str;
    } else {
      return '暂无';
    }
  },
  /**@Description :  ******************************************* 点击任务详情的地图上的航线，跳到航线编辑面板，可以查看当前的这条航线
   **@Date: 2024-07-10 10:36:44
  */
  gotoAirLineEditor() {
    /**
     * 1. 当前子任务
     * 2. 当前子任务索引
     * 3. 关闭抽屉
     * 4. 设置子任务详情面板的航线预览标志
     * 5. 调用航线编辑
     * **/
    let item = this.task.taskDetailData.subtaskList[this.task.subTaskSelectedIndex];
    this.task.drawerTaskDetailVisible = false;
    this.task.subTaskDetailAirLinePreviewFlag = true;
    this.handleAddLine(item);
  },
  /**@Description :  ******************************************* 新增航线
   * @param {*} item 子任务
   **@Date: 2024-07-10 11:05:56
  */
  handleAddNewAirLine(item) {
    this.task.subTaskDetailAirLinePreviewFlag = false;
    this.handleAddLine(item);
  },
  /**@Description :  ******************************************* 编辑航线
   * @param {*} item 子任务
   **@Date: 2024-07-10 11:06:05
  */
  handleEditAirLine(item) {
    this.task.subTaskDetailAirLinePreviewFlag = false;
    this.handleAddLine(item);
  },
  /**@Description :  ******************************************* 删除航线
   * @param {*} item 子任务
   **@Date: 2024-07-10 11:10:03
  */
  handleDeleteAirLine(item) {
    this.task.subTaskDetailAirLinePreviewFlag = false;
    this.handleDeleteLine(item);
  },
  /**@Description :  ******************************************* 删除主任务
   **@Date: 2024-07-16 09:54:46
  */
  handleMainTaskDelete(index, row) {
    let ids = [row.id];
    this.$confirm(`确认要删除 ${row.taskName} 子任务吗?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      // 子任务删除
      let res = await API.WHXF.deleteMainTask(ids);
      if (res.status != 1) {
        this.$message.error('删除失败，请稍后再试');
      }
      // 再刷新一下表格
      this.taskListPage();
    }).catch(() => {});
  }
}