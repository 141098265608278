<!-- 
  航空航线规划
  手动规划：线段实体类的guid，是线段第二个点的guid
 -->

<template>
  <div class="create-line-container">
    <!-- 顶部手动规划，自动规划 -->
    <div class="header">
      <div class="right" v-if="true">
        <!-- <span v-show="manualPlanShrinkFlag" class="open iconfont icon-baseqianjin" @click="openClickHandle"></span> -->
        <!-- <span class="text"> {{ manualOrAuto == 0 ? '手动规划' : '自动规划' }} </span> -->
        <span class="text"> 手动规划 </span>
        <!-- <img class="img" src="@/assets/img/i74.png" @click="createWay" alt /> -->
      </div>
      <!-- 关闭编辑面板 -->
      <div class="left">
        <img class="img" src="@/assets/img/i75.png" @click="close" alt />
      </div>
    </div>
    <!-- 航线信息编辑 -->
    <div class="air-line-info">
      <div class="left">
        <div v-if="!editDataNew" class="name">航线编号: {{ panelForm.name }}</div>
        <div v-else class="name">航线编号: {{ filename }}</div>
        <div class="type" v-if="false">
          <img class="img" src="@/assets/img/i77.png" alt />
          <span class="name"> {{ panelForm.actionWay == 'uav' ? '无人机' : '机巢' }}
          </span>
        </div>
        <div class="scene" v-if="false">
          <span>场景：{{ panelForm.scene }}</span>
        </div>
      </div>
      <div class="right" v-if="false">
        <img src="@/assets/img/i76.png" alt class="img" @click="editCreateAirLinePanel" />
      </div>
    </div>
    <!-- 高度模式 -->
    <div class="height-mode">
      <el-select popper-class="popper-select-panel" v-model="altitudeMode" placeholder="请选择高度模式">
        <el-option v-for="item in altitudeMode_list" :key="item.id" :label="item.name" :value="item.value" />
      </el-select>
    </div>
    <!-- 航点列表 -->
    <div class="line-point-body">
      <div class="empty" v-if="pointList.length == 0">
        <span class="empty-tips">请点击地图添加航点</span>
      </div>
      <div class="line-point" v-else>
        <el-collapse v-model="activeNames" @change="handleChange" accordion>
          <el-collapse-item v-for="(item, index) in pointItemList" :name="String(index)" :key="index">
            <!-- 航点标题 -->
            <template slot="title">
              <div class="item-title">
                <div class="left">
                  <img v-if="item.isCollapse" class="collapse-title-img" src="@/assets/img/i92.png" alt />
                  <img v-else class="collapse-title-img" src="@/assets/img/i91.png" alt />
                  <span class="index" :style="[
                    { left: index >= 9 ? '3px' : '6px' }
                  ]">{{ index + 1 }}</span>
                  <span>航点{{ item.index + 1 }}</span>
                </div>
                <div class="right">
                  <img src="@/assets/img/i13.png" alt @click.stop="deletePoint(index)" />
                </div>
              </div>
            </template>
            <!-- 航点内容 -->
            <div class="content">
              <!-- 飞行高度  飞行速度 -->
              <div class="card card1">
                <!-- 飞行高度 -->
                <div class="item item1">
                  <div class="title">飞行高度</div>
                  <div class="body">
                    <div class="btn btn1" @click="
                      flyHeightClickHandle(
                        -50,
                        item,
                        index
                      )
                      ">-50</div>
                    <div class="btn btn2" @click="
                      flyHeightClickHandle(
                        -10,
                        item,
                        index
                      )
                      ">-10</div>
                    <div class="btn btn3" @click="
                      flyHeightClickHandle(
                        -1,
                        item,
                        index
                      )
                      ">-1</div>
                    <el-input v-model="item.flyHeight" :disabled="panelForm.isFollowAirLine
                      " placeholder @change="
                        flyHeightChangeHandle(
                          item,
                          index
                        )
                        "></el-input>
                    <div class="btn btn4" @click="
                      flyHeightClickHandle(
                        1,
                        item,
                        index
                      )
                      ">+1</div>
                    <div class="btn btn5" @click="
                      flyHeightClickHandle(
                        10,
                        item,
                        index
                      )
                      ">+10</div>
                    <div class="btn btn6" @click="
                      flyHeightClickHandle(
                        50,
                        item,
                        index
                      )
                      ">+50</div>
                  </div>
                </div>
                <!-- 飞行速度 -->
                <div class="item item2">
                  <div class="title">飞行速度(1-20m/s)</div>
                  <div class="body">
                    <img class="img" src="@/assets/img/i79.png" alt @click="
                      flySpeedClickHandle(
                        -1,
                        item,
                        index
                      )
                      " />
                    <el-slider class="slider" v-model="item.flySpeed" :disabled="panelForm.isFollowAirLine
                      " :min="1" :max="20"></el-slider>
                    <img class="img" src="@/assets/img/i80.png" alt @click="
                      flySpeedClickHandle(
                        1,
                        item,
                        index
                      )
                      " />
                    <el-input class="speed-input" v-model.number="item.flySpeed" :disabled="panelForm.isFollowAirLine
                      " placeholder></el-input>
                    <span class="unit">m/s</span>
                  </div>
                </div>
              </div>
              <!-- 航点位置 -->
              <div class="card card2">
                <div class="left">
                  <div class="title">航点位置</div>
                  <div class="lonlat">
                    <el-input class="lon" placeholder v-model="item.lng" @change="
                      lonlatInputChangeHandle(
                        0,
                        item,
                        index
                      )
                      ">
                      <template slot="prepend">经度</template>
                    </el-input>
                    <el-input class="lat" placeholder v-model="item.lat" @change="
                      lonlatInputChangeHandle(
                        1,
                        item,
                        index
                      )
                      ">
                      <template slot="prepend">纬度</template>
                    </el-input>
                  </div>
                </div>
                <div class="right">
                  <img class="img" src="@/assets/img/i48.png" alt />
                  <!-- 左 -->
                  <img class="img-arrow img1" src="@/assets/img/i37.png" alt @click="lonlatClick(1, item, index)" />
                  <!-- 右 -->
                  <img class="img-arrow img2" src="@/assets/img/i37.png" alt @click="lonlatClick(2, item, index)" />
                  <!-- 上 -->
                  <img class="img-arrow img3" src="@/assets/img/i37.png" alt @click="lonlatClick(3, item, index)" />
                  <!-- 下 -->
                  <img class="img-arrow img4" src="@/assets/img/i37.png" alt @click="lonlatClick(4, item, index)" />
                </div>
              </div>
              <!-- 云台 偏航角 俯仰角 -->
              <div class="card card3">
                <div class="item item1">
                  <div class="title">云台偏航角(-180°到180°)</div>
                  <div class="body">
                    <img class="img" src="@/assets/img/i79.png" alt @click="
                      yawClickHandle(-1, index, item)
                      " />
                    <el-slider class="slider" v-model="item.yaw" :min="-180" :max="180"></el-slider>
                    <img class="img" src="@/assets/img/i80.png" alt @click="
                      yawClickHandle(1, index, item)
                      " />
                    <el-input class="speed-input" v-model.number="item.yaw" placeholder></el-input>
                    <span class="unit">°</span>
                  </div>
                </div>
                <div class="item item2">
                  <div class="title">云台俯仰角(-180°到180°)</div>
                  <div class="body">
                    <img class="img" src="@/assets/img/i79.png" alt @click="
                      pitchClickHandle(
                        -1,
                        index,
                        item
                      )
                      " />
                    <el-slider class="slider" v-model="item.pitch" :min="-180" :max="180"></el-slider>
                    <img class="img" src="@/assets/img/i80.png" alt @click="
                      pitchClickHandle(1, index, item)
                      " />
                    <el-input class="speed-input" v-model.number="item.pitch" placeholder></el-input>
                    <span class="unit">°</span>
                  </div>
                </div>
              </div>
              <!-- 航线标签 -->
              <div class="card card4" v-if="false">
                <div class="title">航线标签</div>
                <div class="body">
                  <el-select popper-class="popper-select-panel" v-model="item.airLineLabel" placeholder="请选择航线标签">
                    <el-option v-for="itemLabel in lablistL" :key="itemLabel.id" :label="itemLabel.labelName" :value="itemLabel.labelName"></el-option>
                  </el-select>
                </div>
              </div>
              <!-- 航点动作 -->
              <div class="card card5">
                <div class="title">航点动作</div>
                <div class="body">
                  <el-row class="row-action" v-for="(
                                            actionItem, index
                                        ) in item.loadActionSelectedList" :key="index">
                    <el-col class="col" :span="24">
                      <div class="left">
                        <img class="img" :src="require(`@/assets/img/${actionItem.src}`)
                          " alt />
                        <span class="name">
                          {{
                            actionItem.name
                          }}
                        </span>
                      </div>
                      <div class="right">
                        <img src="@/assets/img/i13.png" alt class="img" @click="deleteAction(index)" />
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="row-add">
                    <el-col class="col" :span="24">
                      <img class="img" src="@/assets/img/i39.png" alt />
                      <span class="action" @click.stop="addActionClick">添加动作</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="foot">
      <!-- 完成动作 -->
      <!-- <el-select class="input-action" v-model="finishAction" placeholder="请选择完成动作" size="mini">
        <el-option v-for="item in finishActionList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select> -->
      <!-- 完成动作 -->
      <div class="foot-item foot-item-1" style="width: 100%;">
        <span class="name">完成动作：</span>
        <el-select class="input-action" v-model="finishAction" placeholder="请选择完成动作" size="mini">
          <el-option v-for="item in finishActionList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- 如果完成动作选择 原地降落，则显示绝对高度设置 -->
      <div class="foot-item foot-item-1" style="width: 100%;" v-if="finishAction == 'AutoLand'">
        <span class="name">绝对高度(m)：</span>
        <el-input size="mini" class="input-action input-action-param" v-model="finishedActionParam" placeholder="请输入绝对降落高度"></el-input>
      </div>
      <div class="btn-c" v-if="!subTaskDetailAirLinePreviewFlag">
        <div class="btn btn-cancel" @click="cancelPlan">取消</div>
        <div class="btn btn-submit" @click="submitPlan" :style="{ color: saveTitle == '仍然保存' ? '#ff0000' : '#ffffff' }">{{ saveTitle }}</div>
      </div>
      <div  class="btn-c" v-else>
        <div class="btn btn-cancel" @click="cancelPlan">返回</div>
      </div>
    </div>
    <!-- 负载动作 -->
    <div class="load-action-panel" v-show="loadActionPanelShowFlag">
      <div class="action action1">
        <div class="title">
          <span class="name">负载动作</span>
          <div class="close" @click="closeLoadActionPanel">
            <img src="@/assets/img/i75.png" alt />
          </div>
        </div>
        <div class="body">
          <div class="item item1" @click="loadActionClick(1, '拍照', 'i90.png')" :class="[
            loadActionItemSelectedFlag == 1
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i90.png" alt />
            <span class="name">拍照</span>
          </div>
          <div class="item item2" @click="loadActionClick(2, '开始录像', 'i81.png')" :class="[
            loadActionItemSelectedFlag == 2
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i81.png" alt />
            <span class="name">开始录像</span>
          </div>
          <div class="item item3" @click="loadActionClick(3, '停止录像', 'i82.png')" :class="[
            loadActionItemSelectedFlag == 3
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i82.png" alt />
            <span class="name">停止录像</span>
          </div>
          <div class="item item4" @click="loadActionClick(4, '云台俯仰角', 'i83.png')" :class="[
            loadActionItemSelectedFlag == 4
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i83.png" alt />
            <span class="name">云台俯仰角</span>
          </div>
          <div class="item item5" @click="loadActionClick(5, '云台偏航角', 'i84.png')" :class="[
            loadActionItemSelectedFlag == 5
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i84.png" alt />
            <span class="name">云台偏航角</span>
          </div>
          <div class="item item6" @click="loadActionClick(6, '相机变焦', 'i85.png')" :class="[
            loadActionItemSelectedFlag == 6
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i85.png" alt />
            <span class="name">相机变焦</span>
          </div>
          <div class="item item7" @click="loadActionClick(7, '开始等时间拍照', 'i86.png')" :class="[
            loadActionItemSelectedFlag == 7
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i86.png" alt />
            <span class="name">开始等时间拍照</span>
          </div>
          <div class="item item8" @click="loadActionClick(8, '开始等间隔拍照', 'i87.png')" :class="[
            loadActionItemSelectedFlag == 8
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i87.png" alt />
            <span class="name">开始等间隔拍照</span>
          </div>
        </div>
      </div>
      <div class="action action2">
        <div class="title">飞机动作</div>
        <div class="body">
          <div class="item item1" @click="loadActionClick(9, '悬停', 'i88.png')" :class="[
            loadActionItemSelectedFlag == 9
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i88.png" alt />
            <span class="name">悬停</span>
          </div>
          <div class="item item2" @click="loadActionClick(10, '飞行器偏航角', 'i89.png')" :class="[
            loadActionItemSelectedFlag == 10
              ? 'item-selected'
              : ''
          ]">
            <img class="img" src="@/assets/img/i89.png" alt />
            <span class="name">飞行器偏航角</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 地名搜索 -->
    <div class="search-info" v-show="true">
      <el-autocomplete popper-class="create-airline-keyword-search-panel" :popper-append-to-body="false" class="autocomplete-dd" style="width:300px" v-model="destination.name" :fetch-suggestions="handle_query_address_async" placeholder="请输入地名名称搜索例如：芜湖高铁站" :trigger-on-focus="false" @select="handle_select_address" clearable @clear="destroy_location_icon">
        <template slot-scope="{ item }">
          <div class="w-[300px]!">
            <span style="font-size: 14px; color: #333">{{ item.name }}</span>
            <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div>
  </div>
</template>

<script>
let handler = null;
let airLinePointDataSource = null;
let dynamicLineEntity = null;
let dynamicEndPoint = null;
let dynamicStartPoint = null;
let dynamicDrawFlag = true;
// 起降场
let landingFieldInfoEntity = null;
import Utils from '@/lib/cesium/utils.js';
import Bus from '@/assets/ligature.js';
import API from '@/api';
import _ from 'lodash';
import methods from '../methods';
import * as turf from '@turf/turf';
let location_icon = null;
import dayjs from 'dayjs'
export default {
  props: {
    // 创建新航线面板参数
    panelForm: {
      type: Object,
      default: () => {
        return { name: `WHXF-` + dayjs(new Date()).format('YYYYMMDDHHmmss') }
      }
    },
    // 航线标签
    lablistL: {
      typeof: Array,
      default: []
    },
    // 禁用自动规划
    noAutoPlan: {
      type: Boolean,
      default: false
    },
    // 显示收缩icon
    manualPlanShrinkFlag: {
      type: Boolean,
      default: false
    },
    // 编辑航线时候的卡片数据
    editData: {
      type: Object,
      default: () => { }
    },
    // 任务创建表单，把选择机巢，选择无人机型号，选择无人机，选择飞手，起飞时间给保存到cursor字符串中
    createTaskForm: {
      type: Object,
      default: () => { }
    },
    subtaskitem: {
      type: Object,
      default: () => { }
    },
    // 起降场信息
    landingFieldInfo: {
      type: Object,
      default: () => { }
    },
    // 任务详情面板地图转到航线编辑，航线编辑里面只有返回按钮，不显示取消，保存按钮
    subTaskDetailAirLinePreviewFlag: {
      type: Boolean,
      default: () => {return false}
    }
  },
  watch: {
    manualPlanShrinkFlag(newVal, oldVal) { },
    panelForm: {
      handler(newVal, oldVal) {
        // console.log('修改了q', newVal);
        this.$nextTick(() => {
          // console.log('newVal.isFollowAirLine', newVal.isFollowAirLine);
          // debugger
          if (newVal.isFollowAirLine) {
            for (let i = 0; i < this.pointItemList.length; i++) {
              this.pointItemList[i].flyHeight = newVal.baseHeight;
              this.pointItemList[i].flySpeed = newVal.baseSpeed;
              console.log(this.pointItemList[i], i);
            }
            // console.log(this.pointItemList, 'this.pointItemList[i]');
            // this.pointItemList = [];
          }
        });
      },
      deep: true, // 深度监听
      immediate: true // 第一次改变就执行
    }
  },
  data() {
    return {
      manualOrAuto: 0, //点击顶部手动规划和自动规划切换按钮， 0 手动规划， 1 自动规划
      pointList: [], //航点
      activeNames: 0,
      pointItemList: [], //航点信息数组
      flySpeed: 0, //飞行速度
      loadActionPanelShowFlag: false, //负载动作面板显示与隐藏
      loadActionItemSelectedFlag: 0, //点击负载每一个选项样式变化
      loadActionSelectedList: [],
      lonlatStep: 0.001, //每次增加或者减小的经纬度大小
      guid: '',
      collapseIndex: 0, //
      saveTitle: '保存',
      // actionList:[],
      // pointFlag: -1,//当前激活的是第几个航点
      planTips: '请点击地图添加航点',
      autoPlaneMapFlag: true, //自动规划是否可以点击地图绘制点
      editAirLineId: '', //编辑航线id
      destination: {
        //地名搜索
        name: '',
        lng: 0,
        lat: 0
      },
      finishActionList: [
        { label: '返回到home点', value: 'GoHome' },
        { label: '悬停，无动作', value: 'Hover' },
        { label: '原地降落', value: 'AutoLand' },
      ],
      finishAction: '',
      finishedActionParam: '',//绝对高度设置
      editDataNew: null,
      altitudeMode: '',
      altitudeMode_list: [
        { name: '相对高度', id: 1, value: 'relativeToGround' },
        { name: '绝对高度', id: 2, value: 'absolute' }
      ], //高度模式   
      filename: '', //航线名称，如果是编辑则，使用这个航线名称
    };
  },
  computed: {
    cesium_layer() {
      return this.g_cesium_layer();
    }
  },
  async mounted() {
    console.log('编辑数据！！！', this.editData);
    console.log('this.subtaskitem', this.subtaskitem);
    console.log('this.landingFieldInfo', this.landingFieldInfo);
    this.$nextTick(async () => {
      console.log('this.subTaskDetailAirLinePreviewFlag', this.subTaskDetailAirLinePreviewFlag);
      dynamicDrawFlag = !this.subTaskDetailAirLinePreviewFlag;
      airLinePointDataSource = new Cesium.CustomDataSource('airLinePointDataSource');
      await window.viewer.dataSources.add(airLinePointDataSource);
      // 新接口，根据航线id获取航线的信息
      if (this.subtaskitem?.flightLineId) {
        // let res1 = await API.TASK.FlightDetail(this.subtaskitem?.id);
        let res2 = await API.AIRWAY.getFlightCursorJsonByLineId(this.subtaskitem?.flightLineId);
        // this.editData = res1;
        this.editDataNew = res2.data;
      }
      // 添加起降场
      landingFieldInfoEntity = this.airway_generateLandingFieldEntity(this.landingFieldInfo);
      window.viewer.entities.add(landingFieldInfoEntity);
      // 添加警情任务内容
      this.addPointFromOriginJQTask();
      this.planTips = '请点击地图添加航点';
      this.autoPlaneMapFlag = true;
      await this.init_cesium_handler();
      // dynamicDrawFlag = true;
      if (this.editDataNew) {
        // console.log('编辑数据！！！', this.editData);
        // this.editAirLineId = this.editDataNew.id;
        console.log('编辑航线', this.editDataNew);
        this.editAirLineId = this.subTaskitem?.flightLineId;
        this.pointItemList = [];
        this.pointList = [];
        let flightCourseJson = JSON.parse(this.editDataNew.flightCourseJson);
        console.log('航线', flightCourseJson);
        // 高度模式
        this.altitudeMode = flightCourseJson.altitudeMode || '';
        //已有航线名称
        this.filename = flightCourseJson.filename;
        // console.log(flightCourseJson, 'flightCourseJson');
        // 手动规划0 还是自动规划 1
        this.manualOrAuto = flightCourseJson?.manualOrAuto ? flightCourseJson.manualOrAuto : 0;
        if (this.manualOrAuto == 1) {
          this.saveTitle = '生成航线';
        }
        //完成动作，绝对高度设置
        this.finishAction = flightCourseJson?.finishedAction ? flightCourseJson.finishedAction : '';
        if (this.finishAction == 'AutoLand') {
          this.finishedActionParam = flightCourseJson?.finishedActionParam ? flightCourseJson.finishedActionParam : 0;
        }
        // 航点数组
        let points = flightCourseJson.points;
        let lonArray = [];
        let latArray = [];
        for (let i = 0; i < points.length; i++) {
          let item = {};
          item.isCollapse = true;
          item.index = i;
          item.lng = parseFloat(points[i].lon).toFixed(7);
          item.lat = parseFloat(points[i].lat).toFixed(7);
          lonArray.push(item.lng);
          latArray.push(item.lat);
          // 飞行高度
          item.flyHeight = points[i]?.alt ? parseFloat(points[i].alt) : 100;
          // 飞行速度
          // item.flySpeed = parseFloat(flightCourseJson.line.baseSpeed);
          item.flySpeed = points[i]?.speed ? parseFloat(points[i].speed) : 5;
          // 偏航角
          item.yaw = parseFloat(points[i].gimbalPitch);
          // 俯仰角
          item.pitch = parseFloat(points[i].pitch);
          // 航线标签 废弃!!!
          // temp.airLineLabel = '';
          // 航点动作
          item.loadActionSelectedList = points[i].actions;
          item.guid = points[i].uuid;
          // 绘制点
          console.log('parseFloat(points[i].lon)', points[i]);

          let c3 = Cesium.Cartesian3.fromDegrees(parseFloat(points[i].lon), parseFloat(points[i].lat), parseFloat(points[i].alt || 100));
          console.log('c3', c3);
          this.guid = item.guid;
          this.pointItemList.push(item);
          let en = this.addPoint(i + 1, c3);
          item.pointEntity = en;
          // 绘制线段
          if (i < points.length - 1) {
            let c3_2 = Cesium.Cartesian3.fromDegrees(parseFloat(points[i + 1].lon), parseFloat(points[i + 1].lat), parseFloat(points[i + 1].alt || 100));
            this.addManualPolyline([c3, c3_2], {
              guid: points[i + 1].uuid
            });
          }
          this.pointList.push(c3);
        }
        //定位到航线
        window.viewer.flyTo(airLinePointDataSource, {
          offset: new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0.0), Cesium.Math.toRadians(-35.0), 0),
        });
      } else {
        console.log('新建航线');
        // 如果是新建航线，则航线名称为空，使用面板生成的航线名称
        this.filename = '';
        //定位到起降场
        if (landingFieldInfoEntity) {
          window.viewer.flyTo(landingFieldInfoEntity, {
            offset: new Cesium.HeadingPitchRange(
              Cesium.Math.toRadians(0.0),
              Cesium.Math.toRadians(-90.0),
              10000
            ),
          });
        }
      }
      // 开启地形
      window.viewer.scene.globe.depthTestAgainstTerrain = true;
    });
  },
  methods: {
    ...methods,
    // 点击顶部切换按钮，切换手动规划还是自动规划
    createWay() {
      if (!this.noAutoPlan) {
        dynamicDrawFlag = true;
        dynamicLineEntity = null;
        dynamicEndPoint = null;
        this.pointItemList = [];
        this.pointList = [];
        airLinePointDataSource.entities.removeAll();
        this.loadActionSelectedList = [];
        this.loadActionItemSelectedFlag = 0;
        this.activeNames = 0;
        this.manualOrAuto = this.manualOrAuto == 0 ? 1 : 0;
      }
      if (this.manualOrAuto == 1) {
        this.saveTitle = '生成航线';
      } else {
        this.saveTitle = '保存';
      }
    },
    // 点击关闭按钮
    close() {
      this.$confirm('关闭将退出航线编辑器，您要保存吗？如果不保存，您创建的内容将会丢失?', '退出确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'confirm-cuetomer-class',
        iconClass: 'confirm-warning-icon-class'
      })
        .then(() => {
          this.$emit('close');
          // handler = handler && handler.destroy();
          this.destory();
        })
        .catch(() => { });
    },
    // 点击编辑按钮弹出编辑框，设置航线
    editCreateAirLinePanel() {
      this.$emit('editCreateAriLinePanel', { editData: this.editData, panelForm: this.panelForm });
    },
    // 初始化地图鼠标事件
    async init_cesium_handler() {
      // 设置小高度，低于该高度就不能再往下缩放
      // window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 15000;
      // window.viewer.scene.globe.depthTestAgainstTerrain = true;
      // airLinePointDataSource = new Cesium.CustomDataSource("airLinePointDataSource");
      // window.viewer.dataSources.add(airLinePointDataSource);
      let viewer = window.viewer;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      // 右键点击
      handler.setInputAction(async (event) => {
        // 是否开启动态绘制
        if (!dynamicDrawFlag) {
          return;
        }
        let earthPosition = window.viewer.scene.camera.pickEllipsoid(event.position);
        if (Cesium.defined(earthPosition)) {
          // 1 先将点添加到面板数组，点的默认高度是100，鼠标拾取高度为0，需要转换为高度为100的点
          let wgs84 = Utils.simpleCartesian3ToWGS84(earthPosition, viewer);
          // console.log(wgs84, '鼠标拾取点转换为经纬度');
          let ray = viewer.camera.getPickRay(event.position);
          // console.log(wgs84cartesian, '带高度');
          let newPos = new Cesium.Cartesian3.fromDegrees(parseFloat(wgs84.longitude), parseFloat(wgs84.latitude), 100);
          earthPosition = _.cloneDeep(newPos);
          this.pointList.push(earthPosition);
          let lnglat = Utils.transformCartesian2WGS84(earthPosition);
          // 2 初始化点的json参数，默认最新添加的点信息面板展开，在这里生成guid
          let temp = this.initAirLineData(lnglat, {
            isCollapse: false
          });
          // 3 关闭其他点的信息面板
          this.pointItemList.forEach((item) => {
            item.isCollapse = true;
          });
          // 4 将点添加到面板数组
          this.pointItemList.push(temp);
          // 5 当前激活的航点索引
          this.collapseIndex = this.pointItemList.length - 1;
          // 6 面板上激活最新航点
          this.activeNames = String(this.pointItemList.length - 1);
          // 7 动态绘制线段的起始点
          dynamicStartPoint = earthPosition;
          // 8 添加两点之间的线段
          if (this.pointList.length >= 2) {
            // 每次第二个点的 guid 作为线段的 guid, 也就是线段的id是线段第二个点的 guid
            this.addManualPolyline(this.pointList.slice(-2), {
              guid: this.guid,
              flag: 0
            });
          }
          // 9 添加点
          this.addManualPoint(this.pointList.length, earthPosition);
          // 10 绘制动态直线
          if (!dynamicLineEntity) {
            dynamicLineEntity = new Cesium.Entity({
              name: 'dynamicLineEntity',
              show: true,
              polyline: {
                width: 6,
                // material: Cesium.Color.fromCssColorString("#00EE9C"),
                material: new Cesium.ImageMaterialProperty({
                  image: 'image/2-1-1-1.png',
                  repeat: new Cesium.Cartesian2(5, 1),
                  // color: Cesium.Color.YELLOWGREEN,
                  transparent: true
                })
              }
            });
            airLinePointDataSource.entities.add(dynamicLineEntity);
            let callback = new Cesium.CallbackProperty((time, result) => {
              let [dynamicStartPoint] = this.pointList.slice(-1);
              let endPoint = dynamicEndPoint;
              if (dynamicStartPoint && endPoint) {
                let aaa = dynamicStartPoint.equals(endPoint);
                let res = null;
                if (aaa) {
                  if (this.pointList.length >= 2) {
                    res = this.pointList.slice(-2);
                  }
                } else {
                  res = [dynamicStartPoint, endPoint];
                }
                return res;
              }
            });
            dynamicLineEntity.polyline.positions = callback;
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      // 鼠标滑动
      handler.setInputAction((event) => {
        if (!dynamicDrawFlag) {
          return;
        }
        let earthPosition = window.viewer.scene.camera.pickEllipsoid(event.endPosition, window.viewer.scene.globe.ellipsoid);
        if (Cesium.defined(earthPosition)) {
          dynamicEndPoint = earthPosition;
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      // 左键结束绘制
      handler.setInputAction((event) => {
        if (!dynamicDrawFlag) {
          return;
        }
        // 获取最后一个点
        let [a1] = this.pointItemList.slice(-1);
        let guid = a1.guid;
        let b1 = airLinePointDataSource.entities.getById('point_' + guid);
        // console.log(b1, 'b1');
        b1._label._text._value = 'E';
        // handler = handler && handler.destroy();
        dynamicDrawFlag = false;
        dynamicLineEntity = null;
        dynamicEndPoint = null;
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
    // 手动规划添加点 index 第几个点， c3 笛卡尔坐标
    addManualPoint(index, c3) {
      let en = this.addPoint(index, c3);
      // 把en添加到数组中
      this.pointItemList[parseInt(this.activeNames)].pointEntity = en;
    },
    /**@Description : 添加点到地图上
     *@Date : 2024-01-19 13:57:07
     *@Param :
     *@Return :
     */
    addPoint(index, c3) {
      let lnglatC3 = Utils.simpleCartesian3ToWGS84(c3, viewer);
      let downP = new Cesium.Cartesian3.fromDegrees(parseFloat(lnglatC3.longitude), parseFloat(lnglatC3.latitude), 0);
      let polyLineArr = [downP, c3];
      let imgSrc = '';
      let text = '';
      let color = '#808080';
      // 第一个点
      if (this.pointItemList.length == 1) {
        text = String('S');
        imgSrc = require('@/assets/img/i91.png');
        color = '#2D73E6';
      } else {
        text = String(index);
        imgSrc = require('@/assets/img/i92.png');
      }
      let en = new Cesium.Entity({
        name: 'airLinePoint',
        id: 'point_' + this.guid,
        position: c3,
        point: {
          show: true,
          pixelSize: 25,
          color: Cesium.Color.fromCssColorString(color),
          fillColor: Cesium.Color.fromCssColorString(color),
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 300000, 0.5)
        },
        label: {
          text: text,
          font: '20px sans-serif',
          scale: 0.8,
          font: 'bold 24px Microsoft YaHei',
          fillColor: Cesium.Color.WHITE,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: false,
          outlineWidth: 0,
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 300000, 0.5),
          pixelOffset: new Cesium.Cartesian2(0, 0)
          // eyeOffset: Cesium.Cartesian3(0, 0, -10000),
        },
        billboard: {
          show: false,
          width: 29,
          height: 38,
          image: imgSrc,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 300000, 0.5)
        },
        polyline: {
          positions: polyLineArr,
          width: 1,
          // material: Cesium.Color.fromCssColorString('#00EE9C'),
          material: new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString('#3CE171')
          })
        }
      });
      let enDownPointEntity = new Cesium.Entity({
        name: 'airLinePoint',
        id: 'point_down_' + this.guid,
        position: downP,
        point: {
          show: true,
          pixelSize: 8,
          // color: Cesium.Color.fromCssColorString('#ffffff'),
          color: Cesium.Color.fromCssColorString('#3CE171'),
          // fillColor: Cesium.Color.fromCssColorString('#ffffff'),
          fillColor: Cesium.Color.fromCssColorString('#3CE171'),
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 300000, 0.5),
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        }
      });
      airLinePointDataSource.entities.add(en);
      airLinePointDataSource.entities.add(enDownPointEntity);
      return en;
    },
    /** 手动规划添加线段
     * 手动规划添加线段
     * c3Arr： [c31, c32] 笛卡尔坐标数组，第一个起点和第二个终点
     * opts:
     *      guid: entity 的 id,
     * **/
    addManualPolyline(c3Arr, opts) {
      // console.log(c3Arr, '线段的两个点');
      let lnglat1 = Utils.transformCartesian2WGS84(c3Arr[0]);
      let lnglat2 = Utils.transformCartesian2WGS84(c3Arr[1]);
      // 1 计算中间点
      let point1 = turf.point([lnglat1.lng, lnglat1.lat]);
      let point2 = turf.point([lnglat2.lng, lnglat2.lat]);
      let midpoint = turf.midpoint(point1, point2);

      let midLon = midpoint.geometry.coordinates[0];
      let midLat = midpoint.geometry.coordinates[1];
      let midAlt = (parseFloat(lnglat1.alt) + parseFloat(lnglat2.alt)) / 2;
      let midC3 = new Cesium.Cartesian3.fromDegrees(midLon, midLat, midAlt);
      // 2 计算角度
      let bearing = turf.rhumbBearing(point1, point2);
      let rotate = 180 - bearing;
      // 3 计算两点之间的距离
      let distance = Cesium.Cartesian3.distance(c3Arr[0], c3Arr[1]).toFixed(2);
      // console.log(distance, 'distance');
      // console.log(bearing, '两点之间的角度');
      let en = new Cesium.Entity({
        name: 'airLineLine',
        id: 'line' + '_' + opts.guid,
        position: midC3,
        polyline: {
          positions: c3Arr,
          width: 6,
          material: new Cesium.ImageMaterialProperty({
            image: 'image/2-1-1-1.png',
            repeat: new Cesium.Cartesian2(5, 1),
            // color: Cesium.Color.YELLOWGREEN,
            transparent: true
          })
        },
        billboard: {
          show: false,
          width: 29,
          height: 38,
          scale: 1.5,
          rotation: Cesium.Math.toRadians(rotate),
          image: require('@/assets/img/i93.png'),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 300000, 0.5)
        },
        label: {
          text: String(distance) + 'm',
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 500000, 0),
          scale: 0.6,
          font: 'bold 30px Microsoft YaHei',
          fillColor: Cesium.Color.fromCssColorString('#fff'),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10)
        }
      });
      airLinePointDataSource.entities.add(en);
    },
    // 自动规划，添加起点和终点 废弃
    addPointAutoStartEnd(lnglat, alt, count) {
      let str = '起';
      if (count == 2) {
        str = '终';
      }
      let en = new Cesium.Entity({
        name: 'airLinePoint',
        id: 'airLinePoint_start_end_' + count,
        // id: 'point_'  + '_' + this.guid,
        position: Cesium.Cartesian3.fromDegrees(lnglat.lng, lnglat.lat, alt),
        point: {
          pixelSize: 25,
          color: Cesium.Color.fromCssColorString('#00EE9C'),
          fillColor: Cesium.Color.fromCssColorString('#00EE9C')
        },
        label: {
          text: str,
          font: '24px sans-serif',
          scale: 0.5,
          font: 'bold 24px Microsoft YaHei',
          fillColor: Cesium.Color.WHITE,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: false,
          outlineWidth: 0
        }
      });
      airLinePointDataSource.entities.add(en);
    },
    // 自动规划，添加自动生成的点和点之间的线段
    addPointAutoRes(res) {
      // 先删除起点和终点
      let startEn = airLinePointDataSource.entities.getById('airLinePoint_start_end_1');
      let endEn = airLinePointDataSource.entities.getById('airLinePoint_start_end_2');
      airLinePointDataSource.entities.remove(startEn);
      airLinePointDataSource.entities.remove(endEn);

      this.pointItemList = [];
      // 添加点和线
      let { grids, pnts } = res;
      // console.log(pnts, 'pnts');
      for (let i = 0; i < pnts.length - 1; i++) {
        let p1 = new Cesium.Cartesian3.fromDegrees(parseFloat(pnts[i].x), parseFloat(pnts[i].y), parseFloat(pnts[i].z));
        let p2 = new Cesium.Cartesian3.fromDegrees(parseFloat(pnts[i + 1].x), parseFloat(pnts[i + 1].y), parseFloat(pnts[i + 1].z));
        // 求两点之间的距离m
        let distance = Cesium.Cartesian3.distance(p1, p2).toFixed(2);
        // 求两点之间中心点
        let point1 = turf.point([parseFloat(pnts[i].x), parseFloat(pnts[i].y)]);
        let point2 = turf.point([parseFloat(pnts[i + 1].x), parseFloat(pnts[i + 1].y)]);
        let midpoint = turf.midpoint(point1, point2);
        let midLon = midpoint.geometry.coordinates[0];
        let midLat = midpoint.geometry.coordinates[1];
        let midAlt = (parseFloat(pnts[i].z) + parseFloat(pnts[i + 1].z)) / 2;

        // 计算中间点和两个点之间的角度
        let res = this.caculateMiddlePointAndRotate(p1, p2);

        // 设置自动规划表单
        let lnglat = { lng: pnts[i].x, lat: pnts[i].y };
        let temp = this.initAirLineData(lnglat, {
          flyHeight: pnts[i].z,
          isCollapse: true
        });
        this.pointItemList.push(temp);
        let text = '';
        let imgSrc = '';
        let color = '#808080';
        if (i == 0) {
          text = String('S');
          imgSrc = require('@/assets/img/i91.png');
          color = '#2D73E6';
        } else {
          text = String(i + 1);
          imgSrc = require('@/assets/img/i92.png');
        }

        // 点和点内标签
        let enPoint = new Cesium.Entity({
          name: 'airLinePoint',
          id: 'point_' + this.guid,
          position: p1,
          point: {
            show: true,
            pixelSize: 25,
            color: Cesium.Color.fromCssColorString(color),
            fillColor: Cesium.Color.fromCssColorString(color),
            scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 100000, 0.3)
          },
          label: {
            // text: String(i + 1),
            text: text,
            font: '24px sans-serif',
            scale: 0.6,
            font: 'bold 24px Microsoft YaHei',
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: false,
            outlineWidth: 0,
            scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 100000, 0.3)

            // text: text,
            // font: '20px sans-serif',
            // scale: 0.8,
            // font: "bold 24px Microsoft YaHei",
            // fillColor: Cesium.Color.WHITE,
            // horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            // verticalOrigin: Cesium.VerticalOrigin.CENTER,
            // disableDepthTestDistance: Number.POSITIVE_INFINITY,
            // showBackground: false,
            // outlineWidth: 0,
            // scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 300000, 0.5),
            // pixelOffset: new Cesium.Cartesian2(0, -4),
          },
          billboard: {
            show: false,
            width: 29,
            height: 38,
            image: imgSrc,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 300000, 0.5)
          }
        });
        // 线
        let enLine = new Cesium.Entity({
          name: 'airLinePoint',
          id: 'line_' + this.guid,
          position: Cesium.Cartesian3.fromDegrees(midLon, midLat, midAlt),
          polyline: {
            positions: [p1, p2],
            width: 2,
            material: Cesium.Color.fromCssColorString('#00EE9C'),
            scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 100000, 0.3)
          },
          label: {
            show: true,
            text: String(distance) + 'm',
            scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 50000, 0),
            scale: 0.5,
            font: 'bold 30px Microsoft YaHei',
            fillColor: Cesium.Color.fromCssColorString('#fff'),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: true,
            backgroundPadding: new Cesium.Cartesian2(10, 10)
          },
          billboard: {
            show: false,
            width: 29,
            height: 38,
            scale: 1.5,
            rotation: Cesium.Math.toRadians(res.rotate),
            image: require('@/assets/img/i93.png'),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 50000, 0.5)
          }
        });
        // 线上的距离标签
        // let enDist = new Cesium.Entity({
        //   name: 'airLinePoint',
        //   id: 'line_' + this.guid,
        //   position: Cesium.Cartesian3.fromDegrees(midLon, midLat, midAlt),
        //   label: {
        //     text: String(distance) + 'm',
        //     scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 50000, 0),
        //     scale: 0.5,
        //     font: "bold 30px Microsoft YaHei",
        //     fillColor: Cesium.Color.fromCssColorString("#fff"),
        //     horizontalOrigin: Cesium.VerticalOrigin.CENTER,
        //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
        //     showBackground: true,
        //     backgroundPadding: new Cesium.Cartesian2(10, 10),
        //   },
        // });
        airLinePointDataSource.entities.add(enPoint);
        airLinePointDataSource.entities.add(enLine);
      }

      // return;
      // 添加最后一个点
      let [last] = pnts.slice(-1);
      let lnglat = { lng: last.x, lat: last.y };
      let temp = this.initAirLineData(lnglat, {
        flyHeight: last.z,
        isCollapse: true
      }); // isCollapse 面板上航点是否折叠
      this.pointItemList.push(temp);

      let lastP = new Cesium.Cartesian3.fromDegrees(parseFloat(last.x), parseFloat(last.y), parseFloat(last.z));
      let enLast = new Cesium.Entity({
        name: 'airLinePoint',
        id: 'point_' + this.guid,
        position: lastP,
        point: {
          pixelSize: 25,
          color: Cesium.Color.fromCssColorString('#00EE9C'),
          fillColor: Cesium.Color.fromCssColorString('#00EE9C'),
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 100000, 0.3)
        },
        label: {
          text: String(pnts.length),
          font: '24px sans-serif',
          scale: 0.6,
          font: 'bold 24px Microsoft YaHei',
          fillColor: Cesium.Color.WHITE,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: false,
          outlineWidth: 0,
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 100000, 0.3)
        }
      });
      airLinePointDataSource.entities.add(enLast);
    },
    // 自动规划，添加自动生成的网格
    addGridAutoRes(res) {
      let { grids, pnts } = res;
      for (let i = 0; i < grids.length - 1; i++) {
        // console.log(grids[i], 'grid');
        let p1 = new Cesium.Cartesian3.fromDegrees(grids[i].r, grids[i].t, grids[i].h);
        let p2 = new Cesium.Cartesian3.fromDegrees(grids[i].l, grids[i].t, grids[i].h);
        let p3 = new Cesium.Cartesian3.fromDegrees(grids[i].l, grids[i].b, grids[i].h);
        let p4 = new Cesium.Cartesian3.fromDegrees(grids[i].r, grids[i].b, grids[i].h);

        let en = new Cesium.Entity({
          name: 'airLinePoint',
          // id: 'point_' + String(index) + '_' + this.guid,
          polygon: {
            hierarchy: [p1, p2, p3, p4, p1],
            outline: true,
            outlineColor: Cesium.Color.RED,
            outlineWidth: 20,
            // material: Cesium.Color.fromRandom().withAlpha(0.6),
            material: Cesium.Color.fromCssColorString('rgba(0, 238, 156, 0.4)'),
            scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 100000, 0.3)
          }
        });
        airLinePointDataSource.entities.add(en);
      }
    },
    // 自动规划，删除中间点之后，重新连接前后两个点的线段
    addAutoPolyline(c3Arr, guid) {
      // console.log(c3Arr, '线段的两个点');
      // 求两点之间的距离m
      let distance = Cesium.Cartesian3.distance(c3Arr[0], c3Arr[1]).toFixed(2);
      // 求两点之间中心点
      let lnglat1 = Utils.transformCartesian2WGS84(c3Arr[0]);
      let lnglat2 = Utils.transformCartesian2WGS84(c3Arr[1]);
      let point1 = turf.point([parseFloat(lnglat1.lng), parseFloat(lnglat1.lat)]);
      let point2 = turf.point([parseFloat(lnglat2.lng), parseFloat(lnglat2.lat)]);
      let midpoint = turf.midpoint(point1, point2);
      let midLon = midpoint.geometry.coordinates[0];
      let midLat = midpoint.geometry.coordinates[1];
      let midAlt = (parseFloat(lnglat1.alt) + parseFloat(lnglat2.alt)) / 2;

      // 线
      let enLine = new Cesium.Entity({
        name: 'airLinePoint',
        id: 'line_' + guid,
        position: Cesium.Cartesian3.fromDegrees(midLon, midLat, midAlt),
        polyline: {
          positions: c3Arr,
          width: 2,
          material: Cesium.Color.fromCssColorString('#00EE9C'),
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 100000, 0.3)
        },
        label: {
          text: String(distance) + 'm',
          scaleByDistance: new Cesium.NearFarScalar(500, 1.0, 50000, 0),
          scale: 0.5,
          font: 'bold 30px Microsoft YaHei',
          fillColor: Cesium.Color.fromCssColorString('#fff'),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10)
        }
      });
      airLinePointDataSource.entities.add(enLine);
    },
    // 点击折叠面板
    handleChange(val) {
      // console.log(val, '点击的面板');
      // console.log(this.pointItemList[val], '点击的面板');
      // 面板合起来
      if (!val) {
        // console.log('面板合起来', val);
        // console.log(this.activeNames, val);
        this.pointItemList[this.collapseIndex].isCollapse = true;
      } else {
        // console.log('面板展开', val);
        this.activeNames = val;
        this.collapseIndex = parseInt(this.activeNames);
        this.pointItemList[this.collapseIndex].isCollapse = false;
        for (let i = 0; i < this.pointItemList.length; i++) {
          if (i != val) {
            this.pointItemList[i].isCollapse = true;
          }
        }
      }
    },
    // 点击负载动作的每一个选项
    loadActionClick(index, name, src) {
      this.loadActionItemSelectedFlag = index;
      let temp = {
        index: index,
        name: name,
        src: src,
        label: name,
        actionParam: 0
      };
      // 选用当前激活的航点 this.activeNames pointItemList
      let activeIndex = parseInt(this.activeNames);
      let pointItem = this.pointItemList[activeIndex];
      // 如果已经存在就不要添加了，有可能会重复点击
      let foundObject = _.find(pointItem.loadActionSelectedList, function (e) {
        return e.index === temp.index;
      });
      if (!foundObject) {
        pointItem.loadActionSelectedList.push(temp);
      }
    },
    // 删除航点动作
    deleteAction(index) {
      let activeIndex = parseInt(this.activeNames);
      let loadActionSelectedList = this.pointItemList[activeIndex].loadActionSelectedList;
      loadActionSelectedList.splice(index, 1);
    },
    // 点击添加航点弹出航点面板
    addActionClick() {
      this.loadActionPanelShowFlag = true;
    },
    // 关闭负载面板
    closeLoadActionPanel() {
      this.loadActionPanelShowFlag = false;
      this.loadActionItemSelectedFlag = 0;
    },
    // 点击经纬度面板 1 减小经度 左，2 增加经度 右， 3 增加纬度 上， 4 减小纬度  下
    lonlatClick(flag, item, index) {
      let isLonNum = isNaN(parseFloat(item.lng));
      let isLatNum = isNaN(parseFloat(item.lat));
      if (isLonNum || isLatNum) {
        this.$message({
          message: '经纬度格式错误',
          type: 'warning'
        });
        return;
      }
      // 当前航点信息面板已打开
      let activeIndex = parseInt(this.activeNames);
      console.log(activeIndex, '当前激活的航点');
      let a = 0;
      switch (flag) {
        case 1:
          a = parseFloat(this.pointItemList[activeIndex].lng) - this.lonlatStep;
          if (a > 180 || a < -180) {
            this.$message({
              type: 'warning',
              message: '经度范围为[-180°-180°]'
            });
            return;
          }
          this.pointItemList[activeIndex].lng = parseFloat(a).toFixed(7);
          break;
        case 2:
          a = parseFloat(this.pointItemList[activeIndex].lng) + this.lonlatStep;
          console.log(a, '当前激活的航点，修改后的值');
          if (a > 180 || a < -180) {
            this.$message({
              type: 'warning',
              message: '经度范围为[-180°-180°]'
            });
            return;
          }
          this.pointItemList[activeIndex].lng = parseFloat(a).toFixed(7);
          break;
        case 3:
          a = parseFloat(this.pointItemList[activeIndex].lat) + this.lonlatStep;
          if (a > 90 || a < -90) {
            this.$message({
              type: 'warning',
              message: '纬度范围为[-90°-90°]'
            });
            return;
          }
          this.pointItemList[activeIndex].lat = parseFloat(a).toFixed(7);
          break;
        case 4:
          a = parseFloat(this.pointItemList[activeIndex].lat) - this.lonlatStep;
          if (a > 90 || a < -90) {
            this.$message({
              type: 'warning',
              message: '纬度范围为[-90°-90°]'
            });
            return;
          }
          this.pointItemList[activeIndex].lat = parseFloat(a).toFixed(7);
          break;
      }
      // 当前点的位置
      let pointItem = this.pointItemList[activeIndex];
      console.log(pointItem, '当前航点的json');
      this.updatePointPosition(activeIndex, pointItem);
      // let newPoint = new Cesium.Cartesian3.fromDegrees(parseFloat(pointItem.lng), parseFloat(pointItem.lat), parseFloat(pointItem.flyHeight))
      // let pointEn = airLinePointDataSource.entities.getById('point_' + pointItem.guid);
      // pointEn && (pointEn.position = newPoint)
      // 跟当前点关联的直线，分为手动规划(0)和自动规划(1)
      // 更新线段坐标
      this.getNewPolyline(index, item, this.manualOrAuto);
    },
    // 初始化规划信息
    initAirLineData(lnglat, opts) {
      let temp = {};
      // 航点编号
      temp.index = this.pointItemList.length;
      temp.lng = parseFloat(lnglat.lng).toFixed(7);
      temp.lat = parseFloat(lnglat.lat).toFixed(7);
      // 飞行高度
      temp.flyHeight = opts && opts.flyHeight ? opts.flyHeight : 100;
      temp.flyHeight = this.panelForm.isFollowAirLine ? this.panelForm.baseHeight : temp.flyHeight;
      // 飞行速度
      temp.flySpeed = 10;
      temp.flySpeed = this.panelForm.isFollowAirLine ? this.panelForm.baseSpeed : temp.flySpeed;
      // console.log(temp.flySpeed, 'temp.flySpeed');
      // 偏航角
      temp.yaw = 0;
      // 俯仰角
      temp.pitch = 0;
      // 航线标签
      temp.airLineLabel = '';
      // 航点动作
      temp.loadActionSelectedList = [];
      // 信息在面板上是否折叠，默认折叠
      temp.isCollapse = opts ? opts.isCollapse : true;
      temp.guid = Cesium.createGuid();
      this.guid = temp.guid;

      return temp;
    },
    // 取消规划
    cancelPlan() {
      // this.pointItemList = [];
      // this.pointList = [];
      // handler = handler && handler.destroy();
      // 1 关闭规划面板
      // this.close();
      this.$emit('close');
      handler = handler && handler.destroy();
      // 2 显示航线列表
      this.$emit('showListPanel');
      this.destory();
      this.destroy_location_icon();
    },
    // 保存规划
    async submitPlan() {
      if (!this.altitudeMode) {
        this.$message({
          type: 'warning',
          message: '请选择高度模式'
        });
        return;
      }
      if (!this.finishAction) {
        this.$message({
          type: 'warning',
          message: '请选择完成动作'
        });
        return;
      }
      // 航线总长度
      let distance = this.pointItemList.reduce((total, item, index) => {
        let dist = 0;
        if (this.pointItemList[index + 1]) {
          let cd = Cesium.Cartesian3.distance(
            Utils.transformWGS842Cartesian({
              lng: item.lng,
              lat: item.lat
            }),
            Utils.transformWGS842Cartesian({
              lng: this.pointItemList[index + 1].lng,
              lat: this.pointItemList[index + 1].lat
            })
          );
          dist = cd;
        }
        return total + parseInt(dist);
      }, 0);
      // 航线数据
      let flightCourseJson = {};
      // 航线名称，
      flightCourseJson.filename = this.filename ? this.filename : this.panelForm.name;
      // 添加高度模式
      flightCourseJson.altitudeMode = this.altitudeMode || "absolute";
      // 返航模式
      flightCourseJson.finishedAction = this.finishAction;
      // 如果返航模式是AutoLand，也就是原地降落，则设置完成动作参数，降落的绝对高度
      if (flightCourseJson.finishedAction == 'AutoLand') {
        if (!this?.finishedActionParam) {
          this.$message.error('请设置绝对高度');
          return;
        }
        if (isNaN(this?.finishedActionParam)) {
          this.$message.error('绝对高度格式设置错误');
          return;
        }
        flightCourseJson.finishedActionParam = parseInt(this.finishedActionParam);
      }
      // 跟随模式
      // flightCourseJson.isFollowAirLine = this.panelForm.isFollowAirLine;
      flightCourseJson.isFollowAirLine = false;
      // 全局速度和高度
      flightCourseJson.line = {};
      console.log('this.panelForm.baseSpeed', this.panelForm);
      // flightCourseJson.line.baseSpeed = Number((this.panelForm.baseSpeed).toFixed(1));
      flightCourseJson.line.baseSpeed = parseFloat(parseFloat(this.panelForm.baseSpeed).toFixed(1)) || 5;
      // flightCourseJson.line.baseHeight = Number((this.panelForm.baseHeight).toFixed(1));
      flightCourseJson.line.baseHeight = parseFloat(parseFloat(this.panelForm.baseHeight).toFixed(1)) || 100;
      // 增加手动规划或者自动规划字段
      flightCourseJson.manualOrAuto = this.manualOrAuto;
      // 增加场景
      // flightCourseJson.scene = this.panelForm.scene;
      flightCourseJson.scene = '消防';
      // 增加执行方式机巢还是无人机
      // flightCourseJson.actionWay = this.panelForm.actionWay;
      flightCourseJson.actionWay = '无人机';
      // 2024年5月14日 17:30:55 增加创建任务时候的参数
      let createTaskOnceItem = JSON.parse(window.localStorage.getItem('createTaskOnce'));
      console.log('createTaskOnceItem', createTaskOnceItem);
      window.localStorage.removeItem('createTaskOnce');
      flightCourseJson.createTaskOnceItem = createTaskOnceItem;
      // 航点列表
      flightCourseJson.points = [];
      // 自动规划生成航线参数
      let autoBuildLineParamsList = [];
      for (let i = 0; i < this.pointItemList.length; i++) {
        let temp = {};

        temp.lon = parseFloat(parseFloat(this.pointItemList[i].lng).toFixed(8));
        temp.lat = parseFloat(parseFloat(this.pointItemList[i].lat).toFixed(8));

        temp.alt = this.panelForm.isFollowAirLine ? this.panelForm.baseHeight : this.pointItemList[i].flyHeight;
        temp.alt = parseFloat(parseFloat(temp.alt).toFixed(1)) || 100;

        temp.speed = this.panelForm.isFollowAirLine ? this.panelForm.baseSpeed : this.pointItemList[i].flySpeed;
        temp.speed = parseFloat(parseFloat(temp.speed).toFixed(1));

        temp.nestId = null;
        temp.gimbalPitch = this.pointItemList[i].yaw;
        temp.pitch = this.pointItemList[i].pitch;
        temp.actions = this.pointItemList[i].loadActionSelectedList;
        temp.uuid = this.pointItemList[i].guid;
        flightCourseJson.points.push(temp);
        let xyz = {
          x: temp.lon,
          y: temp.lat,
          z: temp.alt
        };
        autoBuildLineParamsList.push(xyz);
      }
      let jsonStr = JSON.stringify(flightCourseJson);
      let options = {
        destination: '',
        distance: distance,
        flightCourseJson: jsonStr,
        // flightName: this.panelForm.name,
        flightName: flightCourseJson.filename,
        startLandingFieldId: this.panelForm.startLandingFieldId,
        endLandingFieldId: this.panelForm.endLandingFieldId,
        labelIds: [],
        lat: 0,
        lng: 0,
        nestId: null,
        pointCount: this.pointItemList.length,
        sourceType: 1, // 1 无人机
        time: parseInt(distance / flightCourseJson.line.baseSpeed)
      };
      // console.log('distance', distance);
      // console.log('flightCourseJson.baseSpeed', flightCourseJson.baseSpeed);
      // console.log('flightCourseJson', flightCourseJson);
      // console.log('options', options);
      // console.log('flightCourseJson', flightCourseJson);
      // console.log('flightCourseJson--->str', jsonStr);
      console.log('航线数据', flightCourseJson);
      console.log('航线数据提交', options);
      // return;
      options?.saveTitle == '保存';
      // 1 判断手动规划还是自动规划
      if (this.manualOrAuto == 0) {
        //手动规划
        // 先判断是更新还是插入
        // console.log(this.editAirLineId, '先判断是更新还是插入');
        if (this.editAirLineId) {
          // 更新
          // console.log('更新！！！');
          // console.log(this.editData, '编辑数据原始值');
          options.id = this.editAirLineId;
          // this.airway_check(options);
          // return;
        }
        this.airway_check(options, 'manual');
        this.editAirLineId = '';
      } else {
        // 1 自动规划
        // 2 先生成航线
        if (this.saveTitle == '生成航线') {
          // 加载动画
          const loading = this.$loading({
            lock: true,
            text: '正在生成自动规划航线',
            // spinner: 'el-icon-loading',
            // background: 'rgba(0, 0, 0, 0.7)',
            body: true,
            background: 'rgba(0, 0, 0, 0.7)',
            spinner: 'my-custom-loader'
          });
          try {
            let res = await API.AIRWAY.autoBuildFlightLine(autoBuildLineParamsList);
            // console.log(res, '生成的航线');
            // 3 先清除自动规划绘制的航线
            airLinePointDataSource.entities.removeAll();
            // 4 根据生成航线返回值，绘制网格码和生成的航线
            this.addPointAutoRes(res);
            this.addGridAutoRes(res);
            // 5 航点列表折叠起来
            // console.log(this.pointItemList, 'this.pointItemList');
            // console.log(this.activeNames, 'activeNames');
            // console.log(parseInt(this.activeNames), 'parseInt(this.activeNames)parseInt(this.activeNames)');
            this.pointItemList[parseInt(this.activeNames)].isCollapse = true;
            this.activeNames = -1;
            // 6 按钮修改为保存
            this.saveTitle = '保存';
          } catch (error) {
            console.log(error);
          } finally {
            loading.close();
          }
        } else {
          // 3 保存航线
          if (this.editAirLineId) {
            // 更新
            // console.log('更新！！！');
            // console.log(this.editData, '编辑数据原始值');
            options.id = this.editAirLineId;
            // this.airway_check(options);
            // return;
          }
          this.airway_check(options, 'autoPlan');
          this.editAirLineId = '';
        }
      }
      this.destroy_location_icon();
      // return;
      // 需要先检查航线
      // let type = this.manualOrAuto == 0 ? 'manual' : 'autoPlan';
      // options?.saveTitle == '保存'
      // console.log(options, 'options');
      // this.airway_check(options, type);
      // return;
      // 航线数据提交给后端
      // let res = await this.airway_add(option, '');
      // console.log(res, 'res');
      // // 手动规划成功
      // // 1 关闭规划面板
      // this.$emit('close');
      // // 2 显示航线列表
      // this.$emit('showListPanel');
      // // 3 清除规划数据
      // this.destory();
    },
    // 本次规划之后销毁
    destory() {
      handler = handler && handler.destroy();
      dynamicDrawFlag = false;
      dynamicLineEntity = null;
      dynamicEndPoint = null;
      this.pointItemList = [];
      this.pointList = [];
      airLinePointDataSource.entities.removeAll();
      // 移除起降场
      landingFieldInfoEntity && window.viewer.entities.remove(landingFieldInfoEntity);
      this.loadActionSelectedList = [];
      this.loadActionItemSelectedFlag = 0;
      this.activeNames = 0;
    },
    // 本次规划之后销毁
    destoryWithOutHandle() {
      // handler = handler && handler.destroy();
      dynamicDrawFlag = false;
      dynamicLineEntity = null;
      dynamicEndPoint = null;
      this.pointItemList = [];
      this.pointList = [];
      airLinePointDataSource.entities.removeAll();
      this.loadActionSelectedList = [];
      this.loadActionItemSelectedFlag = 0;
      this.activeNames = 0;
    },
    // 点击删除面板上的航点
    deletePoint(index) {
      /**
       * 自动规划点和线的guid关系   ○--------
       * 手动规划点和线的guid关系   --------○
       * **/
      // 1 删除点和关联的线
      let guid = this.pointItemList[index].guid;
      let enP = airLinePointDataSource.entities.getById(`point_${guid}`);
      let enDP = airLinePointDataSource.entities.getById(`point_down_${guid}`);
      let enL = airLinePointDataSource.entities.getById(`line_${guid}`);
      enP && airLinePointDataSource.entities.remove(enP);
      enDP && airLinePointDataSource.entities.remove(enDP);
      enL && airLinePointDataSource.entities.remove(enL);
      // if (this.manualOrAuto == 1) { // 自动规划
      //   // 删除上一次的线，如果有
      //   // let preGuid = '';
      //   // this.pointItemList[index - 1] && (preGuid = this.pointItemList[index - 1].guid);
      //   // this.pointItemList[index + 1] && (nextGuid = this.pointItemList[index + 1].guid);
      //   // let preEnL = airLinePointDataSource.entities.getById(`line_${preGuid}`);
      //   // preEnL && airLinePointDataSource.entities.remove(preEnL);
      // } else {
      //   // 手动规划
      //   // 删除下一次的线，如果有
      //   let nextGuid = '';
      //   this.pointItemList[index + 1] && (nextGuid = this.pointItemList[index + 1].guid);
      //   let nextEnL = airLinePointDataSource.entities.getById(`line_${nextGuid}`);
      //   nextEnL && airLinePointDataSource.entities.remove(nextEnL);
      // }
      let nextGuid = '';
      this.pointItemList[index + 1] && (nextGuid = this.pointItemList[index + 1].guid);
      let nextEnL = airLinePointDataSource.entities.getById(`line_${nextGuid}`);
      nextEnL && airLinePointDataSource.entities.remove(nextEnL);

      // 2 连接上一次的点和下一次的点
      if ((index - 1 >= 0) & (index < this.pointItemList.length - 1)) {
        // 上一个点
        let pPoint = this.pointItemList[index - 1];
        let nPoint = this.pointItemList[index + 1];
        // 将这两个点连成线，线的entity id 就是 pPoint的id
        let c31 = new Cesium.Cartesian3.fromDegrees(parseFloat(pPoint.lng), parseFloat(pPoint.lat), parseFloat(pPoint.flyHeight));
        let c32 = new Cesium.Cartesian3.fromDegrees(parseFloat(nPoint.lng), parseFloat(nPoint.lat), parseFloat(nPoint.flyHeight));
        // if (this.manualOrAuto == 1) {
        //   this.addAutoPolyline([c31, c32], pPoint.guid);
        // } else {
        //   this.addManualPolyline([c31, c32], { guid: nPoint.guid, flag: 1 });
        // }
        this.addManualPolyline([c31, c32], {
          guid: nPoint.guid,
          flag: 1
        });
      }

      // 3 删除面板上面的数据
      this.pointItemList.splice(index, 1);

      // 4 如果将航点全部删除
      if (this.pointItemList.length == 0) {
        // alert('你把点都删完了');
        // 重新开始规划
        this.planTips = '请点击地图重新规划';
        dynamicDrawFlag = true;
        dynamicLineEntity = null;
        dynamicEndPoint = null;
        this.pointItemList = [];
        this.pointList = [];
        airLinePointDataSource.entities.removeAll();
        this.loadActionSelectedList = [];
        this.loadActionItemSelectedFlag = 0;
        this.activeNames = 0;
        // 自动规划的时候要解开，可以在地图上继续规划
        this.autoPlaneMapFlag = true;

        // 如果是自动规划
        if (this.manualOrAuto == 1) {
          this.saveTitle = '生成航线';
        } else {
          this.saveTitle = '保存';
        }
      }
      // 删除底下的点
    },
    /** 点击飞行高度按钮
     *@Description:
     *@Date: 2023-10-10 10:28:56
     *@Params1: v 当前数值， item 当前航点数据，index 航点索引
     *@Return1:
     */
    flyHeightClickHandle(v, item, index) {
      if (this.panelForm.isFollowAirLine) {
        this.$message({
          type: 'warning',
          message: '跟随模式不可修改高度'
        });
        return;
      }
      let isFlyHeight = isNaN(parseFloat(item.flyHeight));
      if (isFlyHeight) {
        this.$message({
          message: '飞行高度格式错误',
          type: 'warning'
        });
        return;
      }
      let a = parseInt(item.flyHeight) + parseInt(v);
      if (a < 1) {
        this.$message({
          type: 'warning',
          message: '飞行高度小于1m，无法设置'
        });
      } else if (a > 500) {
        this.$message({
          type: 'warning',
          message: '飞行高度大于500m，请谨慎设置'
        });
        item.flyHeight = a;
      }
      else {
        item.flyHeight = a;
      }
      // 更新点
      this.updatePointPosition(index, item);
      // 更新线段
      this.getNewPolyline(index, item, this.manualOrAuto);
    },
    /** 点击飞行速度按钮
     *@Description: 点击飞行速度按钮
     *@Date: 2023-10-10 10:39:08
     *@Params1: v 当前数值， item 当前航点数据，index 航点索引
     *@Return1:
     */
    flySpeedClickHandle(v, item, index) {
      if (this.panelForm.isFollowAirLine) {
        this.$message({
          type: 'warning',
          message: '跟随模式不可修改飞行速度'
        });
        return;
      }
      let a = parseInt(item.flySpeed) + parseInt(v);
      if (a < 1 || a > 20) {
        this.$message({
          type: 'warning',
          message: '飞行速度不在[1-20]范围内，无法设置'
        });
      } else {
        item.flySpeed = a;
      }
    },
    /** 计算两个点(Cartesian3)的中间点和这两个点之间的角度
     *@Description: 计算两个点(Cartesian3)的中间点和这两个点之间的角度
     *@Date: 2023-10-10 11:16:06
     *@Params1:
     *@Return1: 返回object midPoint 和 rotate
     */
    caculateMiddlePointAndRotate(c31, c32) {
      let lnglat1 = Utils.transformCartesian2WGS84(c31);
      let lnglat2 = Utils.transformCartesian2WGS84(c32);
      // 1 计算中间点
      let point1 = turf.point([lnglat1.lng, lnglat1.lat]);
      let point2 = turf.point([lnglat2.lng, lnglat2.lat]);
      let midpoint = turf.midpoint(point1, point2);

      let midLon = midpoint.geometry.coordinates[0];
      let midLat = midpoint.geometry.coordinates[1];
      let midAlt = (parseFloat(lnglat1.alt) + parseFloat(lnglat2.alt)) / 2;
      let midC3 = new Cesium.Cartesian3.fromDegrees(midLon, midLat, midAlt);
      // 2 计算角度
      let bearing = turf.rhumbBearing(point1, point2);
      let rotate = 180 - bearing;
      return { midPoint: midC3, rotate: parseFloat(rotate) };
    },
    /** 获取新的线段坐标
     *@Description:
     *@Date: 2023-10-10 11:33:54
     *@Params1: index 当前航点索引，item 航点数据， flag 0 手动规划， 1 自动规划
     *@Return1:
     */
    getNewPolyline(index, item, flag) {
      if (index == this.pointItemList.length - 1) {
        // 最后一个点
        console.log(item, '我是最后一个点');
        let c31 = null,
          c30 = null;
        // 查找上一个点的坐标和当前新的点的坐标
        if (this.pointItemList.length > 1) {
          let lon1 = parseFloat(this.pointItemList[index - 1].lng);
          let lat1 = parseFloat(this.pointItemList[index - 1].lat);
          let alt1 = parseFloat(this.pointItemList[index - 1].flyHeight);
          let lon0 = parseFloat(item.lng);
          let lat0 = parseFloat(item.lat);
          let alt0 = parseFloat(item.flyHeight);
          c31 = new Cesium.Cartesian3.fromDegrees(lon1, lat1, alt1);
          c30 = new Cesium.Cartesian3.fromDegrees(lon0, lat0, alt0);
          if (flag == 0) {
            this.undatePolyline(c31, c30, item);
          } else {
            // 自动规划
            console.log('自动规划');
            // this.undatePolyline(c31, c30, this.pointItemList[index - 1]);
            this.undatePolyline(c31, c30, item);
          }
        }
        // 动态线段也要改变
        this.pointList[this.pointList.length - 1] = c30;
      } else if (index == 0) {
        // 第一个点
        // console.log(item, '我是第一个点');
        if (this.pointItemList.length > 1) {
          let lon0 = parseFloat(this.pointItemList[index + 1].lng);
          let lat0 = parseFloat(this.pointItemList[index + 1].lat);
          let alt0 = parseFloat(this.pointItemList[index + 1].flyHeight);
          let lon1 = parseFloat(item.lng);
          let lat1 = parseFloat(item.lat);
          let alt1 = parseFloat(item.flyHeight);
          let c31 = new Cesium.Cartesian3.fromDegrees(lon1, lat1, alt1);
          let c30 = new Cesium.Cartesian3.fromDegrees(lon0, lat0, alt0);
          console.log(c31, 'c31');
          console.log(c30, 'c30');

          // this.undatePolyline(c31, c30, this.pointItemList[index + 1]);
          console.log(this.pointItemList);
          console.log(this.pointItemList[index + 1], 'this.pointItemList[index + 1]');
          if (flag == 0) {
            this.undatePolyline(c31, c30, this.pointItemList[index + 1]);
          } else {
            this.undatePolyline(c31, c30, this.pointItemList[index + 1]);
          }
        }
      } else {
        // 中间点
        // console.log(item, '我是中间点');
        if (this.pointItemList.length > 2) {
          /**
           * 涉及到三个点和两条线段，这里点已经处理了，剩下线段和线段上的图标
           * 需要查找第一个线段和第二个线段
           * **/
          // 第一个线段
          let lon0 = parseFloat(this.pointItemList[index - 1].lng);
          let lat0 = parseFloat(this.pointItemList[index - 1].lat);
          let alt0 = parseFloat(this.pointItemList[index - 1].flyHeight);
          let lon1 = parseFloat(item.lng);
          let lat1 = parseFloat(item.lat);
          let alt1 = parseFloat(item.flyHeight);

          let lon2 = parseFloat(this.pointItemList[index + 1].lng);
          let lat2 = parseFloat(this.pointItemList[index + 1].lat);
          let alt2 = parseFloat(this.pointItemList[index + 1].flyHeight);
          let c31 = new Cesium.Cartesian3.fromDegrees(lon1, lat1, alt1);
          let c30 = new Cesium.Cartesian3.fromDegrees(lon0, lat0, alt0);
          let c32 = new Cesium.Cartesian3.fromDegrees(lon2, lat2, alt2);
          // this.undatePolyline(c30, c31, item);
          // this.undatePolyline(c31, c32, this.pointItemList[index + 1]);
          if (flag == 0) {
            this.undatePolyline(c30, c31, item);
            this.undatePolyline(c31, c32, this.pointItemList[index + 1]);
          } else {
            this.undatePolyline(c30, c31, this.pointItemList[index + 1]);
            this.undatePolyline(c31, c32, item);
          }
        }
      }
    },
    /** 更新线段坐标
     *@Description: 更新线段坐标
     *@Date: 2023-10-10 13:55:49
     *@Params1: c31 前一个点，c30 后一个点，item 航点数据
     *@Return1:
     */
    undatePolyline(c31, c30, item) {
      // 查找最后一个线段实体
      let line_ent = airLinePointDataSource.entities.getById('line_' + item.guid);
      console.log(item.guid, 'item.guid');
      console.log(line_ent, 'line_ent');
      let c3Arr = [c31, c30];
      line_ent.polyline.positions = c3Arr;
      // 计算中间点
      let res = this.caculateMiddlePointAndRotate(c31, c30);
      line_ent.position = res.midPoint;
      line_ent.billboard.rotation = Cesium.Math.toRadians(res.rotate);
      let distance = Cesium.Cartesian3.distance(c31, c30).toFixed(2);
      line_ent.label.text = String(distance) + 'm';
    },
    /** 更新点的坐标
     *@Description: 更新点的坐标
     *@Date: 2023-10-10 13:44:05
     *@Params1: index 当前航点索引，item 当前航点内容
     *@Return1:
     */
    updatePointPosition(index, item) {
      let newPoint = new Cesium.Cartesian3.fromDegrees(parseFloat(item.lng), parseFloat(item.lat), parseFloat(item.flyHeight));
      let pointEn = airLinePointDataSource.entities.getById('point_' + item.guid);
      let pointDownEn = airLinePointDataSource.entities.getById('point_down_' + item.guid);
      let lnglatC3 = Utils.simpleCartesian3ToWGS84(newPoint, window.viewer);
      let downP = new Cesium.Cartesian3.fromDegrees(parseFloat(lnglatC3.longitude), parseFloat(lnglatC3.latitude), 0);
      pointEn && (pointEn.position = newPoint);
      pointEn && (pointEn.polyline.positions = [newPoint, downP]);
      pointDownEn && (pointDownEn.position = downP);
    },
    /** 经纬度输入框输入事件
     *@Description: 经纬度输入框输入事件
     *@Date: 2023-10-10 13:40:03
     *@Params1:  flag 0 经度输入， 1 纬度输入
     *@Return1:
     */
    lonlatInputChangeHandle(flag, item, index) {
      // console.log('item', item);
      let isLonNum = isNaN(parseFloat(item.lng));
      let isLatNum = isNaN(parseFloat(item.lat));
      if (isLonNum || isLatNum) {
        this.$message({
          message: '经纬度格式错误',
          type: 'warning'
        });
        return;
      }
      // 更新点
      this.updatePointPosition(index, item);
      // 更新线段
      this.getNewPolyline(index, item, this.manualOrAuto);
    },
    /** 云台偏航角点击
     *@Description: 云台偏航角点击
     *@Date: 2023-10-10 14:03:39
     *@Params1: v 每点击一次增加或者减少的量
     *@Return1:
     */
    yawClickHandle(v, index, item) {
      let a = parseFloat(item.yaw) + parseFloat(v);
      if (a < -180 || a > 180) {
        this.$message({
          type: 'warning',
          message: '偏航角不在[-180-180]范围内，无法设置'
        });
        return;
      }
      item.yaw = a;
    },
    /** 偏航角点击
     *@Description:
     *@Date: 2023-10-10 14:15:07
     *@Params1:
     *@Return1:
     */
    pitchClickHandle(v, index, item) {
      let a = parseFloat(item.pitch) + parseFloat(v);
      if (a < -180 || a > 180) {
        this.$message({
          type: 'warning',
          message: '俯仰角不在[-180-180]范围内，无法设置'
        });
        return;
      }
      item.pitch = a;
    },
    /** 飞行监控-无人机中应用，面板向右移动
     *@Description: 飞行监控-无人机中应用，面板向右移动
     *@Date: 2023-10-19 14:16:01
     *@Params1:
     *@Return1:
     */
    openClickHandle() {
      Bus.$emit('airline-library-move-right', true);
    },
    /** 航点高度输入框设置
     *@Description:
     *@Author: name
     *@Date: 2023-11-23 16:15:38
     *@Params1:
     *@Return1:
     */
    flyHeightChangeHandle(item, index) {
      if (this.panelForm.isFollowAirLine) {
        this.$message({
          type: 'warning',
          message: '跟随模式不可修改高度'
        });
        return;
      }
      console.log('item', item);

      let isFlyHeight = isNaN(parseFloat(item.flyHeight));
      console.log('isFlyHeight', isFlyHeight);
      if (isFlyHeight) {
        this.$message({
          message: '飞行高度格式错误',
          type: 'warning'
        });
        return;
      }
      let c = parseFloat(item.flyHeight);
      if (c < 1) {
        this.$message({
          type: 'warning',
          message: '飞行高度小于1m，无法设置'
        });
        // if (c > 500) {
        //   item.flyHeight = 500;
        // }
        item.flyHeight = 1;
      }
      if (c > 500) {
        this.$message({
          type: 'warning',
          message: '飞行高度大于500m，请谨慎设置'
        });
      }

      console.log(item, index, '航点高度数据框设置');
      // 更新点
      this.updatePointPosition(index, item);
      // 更新线段
      this.getNewPolyline(index, item, this.manualOrAuto);
    },
    // 地名搜索1
    handle_query_address_async(address, cb) {
      // console.log(address, cb, '输入搜索搜索');
      // API.MAP.KeyWordsTDT({ keywords: address }).then((res) => {
      // console.log('天地图地名返回值', res);
      //     cb(res.data.pois);
      // });
      API.MAP.KeyWordsGaoDe({ keywords: address }).then((res) => {
        console.log('高德地图地名返回值', res);
        cb(res.data.pois);
      });
    },
    // 地名搜索2
    handle_select_address(item) {
      // console.log(item, '搜索输入');
      let viewer = window.viewer;
      viewer.trackedEntity && (viewer.trackedEntity = undefined);
      viewer.scene.globe.show = true;
      viewer.scene.skyAtmosphere.show = true;
      viewer.scene.globe.showGroundAtmosphere = true;
      // 模式关闭
      window.plate_data_source && (window.plate_data_source.show = false);
      if (item) {
        // let location = item.lonlat.split(',');
        let location = item.location.split(',');
        this.destination.name = item.name;
        this.destination.lon = Number(location[0]);
        this.destination.lat = Number(location[1]);
        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: this.destination.lon,
          lat: this.destination.lat,
          alt: 1000
        });
        viewer.camera.flyTo({
          destination: potiion
        });
        this.init_location_icon(potiion, item);
      }
    },
    /**
     *@Description: 兴趣点添加到地图上
     *@Date: 2023-11-02 14:46:48
     *@Params1:
     *@Return1:
     */
    init_location_icon(position, item) {
      // console.log(item, 'item兴趣点');
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      if (location_icon) {
        // location_icon.position = position;
        viewer.entities.remove(location_icon);
      }
      location_icon = viewer.entities.add({
        position,
        billboard: {
          width: 36,
          height: 36,
          image: require('@/assets/images/icons/icon_location.png'),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        },
        label: {
          // text: `${item.name}\r\n${item.address}`,
          text: `${item.name}`,
          orizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          showBackground: false,
          font: '13px monospace bolder',
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -18),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          fillColor: Cesium.Color.fromCssColorString('#FF0000') // 字体颜色
          // translucencyByDistance: new Cesium.NearFarScalar(0, 0, 1000000, 1),
        }
      });
    },
    destroy_location_icon() {
      if (!location_icon) {
        return location_icon;
      }
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.entities.remove(location_icon);
      location_icon = null;
    },
    /**@Description :  ******************************************* 向地图上添加警情任务的坐标点
     **@Date: 2024-05-19 10:37:25
    */
    addPointFromOriginJQTask() {
      let a = window.localStorage.getItem('jqTaskNoticeContent');
      if (!a || a === undefined) {
        return;
      }
      let jqTaskNoticeContentJSON = JSON.parse(a);
      console.log('jqTaskNoticeContentJSON', jqTaskNoticeContentJSON);
      let alert_positions = jqTaskNoticeContentJSON.alert_positions;
      alert_positions.forEach((v, index) => {
        let pos = v.value.split(',');
        let lon = parseFloat(pos[0]);
        let lat = parseFloat(pos[1]);
        let c1 = Cesium.Cartesian3.fromDegrees(lon, lat);
        let cen1 = window.viewer.entities.getById(`whxf-jq-origin-task-point-${index}`);
        cen1 && window.viewer.entities.remove(cen1);
        let en1 = new Cesium.Entity({
          id: `whxf-jq-origin-task-point-${index}`,
          position: c1,
          // point: {
          //   pixelSize: 16,
          //   // color: Cesium.Color.YELLOW,
          //   color: Cesium.Color.fromCssColorString('#00C297'),
          //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
          //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          //   disableDepthTestDistance: 99000000,
          //   fillColor: Cesium.Color.fromCssColorString('#00C297'), // 字体颜色
          //   translucencyByDistance: new Cesium.NearFarScalar(3000, 1, 10000000, 0)
          // },
          // label: {
          //   // text: item.name,
          //   text: v.label,
          //   show: true,
          //   showBackground: false,
          //   font: '10px monospace bolder',
          //   horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          //   verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          //   pixelOffset: new Cesium.Cartesian2(0, -2),
          //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
          //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          //   disableDepthTestDistance: 99000000,
          //   fillColor: Cesium.Color.fromCssColorString('#00C297'), // 字体颜色
          //   translucencyByDistance: new Cesium.NearFarScalar(3000, 1, 10000000, 0)
          // },
          label: {
            text: v.label,
            font: "8px PingFang bold", //字体样式
            fillColor: Cesium.Color.WHITE, //字体颜色
            backgroundColor: Cesium.Color.fromCssColorString("rgba(184, 78, 47, 0.88)"), //背景颜色
            showBackground: true, //是否显示背景颜色
            style: Cesium.LabelStyle.FILL, //label样式
            backgroundPadding: new Cesium.Cartesian2(5, 3),
            pixelOffset: new Cesium.Cartesian2(0, -42),
            scaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4)
          },
          billboard: {
            image: `image/s2.png`,
            pixelOffset: new Cesium.Cartesian2(0, -20),
            width: 32,
            height: 41,
            scale: 0.5,
            scaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4)
          },
        });
        window.viewer.entities.add(en1);
      });



      // let point1 = jqTaskNoticeContentJSON.alert_positions.position1;
      // let point2 = jqTaskNoticeContentJSON.alert_positions.position2;
      // let p1Arr = point1.split(',');
      // let p2Arr = point2.split(',');
      // let c1 = Cesium.Cartesian3.fromDegrees(Number(p1Arr[0]), Number(p1Arr[1]));
      // let c2 = Cesium.Cartesian3.fromDegrees(Number(p2Arr[0]), Number(p2Arr[1]));
      // let cen1 = window.viewer.entities.getById('jq-origin-task-point-1');
      // let cen2 = window.viewer.entities.getById('jq-origin-task-point-2');
      // cen1 && window.viewer.entities.remove(cen1);
      // cen2 && window.viewer.entities.remove(cen2);

      // let en1 = new Cesium.Entity({
      //   id: 'jq-origin-task-point-1',
      //   position: c1,
      //   point: {
      //     pixelSize: 16,
      //     // color: Cesium.Color.YELLOW,
      //     color: Cesium.Color.fromCssColorString('#00C297'),
      //   },
      //   label: {
      //     // text: item.name,
      //     text: '警情任务-1',
      //     show: true,
      //     showBackground: false,
      //     font: '10px monospace bolder',
      //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      //     pixelOffset: new Cesium.Cartesian2(0, -2),
      //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      //     disableDepthTestDistance: 99000000,
      //     fillColor: Cesium.Color.fromCssColorString('#00C297'), // 字体颜色
      //     translucencyByDistance: new Cesium.NearFarScalar(3000, 1, 10000000, 0)
      //   },
      // });

      // let en2 = new Cesium.Entity({
      //   id: 'jq-origin-task-point-2',
      //   position: c2,
      //   point: {
      //     pixelSize: 16,
      //     // color: Cesium.Color.YELLOW,
      //     color: Cesium.Color.fromCssColorString('#00C297'),
      //   },
      //   label: {
      //     // text: item.name,
      //     text: '警情任务-2',
      //     show: true,
      //     showBackground: false,
      //     font: '10px monospace bolder',
      //     // horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      //     // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      //     pixelOffset: new Cesium.Cartesian2(0, -2),
      //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      //     disableDepthTestDistance: 99000000,
      //     fillColor: Cesium.Color.fromCssColorString('#00C297'), // 字体颜色
      //     translucencyByDistance: new Cesium.NearFarScalar(3000, 1, 10000000, 0)
      //   },
      // });
      // window.viewer.entities.add(en1);
      // window.viewer.entities.add(en2);
    }
  },
  inject: ['g_cesium_layer']
};
</script>

<style lang="scss" scoped>
.create-line-container {
  width: 100%;
  height: 100%;
  background-color: #0a293a;
  position: relative;

  .header {
    width: 100%;
    height: 54px;
    background: #224d68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;

    .right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .open {
        color: #ffffff;
        cursor: pointer;
        margin-right: 10px;
      }

      .text {
        width: 64px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #00f5ff;
        line-height: 22px;
      }

      .img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;

      .img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  .air-line-info {
    width: 95%;
    height: 35px;
    background: #163b53;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px;

    .right,
    .left {
      height: 100%;
      width: 50%;
    }

    .left {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-direction: column;
      width: 100%;

      .name {
        // width: 64px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 100%;
        // background-color: #007aff;
        // line-height: 22px;
        // background-color: #00F5FF;
      }

      .type {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .img {
          height: 20px;
          width: 20px;
        }

        .name {
          width: 203px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
          line-height: 17px;
        }
      }

      .scene {
        // width: 203px;
        // height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        // line-height: 17px;
      }
    }

    .right {
      // background-color: #00F5FF;
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 8px 0;

      .img {
        width: 18px;
        height: 23px;
        cursor: pointer;
      }
    }
  }

  .height-mode {
    border: 1px solid #163b53;
    width: 95%;
    margin: 0 auto;
  }

  .line-point-body {
    width: 100%;
    height: calc(100% - 223px);
    position: relative;
    overflow-y: auto;

    .empty-tips {
      position: absolute;
      top: 49%;
      left: 30%;
      width: 126px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }

    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      height: 40px;
      // background-color: #00F5FF;
      width: 100%;

      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // width: 70px;
        width: 50%;

        .index {
          position: absolute;
          top: -7px;
          left: 6px;
          font-size: 12px;
          color: #fff;
        }
      }

      .right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        box-sizing: border-box;
        margin-top: -4px;

        img {
          width: 20px;
          height: 20px;
          margin-top: 3px;
          cursor: pointer;
        }
      }
    }
  }

  .foot {
    width: 100%;
    height: 120px;
    background: #133e55;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    // border: 1px solid red;
    position: absolute;
    bottom: 4px;

    // .input-action {
    //   width: 80%;
    //   height: 40%;
    //   // border: 1px solid red;
    //   display: flex;
    //   align-items: flex-end;
    //   justify-content: center;
    // }

    .btn-c {
      width: 100%;
      height: 60%;
      // background-color: #00f5ff;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        // width: 143px;
        width: 30%;
        height: 60%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }

      .btn-cancel {
        background: #1f526e;
      }

      .btn-submit {
        margin-left: 10px;
        background: #2b72ea;
      }
    }
    .foot-item {
      width: 100%;
      // border:1px solid #ff0000;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 2px 10%;
      .name {
        font-size: 12px;
        width: 40%;
      }
    }

    .input-action {
    }

    .input-action-param {
    }

    .btn-c {
      width: 100%;
      height: 60%;
      // background-color: #00f5ff;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        // width: 143px;
        width: 30%;
        height: 60%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }

      .btn-cancel {
        background: #1f526e;
      }

      .btn-submit {
        margin-left: 10px;
        background: #2b72ea;
      }
    }
  }

  .collapse-title-img {
    width: 20px;
    height: 27px;
    margin-right: 10px;
  }

  .load-action-panel {
    position: absolute;
    right: -305px;
    bottom: 1%;
    z-index: 301;
    width: 302px;
    height: 438px;
    background: #0a293a;
    box-sizing: border-box;
    padding: 0 10px;

    .action {
      .title {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background-color: #00F5FF;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;

        .close {
          img {
            width: 28px;
            height: 28px;
            cursor: pointer;
          }
        }
      }

      .body {
        .item {
          // width: 278px;
          height: 32px;
          background: #1f526e;
          border-radius: 4px;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 5px;
          box-sizing: border-box;
          cursor: pointer;

          .img {
            height: 30px;
            width: 30px;
          }

          .name {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .item-selected {
          background-color: #007aff;
        }
      }
    }
  }

  .search-info {
    position: fixed;
    top: 10px;
    right: 20px;
    // border: 1px solid yellow;
    z-index: 2;
    width: 300px;

    .autocomplete-dd {
      width: 100%;
      border: 0;
    }

    /deep/ .el-input__inner {
      color: #ffffff;
    }
  }

  /deep/ .el-collapse-item__header {
    margin-bottom: 5px;
    width: 100%;
    height: 40px;
    background: #133e55;
    color: #fff;
    border: 0;
    box-sizing: border-box;
    padding: 20px 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  /deep/ .el-collapse {
    border: 0;
  }

  /deep/ .el-collapse-item__wrap {
    border: 0;
  }

  /deep/ .el-collapse-item__content {
    background-color: #0a293a;
    margin-bottom: -26px;

    .el-input__inner {
      color: #fff;
    }

    .content {
      width: 100%;
      height: 100%;
      background-color: #0a293a;
      box-sizing: border-box;
      padding: 0 15px;

      .card {
        background-color: #133e55;
        margin-bottom: 8px;
      }

      .card1,
      .card3 {
        width: 100%;
        height: 170px;
        background: #133e55;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .item {
          height: 50%;
          width: 100%;
          // border: 1px solid red;
          box-sizing: border-box;
          display: flex;
          align-items: flex-start;
          justify-content: space-evenly;
          flex-direction: column;

          .title {
            color: #fff;
            // width: 125px;
            // height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            // line-height: 20px;
          }

          .body {
            width: 100%;
            height: auto;
          }
        }

        .item1 {
          .body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #0a293a;

            .btn {
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              // border: 1px solid yellow;
              height: 40px;
              background-color: #1f526e;
              cursor: pointer;
            }

            .el-input {
              width: 75px;
              // height: 30px;
            }
          }
        }

        .item2 {
          .title {
            margin-top: 10px;
          }

          .body {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: space-around;

            .img {
              height: 24px;
              width: 24px;
              cursor: pointer;
            }

            .slider {
              width: 45%;
            }

            .speed-input {
              width: 70px;
            }

            .unit {
              color: #fff;
            }

            .el-slider__runway {
              height: 3px;
            }

            .el-slider__bar {
              height: 3px;
            }

            .el-slider__button {
              height: 20px;
              width: 20px;
              background-color: #2b72ea;
              border: 2px solid #fff;
            }
          }
        }
      }

      .card1 {
        .body {
          height: 30px;

          .btn {
            height: 30px !important;
          }

          .el-input__inner {
            height: 30px;
          }
        }
      }

      .card2 {
        width: 100%;
        height: 111px;
        background: #133e55;
        border-radius: 4px;
        display: flex;

        .left {
          height: 100%;
          width: 70%;

          .title {
            height: 30%;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            box-sizing: border-box;
          }

          .lonlat {
            height: 70%;
            width: 100%;
            // background-color: #9B9B9B;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            padding: 0 10px;
            box-sizing: border-box;

            .lon,
            .lat {
              width: 100%;
              height: 45%;
              // height: 1px;
            }
          }

          .el-input__inner {
            height: 30px;
            color: #fff;
          }

          .el-input-group--prepend {
            height: 30px !important;
          }

          .el-input-group__prepend {
            background-color: #0a293a;
            border: 0;
            font-size: 9px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b72ea;
            padding: 0 10px;
          }
        }

        .right {
          height: 100%;
          width: 30%;
          // background-color: #00F5FF;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          img {
            width: 84%;
            height: 80%;
          }

          .img-arrow {
            position: absolute;
            width: 11px;
            height: 11px;
            cursor: pointer;
          }

          .img1 {
            top: 44%;
            left: 14%;
            transform: rotate(180deg);
          }

          .img2 {
            top: 44%;
            right: 15%;
            transform: rotate(0deg);
          }

          .img3 {
            top: 17%;
            right: 45%;
            transform: rotate(-90deg);
          }

          .img4 {
            bottom: 17%;
            right: 45%;
            transform: rotate(90deg);
          }
        }
      }

      .card3 {
        width: 100%;
        height: 172px;
        background: #133e55;
        border-radius: 4px;

        .item1 {
          .title {
            margin-top: 10px;
          }

          .body {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: space-around;
            background-color: transparent;

            .img {
              height: 24px;
              width: 24px;
              cursor: pointer;
            }

            .slider {
              width: 45%;
            }

            .speed-input {
              width: 70px;
            }

            .unit {
              color: #fff;
            }

            .el-slider__runway {
              height: 3px;
            }

            .el-slider__bar {
              height: 3px;
            }

            .el-slider__button {
              height: 20px;
              width: 20px;
              background-color: #2b72ea;
              border: 2px solid #fff;
            }
          }
        }

        .el-input__inner {
          height: 30px !important;
        }
      }

      .card4 {
        width: 100%;
        height: 84px;
        background: #133e55;
        border-radius: 4px;
        padding: 0 10px;
        box-sizing: border-box;

        .title {
          height: 40%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }

        .body {
          height: 60%;
          width: 100%;
          // background-color: #2B72EA;
        }
      }

      .card5 {
        width: 100%;
        min-height: 86px;
        height: auto;
        max-height: 240px;
        background: #163b53;
        border-radius: 4px;
        overflow-y: auto;
        padding: 0 10px;
        box-sizing: border-box;

        .title {
          height: 40%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          // background-color: #00F5FF;
          // margin-left: 10px;
          margin-top: 5px;
        }

        .body {
          height: 60%;
          width: 100%;

          // background-color: #2B72EA;
          // pointer-events: ;
          .row-add,
          .row-action {
            height: 32px;
            width: 100%;
            background: #1f526e;
            border-radius: 4px;
            padding: 0 5px;
            box-sizing: border-box;
            cursor: pointer;
            margin: 2px 0;

            .col {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
              height: 100%;
              // margin: 5px 0;

              .img {
                width: 20px;
                height: 20px;
              }

              .action {
                margin-left: 10px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1bbcfd;
              }
            }
          }

          .row-action {
            .col {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
              height: 100%;

              .left {
                height: 100%;
                width: 50%;
                display: flex;
                align-items: center;

                .img {
                  width: 28px;
                  height: 28px;
                }
              }

              .right {
                height: 100%;
                width: 50%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .img {
                  width: 22px;
                  height: 22px;
                  cursor: pointer;
                }
              }

              .name {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  /deep/ .el-input.is-disabled .el-input__inner {
    background-color: unset !important;
  }

  /deep/ .el-input__inner {
    color: #ffffff !important;
  }
}
</style>

<style lang="scss">
.confirm-cuetomer-class {
  width: 376px;
  border: 0 !important;
  height: 195px;
  background: #0e3042 !important;

  .el-message-box__header {
    width: 375px;
    height: 40px;
    background: #133e55;
    padding: 0;

    .el-message-box__title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff !important;
    }

    .el-message-box__headerbtn {
      display: none;
    }
  }

  .el-message-box__content {
    padding: 25px;

    .confirm-warning-icon-class {
      background-image: url("~@/assets/img/i94.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
    }

    .p {
      width: 280px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .el-message-box__btns {
    .el-button--default {
      width: 114px;
      height: 40px;
      background: #133e55;
      border-radius: 4px;
      color: #fff;
      /* width: 28px; */
      /* height: 20px; */
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      border: 0;
    }

    .el-button--primary {
      background: #007aff;
    }
  }

  .el-message-box__btns button:nth-child(2) {
    box-shadow: none;
  }
}

.create-airline-keyword-search-panel {
  // left: 0 !important;
  width: 300px !important;
  right: 100px;

  .el-scrollbar {
    width: 300px;

    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }

    li {
      box-sizing: border-box;
      padding: 0 10px;

      span {
        color: #ffffff !important;
      }
    }
  }
}

.el-autocomplete {
  border: 1px solid #04d5da;
  right: 0;
  background: rgba(22, 29, 45, 0.58);
  width: 300px;
}
</style>