import mount from "@/components/observe/mount"
import mqtt from "./mqtt"
import uav from "./uav"
import task from "./task"

export default () => ({
    mount,
    mqtt: mqtt(),
    uav: uav(),
    task: task()
})