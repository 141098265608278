import {
  values
} from "@antv/util";

export default () => ({
  // 任务详情抽屉显示标志
  drawerTaskDetailVisible: false,
  // 任务详情抽屉数据
  taskDetailData: {
    mainTaskName: '',
    mainTaskId: '',
    mainTaskcreateTime: '',
    mainTaskType: '',
    mainTaskSource: '',
    mainTaskTarget: '',
    subtaskList:[],
  },
  // 任务详情里面子任务选中索引
  subTaskSelectedIndex: 0,
  // 任务详情里面子任务操作记录
  subTaskLogList: [],
  // 主任务点击编辑的时候，已经有多少个子任务，这些子任务是不能删除的
  onceEditSubTaskCount: 1,
  // 主任务下面子任务的的航线数据
  whxfSubTaskAirLineList: [],
  //进入到协同视图里面的所有航线的四至范围 {maxLon, minLon, maxLat, minLat, maxAlt, minAlt}
  whxfXTSTAirLineSqrt: {},
  // 任务详情里面子任务的操作按钮，取消，立即执行，协同视图, 默认全部显示
  subtaskCancelFlag: true, // 只有待生效显示
  subtaskExecuteFlag: true, // 只有待生效显示
  subtaskViewFlag: true, // 全状态都有
  combinationUvaList: [], // 组合UAV列表
  flyerList:[], // 创建子任务的时候飞手列表
  uavLandingFieldList: [], // 无人机起降场下拉列表
  uavModelList: [], // 无人机型号下拉列表
  landingFieldInfo: {}, // 起降场信息
  subTaskDetailAirLinePreviewFlag: true, // 任务详情面板地图转到航线编辑，航线编辑里面只有返回按钮，不显示取消，保存按钮
})