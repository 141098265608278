<template>
  <div class="page-observe-fckernel-uav">
    <!-- 主界面 -->
    <div class="main-p" v-show="pageShow">
      <!-- 顶部栏 -->
      <Header :uav-data="uav.control.data"></Header>
      <!-- 侧边栏 0 无人机， 1 任务订单，2 视频墙 -->
      <SideBar :selectedFlag="0" class="side-bar"></SideBar>
      <!-- 表格相关 -->
      <div class="task-main">
        <!-- 顶部四个方块 -->
        <div class="info-c">
          <div class="item">
            <div class="l">
              <span class="n">待生效</span>
              <span class="v">{{ statusCount.waitCount }}</span>
            </div>
            <div class="r">
              <div class="i"></div>
            </div>
          </div>
          <div class="item">
            <div class="l">
              <span class="n">执行中</span>
              <span class="v">{{ statusCount.flyingCount }}</span>
            </div>
            <div class="r">
              <div class="i"></div>
            </div>
          </div>
          <div class="item">
            <div class="l">
              <span class="n">已取消</span>
              <span class="v">{{ statusCount.cancelCount }}</span>
            </div>
            <div class="r">
              <div class="i"></div>
            </div>
          </div>
          <div class="item">
            <div class="l">
              <span class="n">已完成</span>
              <span class="v">{{ statusCount.finishedCount }}</span>
            </div>
            <div class="r">
              <div class="i"></div>
            </div>
          </div>
        </div>
        <!-- 查询操作，创建任务 -->
        <div class="search-c">
          <el-form ref="searchForm" :inline="true" :model="formInline" class="demo-form-inline">
            <div class="v">
              <el-form-item label="任务名称" prop="taskName" label-width="100px">
                <el-input v-model="formInline.taskName" placeholder="请输入任务名称" size="small" style="width: 130px;"></el-input>
              </el-form-item>
              <el-form-item label="无人机名称" prop="deviceName" label-width="100px">
                <el-input v-model="formInline.deviceName" placeholder="请输入无人机名称或SN号" size="small" style="width: 180px;"></el-input>
              </el-form-item>
              <el-form-item label="任务状态" prop="status" label-width="100px">
                <el-select v-model="formInline.status" size="small" placeholder="不限" style="width: 120px;">
                  <el-option v-for="(item, index) in taskStatusList" :label="item.label" :value="item.value" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任务类型" prop="taskType" label-width="100px">
                <el-select v-model="formInline.taskType" size="small" placeholder="不限" style="width: 120px;">
                  <el-option v-for="(item, index) in taskTypeSearchList" :label="item.label" :value="item.value" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="起止时间" prop="rangTime" label-width="100px">
                <el-date-picker v-model="formInline.rangTime" type="datetimerange" start-placeholder="开始日期" style="width: 300px;" end-placeholder="结束日期" size="small" range-separator="至">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="o-1">
              <el-form-item>
                <el-button class="r-btn" type="info" @click="onResetSearch" size="mini">重置</el-button>
                <el-button class="s-btn" type="primary" @click="onSubmitSearch" size="mini">搜索</el-button>
              </el-form-item>
            </div>
            <div class="o-2">
              <el-form-item>
                <el-button class="c-btn" type="primary" size="mini" @click="handleCreateNewTask">
                  创建
                </el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 表格 -->
        <div class="table-c">
          <div class="r-table tableStyle" :style="cssVars">
            <el-table ref="mainTable" style="width: 100%" fit highlight-current-row :data="tableData" :row-class-name="tableRowClassName" :expand-row-keys="expandRows" :row-key="(row) => row.id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :default-expand-all="isExpand" @expand-change="handleExpandChange" @row-click="handleTableRowClick">
              <!-- 子任务 -->
              <el-table-column type="expand" width="1">
                <template slot-scope="props">
                  <!-- 这里也是一个table -->
                  <!-- @row-click="(row, column, event) => task_handleSubTableRowClick(row, column, event, props.row)" -->
                  <el-table :data="props.row.subtaskList" style="width: 100%" fit :row-class-name="subTableRowClassName">
                    <el-table-column width="141" align="left" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="taskTitle" width="240" label="子任务订单号" align="left" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span>{{ scope.row.taskTitle || "" }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="deviceHardId" width="180" label="分配无人机名称" align="left" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <!-- <span>{{ task_getDeviceNameByDeviceHardId( scope.row.deviceHardId ) || "" }}</span> -->
                        <span>{{  scope.row.deviceName }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="起点" width="210" align="left" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span>{{ scope.row.endCoord }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="终点" width="210" align="left" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span>{{ scope.row.startCoord }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="deliveryId-5" label="(预定) 起飞时间" width="210" align="left">
                      <template slot-scope="scope">
                        <span>{{ scope.row.startedAt }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="deliveryId-6" label="完成时间" width="210" align="left">
                      <template slot-scope="scope">
                        <span>{{ scope.row.endTime }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="deliveryId-7" label="任务状态" align="left">
                      <template slot-scope="scope">
                        <div class="f" :style="{
                          color: statusColor[getTaskStatus(scope.row)],
                          borderColor: statusColor[getTaskStatus(scope.row)],
                        }">
                          {{ getTaskStatus(scope.row) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" align="right">
                      <template slot-scope="scope">
                        <!-- 立即执行 
                        @click="task_subTaskHandleExecute(scope.$index, scope.row, props.$index, props.row)" -->
                        <el-button v-if="scope.row.taskStatus == 0" size="mini" type="text" @click="
                          task_subTaskHandleExecute(
                            scope.$index,
                            scope.row,
                            props.$index,
                            props.row
                          )
                          ">
                          <span class="op-name">立即执行</span>
                        </el-button>
                        <!-- 取消 -->
                        <el-button v-if="scope.row.taskStatus == 0" size="mini" type="text" @click="
                          task_subTaskHandleCancel(
                            scope.$index,
                            scope.row,
                            props.$index,
                            props.row
                          )
                          ">
                          <span class="op-name">取消</span>
                        </el-button>
                        <!-- 查看 -->
                        <el-button size="mini" type="text" @click="
                          task_handleSubTableRowClick(
                            scope.$index,
                            scope.row,
                            { label: '查看' },
                            props.$index,
                            props.row
                          )
                          ">
                          <span class="op-name">查看</span>
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column width="40" align="right" show-overflow-tooltip>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column type="selection" width="55" align="center" v-if="0">
              </el-table-column>
              <el-table-column width="42" align="left" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="taskId" label="序号" width="100" align="left" show-overflow-tooltip>
                <!-- <template slot-scope="scope">{{(pageIndex-1)*pageSize+scope.$index+1}}</template> -->
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column prop="taskName" label="任务名称" width="240" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.taskName || "" }}
                </template>
              </el-table-column>
              <el-table-column prop="taskType" label="任务类型" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span :style="{
                    color:
                      scope['row']['taskType'] == 'once'
                        ? '#9BFFFF'
                        : '#9BFFFF',
                  }">
                    {{ getTableColumnTaskType(scope.row.taskType) || '' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="taskStatus" width="210" label="任务状态" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <!-- 待生效 -->
                  <div v-if="scope.row.status == 0">
                    <img src="@/assets/img/i160.png" alt="" style="width: 62px; height: 28px" />
                  </div>
                  <!-- 执行中 -->
                  <div v-else-if="scope.row.status == 3">
                    <img src="@/assets/img/i162.png" alt="" style="width: 62px; height: 28px" />
                  </div>
                  <!-- 已取消 -->
                  <div v-else-if="scope.row.status == 99">
                    <img src="@/assets/img/i163.png" alt="" style="width: 62px; height: 28px" />
                  </div>
                  <!-- 已完成 -->
                  <div v-else-if="scope.row.status == 1">
                    <img src="@/assets/img/i161.png" alt="" style="width: 62px; height: 28px" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="taskTarget" width="210" label="任务目的" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ getTableColumnTaskTarget(scope.row.taskTarget) || "" }}
                </template>
              </el-table-column>
              <el-table-column prop="source" label="任务来源" width="210" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{
                    scope.row.source == "default" ? "手动新建" : "警情通知"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="210" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.createTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="finishTime" label="完成时间" width="210" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.finishTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作" width="130" align="right">
                <template slot-scope="scope">
                  <!-- 主任务编辑按钮 -->
                  <!-- 编辑 -->
                  <el-button v-if="scope.row.status == 0" size="mini" type="text" @click.stop="handleDetailMainTask(scope.$index, scope.row)">
                    <span class="op-name">编辑</span>
                  </el-button>
                  <!-- 协同视图 -->
                  <el-button v-if="scope.row.status != 0" size="mini" type="text" @click.stop="
                    task_handleMainTaskCollaborativeView(scope.$index, scope.row)">
                    <span class="op-name">协同视图</span>
                  </el-button>
                  <!-- 取消 -->
                  <el-button v-if="scope.row.status == 0 || (scope.row.taskType == 'period' && scope.row.status == 3)" size="mini" type="text" @click.stop=" task_handleMainTaskCancel(scope.$index, scope.row)">
                    <span class="op-name">取消</span>
                  </el-button>
                  <!-- 删除 -->
                   <el-button v-if="scope.row.status != 3" size="mini" type="text" @click.stop=" task_handleMainTaskDelete(scope.$index, scope.row)">
                    <span class="op-name" >删除</span>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column width="40" align="right" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页部分 -->
          <div class="p">
            <el-pagination background layout="prev, pager, next, sizes, jumper" :total="mainTableTotal" @current-change="handleCurrentChange" @size-change="handleSizeChange">
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 创建普通任务抽屉 -->
      <el-drawer title="" :visible.sync="drawerCreateTaskVisible" :direction="drawDirection" size="968px" :with-header="false" custom-class="drawer-panel drawer-task draw-create-task">
        <!-- 顶部，新建/编辑任务 -->
        <div class="header">
          <div class="l">
            <img v-if="createOrEditMainTask == 0" src="@/assets/img/i159.png" alt="" />
            <img v-else src="@/assets/img/Frame.png" alt="" />
            <span>{{ createOrEditMainTask == 0 ? '新建任务' : '编辑任务' }}</span>
          </div>
          <div class="r">
            <img @click="drawerCreateTaskVisible = false" src="@/assets/img/i151.png" alt="" />
          </div>
        </div>
        <div class="body">
          <!-- 卡片列表 -->
          <div class="create-task">
            <!-- 任务名称、类型、目的 -->
            <div class="task-n-t-d" style="flex-direction: column;">
              <el-row class="row">
                <el-col :span="8" class="col" style="width:360px">
                  <div class="form-label">任务名称</div>
                  <el-input class="input-name" v-model="createTaskForm.taskName" :placeholder="dynamicPlaceholder" size="small" style="width:269px"></el-input>
                </el-col>
                <el-col :span="8" class="col">
                  <div class="form-label">任务类型</div>
                  <el-select class="input-task" v-model="createTaskForm.taskType" :disabled="createOrEditMainTask == 1" placeholder="请选择任务类型" size="small" @change="handleSelectTaskType" style="width:168px">
                    <el-option v-for="taskType in taskTypeList" :key="taskType.value" :label="taskType.label" :value="taskType.value" :disabled="taskType.disabled">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8" class="col">
                  <div class="form-label">任务目的</div>
                  <el-select class="input-task" v-model="createTaskForm.taskTarget" placeholder="请选择任务目的" size="small" style="width:168px">
                    <el-option v-for="item in taskPurposeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row class="row" v-if="jqTaskFlag == 0" style="justify-content: flex-start; margin-top:20px">
                <el-col :span="8" class="col" style="justify-content: flex-start">
                  <div class="form-label">警情编号 :</div>
                  <!-- <span style="color: #fff">{{jqOriginTaskData.id || 'xx'}}</span> -->
                  <span style="color: #fff">{{ jqOriginTaskData.id }}</span>
                </el-col>
              </el-row>
            </div>
            <div class="task-card-c custom-scrollbar">
              <!-- 任务卡片列表 lineCardList -->
              <div class="card-list" v-for="(item, index) in lineCardListFilter" :key="index" :style="createTaskForm.taskType == 'period' ? 'height: 177px; padding:10px 0 0 0' : 'height: 136px;padding:10px 0'">
                <!-- 卡片-左侧序号 -->
                <!-- <div class="left" v-show="createTaskForm.taskType == 'once'">{{ index < 9 ? '0' + (index + 1) : index + 1 }}</div> -->
                <!-- 卡片-右侧内容 -->
                <div class="right">
                  <!-- 编辑的时候，显示子任务订单编号 -->
                  <div class="task-title" v-if="createOrEditMainTask == 1 && item.taskTitle">
                    子任务编号：{{  item.taskTitle }}
                  </div>
                  <!-- 派机机巢 、无人机型号、无人机、飞手选择 -->
                  <el-row class="row" style="justify-content: space-between;" :style="createTaskForm.taskType == 'period' ? 'height: 38px; padding-top: 10px' : 'height: 50%'">
                    <!-- 派机机巢 -->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.landingFieldId" placeholder="请选择起降场" size="small" @change="(v) => handleSelectUavLand(v, item)">
                        <el-option v-for="uavLand in task.uavLandingFieldList" :key="uavLand.id" :label="uavLand.name" :value="uavLand.id">
                        </el-option>
                      </el-select>
                    </el-col>
                    <!-- 无人机型号 -->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.goodsId" placeholder="请选择无人机型号" size="small" @change="(v) => handleSelectUavModel(v, item)">
                        <el-option v-for="(dvt) in task.uavModelList" :key="dvt.goodsId" :label="dvt.goodsName" :value="dvt.goodsId">
                        </el-option>
                      </el-select>
                    </el-col>
                    <!-- 选择无人机 item.deviceList -->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.deviceHardId" placeholder="选择无人机" size="small" @change="(v) => handleSelectUavDevice(v, item)" @visible-change="(v) => task_handleSelectUavPanelVisible(v, item)">
                        <el-option v-for="(dvhid) in task.combinationUvaList" :key="dvhid.deviceHardId" :label="dvhid.deviceName" :value="dvhid.deviceHardId" :disabled="dvhid.disabled">
                        </el-option>
                      </el-select>
                    </el-col>
                    <!-- 选择飞手 item.flyerList-->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.flyIds" placeholder="选择飞手" size="small" multiple @change="(v) => handleSelectFlyer(v, item)" @visible-change="(v) => task_handleSelectFlyerPanelVisible(v, item)" @remove-tag="(v) => task_handleFlyerListRemoveTag(v, item)">
                        <el-option v-for="flyer in task.flyerList" :key="flyer.id" :label="flyer.title" :value="flyer.id" :disabled="flyer.disabled">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <!-- 单次任务 -->
                  <el-row class="row" v-show="createTaskForm.taskType == 'once'" style="margin-top: 0px;">
                    <el-col :span="8" class="col">
                      <div class="form-label">起飞时间</div>
                      <el-date-picker size="small" v-model="item.startedAt" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="{
                              disabledDate: (time) =>
                                time.getTime() <
                                new Date(new Date().setHours(0, 0, 0, 0))
                            }">
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8" class="col" v-if="true" style="justify-content: space-between">
                      <!-- <el-select v-if="false" class="input-task" v-model="createTaskForm.type" placeholder="选择航线" size="small">
                            <el-option v-for="item in taskTypeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                          </el-select> -->
                    </el-col>
                    <el-col :span="4" class="col" style="text-align: right; justify-content: flex-end; margin-left: 28px; ">
                      <!-- <el-link :underline="false" style="color: #00F5FF;" @click="handleAddLine(item)"
                            v-if="!item.flightLineId">新增航线</el-link> -->
                      <el-button v-if="!item.flightLineId" class="add-line-btn" type="primary" style="border: 0; padding" @click="task_handleAddNewAirLine(item)">新增航线</el-button>
                      <el-button v-if="item.flightLineId" class="add-line-btn" type="primary" style="border: 0" @click="task_handleEditAirLine(item)">编辑航线</el-button>
                      <el-button v-if="item.flightLineId" class="add-line-btn del-line-btn" type="primary" style="border: 0" @click="task_handleDeleteAirLine(item)">删除航线</el-button>
                    </el-col>
                    <!-- <el-col :span="0" class="col"></el-col> -->
                  </el-row>
                  <!-- 周期任务 -->
                  <el-row class="row" v-show="createTaskForm.taskType == 'period'" style="border: 0 solid red; flex-direction: column; margin: 21px 0 0 31px">
                    <!-- <el-row class="row" style="border: 0 solid green">
                    </el-row> -->
                    <!-- 循环周期 -->
                    <el-row class="row" style="border: 0 solid blue; width: 100%; margin-left: 0; justify-content: flex-start;">
                      <el-col :span="16" class="col">
                        <div class="form-label">循环周期</div>
                        <!-- 周期任务的有效开始时间 -->
                        <el-date-picker size="small" v-model="item.validStartTime" type="datetime" placeholder="选择日期时间" :picker-options="{
                              disabledDate: (time) =>
                                time.getTime() <
                                new Date(new Date().setHours(0, 0, 0, 0)),
                            }">
                        </el-date-picker>

                        <div style="
                            width: 16px;
                            height: 1px;
                            background-color: rgba(255, 255, 255, 0.51);
                            margin-left: 10px;
                          "></div>
                        <!-- 周期任务的有效结束时间 -->
                        <el-date-picker size="small" v-model="item.validEndTime" type="datetime" placeholder="选择日期时间" style="margin-left: 10px" :picker-options="{
                                disabledDate: (time) =>
                                  time.getTime() <
                                  new Date(new Date().setHours(0, 0, 0, 0)),
                              }">
                        </el-date-picker>
                      </el-col>
                      <el-col :span="4" class="col"></el-col>
                    </el-row>
                    <!-- 循环方式 -->
                    <el-row class="row" style="
                        border: 0px solid blue;
                        width: 100%;
                        margin: 21px 0 0 0;
                        justify-content: flex-start;
                      ">
                      <el-col :span="16" class="col" style="border: 0px solid green">
                        <div class="form-label">循环方式 : </div>
                        <div style="
                            display: flex;
                            width: 80%;
                            align-items: center;
                            justify-content: center;
                          ">
                          <span style="width: 30px; color: #fff; font-size: 12px">每</span>
                          <el-input style="
                              width: 160px;
                              height: 28px;
                              margin-left: 10px;
                              margin-right: 10px;
                            " class="input-name" v-model="item.day" placeholder="" size="small"></el-input>
                          <span style="width: 30px; color: #fff; font-size: 12px">天</span>
                          <el-input style="
                              width: 160px;
                              height: 28px;
                              margin-left: 10px;
                              margin-right: 10px;
                            " class="input-name" v-model="item.hour" placeholder="" size="small"></el-input>
                          <span style="width: 30px; color: #fff; font-size: 12px">时</span>
                          <el-input style="
                              width: 160px;
                              height: 28px;
                              margin-left: 10px;
                              margin-right: 10px;
                            " class="input-name" v-model="item.minute" placeholder="" size="small"></el-input>
                          <span style="width: 30px; color: #fff; font-size: 12px">分</span>
                        </div>
                      </el-col>
                      <!-- 周期任务的button移动到这里 -->
                      <el-col :span="4" class="col" style="height: 28px">
                        <!-- <el-link :underline="false" style="color: #00F5FF; margin: 30px 0;" @click="handleAddLine(item)">新增航线</el-link> -->
                        <el-button v-if="!periodTaskAirLineId" class="add-line-btn" type="primary" style="margin: 20px 0; border: 0" :style="!periodTaskAirLineId ? 'margin-left: 173px' : ''" @click="task_handleAddNewAirLine(item)">新增航线</el-button>
                        <el-button v-if="periodTaskAirLineId" class="add-line-btn" type="primary" style="margin: 20px 0;border: 0" :style="periodTaskAirLineId ? 'margin-left: 89px' : ''" @click="task_handleEditAirLine(item)">编辑航线</el-button>
                        <el-button v-if="periodTaskAirLineId" class="add-line-btn del-line-btn" type="primary" style="margin: 20px 10px; border: 0" @click="task_handleDeleteAirLine(item)">删除航线</el-button>
                      </el-col>
                    </el-row>
                  </el-row>
                  <!-- 删除一个卡片 第一个不能删除-->
                  <div class="delete-card" v-if="index >= task.onceEditSubTaskCount" @click="handleDeleteLineCard(index, item)">
                    X
                  </div>
                </div>
              </div>
              <div class="create-btn" @click="handleAddLineCard" v-show="createTaskForm.taskType == 'once'">
                <img src="@/assets/img/add.png" alt="" />
              </div>
              <div class="line-conflict-deal">
                <span>航线冲突处理 ：</span>
                <div class="d-c">
                  <el-row>
                    <el-col :span="8" class="d-c-item" style="width:177px; margin-right: 32px;">
                      <span style="width: 65px">飞行间距</span>
                      <el-input style="width: 80px" v-model="createTaskForm.flightlineConflictStrategy
                          .flightDistance
                          " placeholder="" size="small"></el-input>
                      <span style="margin-left: 5px">米</span>
                    </el-col>
                    <el-col :span="8" class="d-c-item" style="width:214px; margin-right: 32px;">
                      <span style="width: 95px">飞行间隔时间</span>
                      <el-input style="width: 80px" v-model="createTaskForm.flightlineConflictStrategy.flightTime
                          " placeholder="" size="small"></el-input>
                      <span style="margin-left: 5px">秒</span>
                    </el-col>
                    <el-col :span="8" class="d-c-item">
                      <span style="width: 65px">终点间距</span>
                      <el-input style="width: 80px" v-model="createTaskForm.flightlineConflictStrategy
                          .destinationDistance
                          " placeholder="" size="small"></el-input>
                      <span style="margin-left: 5px">米</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <!-- 备注 -->
            <div class="task-remark">
              <div class="form-label">
                <span>任务备注</span>
              </div>
              <el-input v-model="createTaskForm.remark" placeholder="请输入任务备注" size="small" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"></el-input>
            </div>
          </div>
        </div>
        <div class="foot">
          <el-button class="p" @click="handleCreateNewTaskSubmit">确定</el-button>
        </div>
      </el-drawer>
      <!-- 创建警情任务抽屉 -->
      <el-drawer title="" :visible.sync="jqTaskPanelVisible" :direction="drawDirection" size="50%" :with-header="false" custom-class="drawer-panel drawer-task draw-create-task">
        <!-- 顶部，新建编辑任务 -->
        <div class="header">
          <div class="l">
            <img src="@/assets/img/i159.png" alt="" />
            <span style="font-size: 16px; width: 160px">{{
              "警情工单处理"
            }}</span>
          </div>
          <div class="r">
            <img @click="jqTaskPanelVisible = false" src="@/assets/img/i151.png" alt="" />
          </div>
        </div>
        <div class="body">
          <!-- 卡片列表 -->
          <div class="create-task">
            <!-- 任务名称、类型、目的 -->
            <div class="task-n-t-d" style="flex-direction: column ;margin-top: 0px;">
              <el-row class="row row-title" style="margin-bottom: 10px">
                <el-col :span="8" class="col" style="justify-content: flex-start">
                  <div class="form-label" style="font-size: 15px; width: 160px">
                    警情工单详情
                  </div>
                </el-col>
              </el-row>
              <el-row class="row" style=" justify-content: flex-start">
                <el-col :span="8" class="col" style=" justify-content: flex-start;margin-right:4px">
                  <div class="form-label">工单编号 :</div>
                  <div style="color: #fff">
                    {{ getJQAlertId(jqOriginTaskData) }}
                  </div>
                </el-col>
                <el-col :span="8" class="col" style=" justify-content: center; margin-right: 30px">
                  <div class=" form-label">工单时间 :
                  </div>
                  <div style="color: #fff">
                    {{ getJQDate(jqOriginTaskData) }}
                  </div>
                </el-col>
                <el-col :span="8" class="col" style=" justify-content: flex-end">
                  <div class="form-label">任务目的</div>
                  <el-select class="input-task" v-model="createTaskForm.taskTarget" placeholder="请选择任务目的" size="small" :disabled="jqTaskFlag == 0">
                    <el-option v-for="item in taskPurposeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="8" class="col" style=" justify-content: flex-start">
                  <span style="color: #fff; font-size: 14px">{{
                    `警情点地址 : `+  getJQContent(jqOriginTaskData)
                  }}</span>
                </el-col>
              </el-row>
            </div>
            <div class="task-card-c custom-scrollbar" style="margin-top: 10px;">
              <div class="sub-title" style="font-size: 15px; margin-bottom: 10px;">消防资源调度</div>
            </div>
            <div class="task-card-c custom-scrollbar" style="margin-top: 0px; max-height: calc(100% - 320px)">
              <!-- 标题 -->
              <!-- 任务卡片列表 -->
              <div class="card-list" v-for="(item, index) in lineCardList" :key="index">
                <!-- 卡片 -->
                <div class="left" v-show="createTaskForm.taskType == 'once'">
                  {{ index < 10 ? '0' + (index + 1) : index + 1 }} </div>
                <div class="right">
                  <!-- 起降场，无人机、飞手选择 -->
                  <el-row class="row" style="justify-content: space-between">
                    <!-- 起降场 -->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.landingFieldId" placeholder="请选择起降场" size="small" @change="(v) => handleSelectUavLand(v, item)">
                        <el-option v-for="uavLand in task.uavLandingFieldList" :key="uavLand.id" :label="uavLand.name" :value="uavLand.id" :disabled="uavLand.disabled">
                        </el-option>
                      </el-select>
                    </el-col>
                    <!-- 无人机型号 -->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.goodsId" placeholder="请选择无人机型号" size="small" @change="(v) => handleSelectUavModel(v, item)">
                        <el-option v-for="dvt in task.uavModelList" :key="dvt.goodsId" :label="dvt.goodsName" :value="dvt.goodsId">
                        </el-option>
                      </el-select>
                    </el-col>
                    <!-- 无人机 item.deviceList-->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.deviceHardId" placeholder="请选择无人机" size="small" @change="(v) => handleSelectUavDevice(v, item)" @visible-change="(v) => task_handleSelectUavPanelVisible(v, item)">
                        <el-option v-for="dvhid in task.combinationUvaList" :key="dvhid.deviceHardId" :label="dvhid.deviceName" :value="dvhid.deviceHardId" :disabled="dvhid.disabled">
                        </el-option>
                      </el-select>
                    </el-col>
                    <!-- 选择飞手 -->
                    <el-col :span="5" class="col">
                      <el-select class="input-task" v-model="item.flyIds" placeholder="选择飞手" size="small" multiple @change="(v) => handleSelectFlyer(v, item)" @visible-change="(v) => task_handleSelectFlyerPanelVisible(v, item)" @remove-tag="(v) => task_handleFlyerListRemoveTag(v, item)">
                        <el-option v-for="flyer in task.flyerList" :key="flyer.id" :label="flyer.title" :value="flyer.id" :disabled="flyer.disabled">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <!-- 单次任务 -->
                  <el-row class="row" v-show="createTaskForm.taskType == 'once'" style="margin-top: 0px">
                    <el-col :span="8" class="col">
                      <div class="form-label">起飞时间</div>
                      <el-date-picker size="small" v-model="item.startedAt" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="{
                              disabledDate: (time) =>
                                time.getTime() <
                                new Date(new Date().setHours(0, 0, 0, 0)),
                            }">
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8" class="col" v-if="true">
                      <!-- <el-select v-if="false" class="input-task" v-model="createTaskForm.type" placeholder="选择航线" size="small">
                            <el-option v-for="item in taskTypeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                          </el-select> -->
                    </el-col>
                    <el-col :span="4" class="col" style="text-align: right;" :style="!item.flightLineId ? 'padding-left: 98px' : 'padding-left: 14px'">
                      <!-- <el-link :underline="false" style="color: #00F5FF;" @click="handleAddLine(item)"
                            v-if="!item.flightLineId">新增航线</el-link> -->
                      <el-button v-if="!item.flightLineId" class="add-line-btn" type="primary" style="border: 0" @click="task_handleAddNewAirLine(item)">新增航线</el-button>
                      <el-button v-if="item.flightLineId" class="add-line-btn" type="primary" style="border: 0" @click="task_handleEditAirLine(item)">编辑航线</el-button>
                      <el-button v-if="item.flightLineId" class="add-line-btn del-line-btn" type="primary" style="border: 0" @click="task_handleDeleteAirLine(item)">删除航线</el-button>
                    </el-col>
                    <el-col :span="0" class="col"></el-col>
                  </el-row>
                  <!-- 周期任务 -->
                  <el-row class="row" v-show="createTaskForm.taskType == 'period'" style="border: 0 solid red; flex-direction: column">
                    <el-row class="row" style="border: 0 solid green">
                      <!-- <el-link :underline="false" style="color: #00F5FF; margin: 30px 0;" @click="handleAddLine(item)">新增航线</el-link> -->
                      <el-button v-if="!periodTaskAirLineId" class="add-line-btn" type="primary" style="margin: 30px 0; border: 0" @click="task_handleAddNewAirLine(item)">新增航线</el-button>
                      <el-button v-if="periodTaskAirLineId" class="add-line-btn" type="primary" style="margin: 30px 0; border: 0" @click="task_handleEditAirLine(item)">编辑航线</el-button>
                      <el-button v-if="periodTaskAirLineId" class="add-line-btn del-line-btn" type="primary" style="margin: 30px 10px; border: 0" @click="task_handleDeleteAirLine(item)">删除航线</el-button>
                    </el-row>
                    <!-- 循环周期 -->
                    <el-row class="row" style="border: 0 solid blue; width: 100%; margin-left: 0">
                      <el-col :span="16" class="col">
                        <div class="form-label">循环周期</div>
                        <!-- 周期任务的有效开始时间 -->
                        <el-date-picker size="small" v-model="item.validStartTime" type="datetime" placeholder="选择日期时间" :picker-options="{
                              disabledDate: (time) =>
                                time.getTime() <
                                new Date(new Date().setHours(0, 0, 0, 0)),
                            }">
                        </el-date-picker>

                        <div style="
                            width: 16px;
                            height: 1px;
                            background-color: rgba(255, 255, 255, 0.51);
                            margin-left: 10px;
                          "></div>
                        <!-- 周期任务的有效结束时间 -->
                        <el-date-picker size="small" v-model="item.validEndTime" type="datetime" placeholder="选择日期时间" style="margin-left: 10px" :picker-options="{
                                disabledDate: (time) =>
                                  time.getTime() <
                                  new Date(new Date().setHours(0, 0, 0, 0)),
                              }">
                        </el-date-picker>
                      </el-col>
                      <el-col :span="4" class="col"></el-col>
                    </el-row>
                    <!-- 循环方式 -->
                    <el-row class="row" style="
                        border: 0px solid blue;
                        width: 100%;
                        margin: 30px 0;
                        margin-left: 0;
                      ">
                      <el-col :span="16" class="col" style="border: 0px solid green">
                        <div class="form-label">循环方式 :</div>
                        <div style="
                            display: flex;
                            width: 80%;
                            align-items: center;
                            justify-content: center;
                          ">
                          <span style="width: 30px; color: #fff; font-size: 12px">每</span>
                          <el-input style="
                              width: 160px;
                              margin-left: 10px;
                              margin-right: 10px;
                            " class="input-name" v-model="item.day" placeholder="" size="small"></el-input>
                          <span style="width: 30px; color: #fff; font-size: 12px">天</span>
                          <el-input style="
                              width: 160px;
                              margin-left: 10px;
                              margin-right: 10px;
                            " class="input-name" v-model="item.hour" placeholder="" size="small"></el-input>
                          <span style="width: 30px; color: #fff; font-size: 12px">时</span>
                          <el-input style="
                              width: 160px;
                              margin-left: 10px;
                              margin-right: 10px;
                            " class="input-name" v-model="item.minute" placeholder="" size="small"></el-input>
                          <span style="width: 30px; color: #fff; font-size: 12px">分</span>
                        </div>
                      </el-col>
                      <el-col :span="4" class="col"></el-col>
                    </el-row>
                  </el-row>
                  <!-- 删除一个卡片 第一个不能删除-->
                  <div class="delete-card" v-if="index >= task.onceEditSubTaskCount" @click="handleDeleteLineCard(index, item)">
                    X
                  </div>
                </div>
              </div>
              <div class="create-btn" @click="handleAddLineCard" v-show="createTaskForm.taskType == 'once'">
                <img src="@/assets/img/add.png" alt="" />
              </div>
              <div class="line-conflict-deal">
                <span>航线冲突处理 ：</span>
                <div class="d-c">
                  <el-row>
                    <el-col :span="8" class="d-c-item" style="width:177px; margin-right: 32px;">
                      <span style="width: 65px">飞行间距</span>
                      <el-input style="width: 80px" v-model="createTaskForm.flightlineConflictStrategy
                          .flightDistance
                          " placeholder="" size="small"></el-input>
                      <span style="margin-left: 5px">米</span>
                    </el-col>
                    <el-col :span="8" class="d-c-item" style="width:214px; margin-right: 32px;">
                      <span style="width: 95px">飞行间隔时间</span>
                      <el-input style="width: 80px" v-model="createTaskForm.flightlineConflictStrategy.flightTime
                          " placeholder="" size="small"></el-input>
                      <span style="margin-left: 5px">秒</span>
                    </el-col>
                    <el-col :span="8" class="d-c-item">
                      <span style="width: 65px">终点间距</span>
                      <el-input style="width: 80px" v-model="createTaskForm.flightlineConflictStrategy
                          .destinationDistance
                          " placeholder="" size="small"></el-input>
                      <span style="margin-left: 5px">米</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <!-- 备注 -->
            <div class="task-remark">
              <div class="form-label">
                <span>任务备注</span>
              </div>
              <el-input v-model="createTaskForm.remark" placeholder="请输入任务备注" size="small" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"></el-input>
            </div>
          </div>
        </div>
        <div class="foot">
          <!-- @click="drawerTaskDetail = false" -->
          <el-button class="p" @click="handleCreateNewTaskSubmit">确定</el-button>
        </div>
      </el-drawer>
      <!-- 任务详情抽屉 -->
      <el-drawer title="" :visible.sync="task.drawerTaskDetailVisible" :direction="drawDirection" size="60%" :with-header="false" custom-class="drawer-panel drawer-task draw-create-task draw-create-detail">
        <div class="header">
          <div class="l">
            <img src="@/assets/img/i164.png" alt="" />
            <span>任务详情</span>
          </div>
          <div class="r">
            <img @click="task.drawerTaskDetailVisible = false" src="@/assets/img/i151.png" alt="" />
          </div>
        </div>
        <div class="body">
          <!-- 主任务信息 -->
          <div class="main-task-info" style="margin-bottom: 18px">
            <el-row class="row">
              <el-col :span="8">
                <el-row>
                  <el-col :span="4" class="col label"> 任务名称： </el-col>
                  <el-col :span="10" class="col v">
                    {{ task.taskDetailData.taskName || "" }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :span="4" class="col label"> 任务编号： </el-col>
                  <el-col :span="10" class="col v">
                    {{ task.taskDetailData.id || "" }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :span="6" class="col label"> 创建任务时间： </el-col>
                  <el-col :span="10" class="col v">
                    {{ task.taskDetailData.createTime || "" }}
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8">
                <el-row>
                  <el-col :span="4" class="col label"> 任务类型： </el-col>
                  <el-col :span="10" class="col v">
                    {{ task.taskDetailData.taskType == 'once' ? '单次任务' : '周期任务' }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :span="4" class="col label"> 任务来源： </el-col>
                  <el-col :span="10" class="col v">
                    {{ task.taskDetailData.source == 'default' ? '手动新建' : '警情通知' }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :span="6" class="col label"> 任务目的： </el-col>
                  <el-col :span="8" class="col v">
                    {{ task_getTaskTargetById(task.taskDetailData.taskTarget) }}
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row class="row" v-if="task.taskDetailData.taskType == 'period'">
              <el-col :span="8">
                <el-row>
                  <el-col :span="4" class="col label"> 循环周期： </el-col>
                  <el-col :span="20" class="col v">
                    {{ task.taskDetailData.validStartTime }} — {{ task.taskDetailData.validEndTime }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :span="4" class="col label"> 循环方式： </el-col>
                  <el-col :span="10" class="col v">
                    {{ task_getPeriodDataStr(task.taskDetailData) }}
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">

              </el-col>
            </el-row>
          </div>
          <!-- 中间子任务信息 -->
          <div class="main-sub-task-c">
            <!-- 左侧子任务列表 -->
            <div class="sub-task-list custom-scrollbar">
              <div class="card" v-for="(item, index) in task.taskDetailData.subtaskList" :key="index" @click="task_handleSubTaskSelected(item, index)">
                <div class="r-card" :class="[ task.subTaskSelectedIndex == index ? 'sub-task-selected' : '', ]">
                  <div class=" card-uav card-uav-header">
                    <div class=" card-uav-header1" style="width:100%">
                      <div class="left">
                        <span>子任务编号：{{ item.taskTitle }}</span>
                      </div>
                      <div class="right">
                        <div class="f" style="padding: 3px 5px" :style="{
                        color: statusColor[getTaskStatus(item)],
                        border: '1px solid ' + statusColor[getTaskStatus(item)], }">
                          {{ getTaskStatus(item) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-uav card-uav-info">
                    <img src="@/assets/img/i165.png" alt="" style="width: 27px; height: 27px" />
                    <span class="n">{{
                      task_getDeviceNameByDeviceHardId(item.deviceHardId)
                    }}</span>
                  </div>
                  <div class="card-uav card-uav-date">
                    <img src="@/assets/img/i168.png" alt="" style="width: 24px; height: 27px" />
                    <span class="n">{{
                      task_formatCoord(item.startCoord)
                    }}</span>
                    <img src="@/assets/img/i167.png" alt="" style="width: 34px; height: 10px; margin: 0 10px" />
                    <img src="@/assets/img/i166.png" alt="" style="width: 24px; height: 27px" />
                    <span class="n">{{ task_formatCoord(item.endCoord) }}</span>
                  </div>
                  <div class="card-uav card-uav-plan" :style="task.subTaskSelectedIndex == index ? 'background-image:linear-gradient(to top, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0))': 'background: #163b53;'">
                    <span>计划任务时间：{{ item.startedAt }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右侧子任务信息 -->
            <div class=" sub-task-info">
              <!-- 上部分子任务地图 -->
              <div class="sub-task-map">
                <div class="cesium-map" id="cesiumMap"></div>
              </div>
              <!-- 下部分子任务操作记录 -->
              <div class="sub-task-log">
                <!-- 操作记录 -->
                <div class="s-h">操作记录</div>
                <!-- 表头 -->
                <el-row class="row-h row-odd">
                  <el-col :span="2" class="col col-h">
                    <span>编号</span>
                  </el-col>
                  <el-col :span="16" class="col col-h">
                    <span>操作描述</span>
                  </el-col>
                  <el-col :span="6" class="col col-h">
                    <span>时间</span>
                  </el-col>
                </el-row>
                <!-- 表格体 -->
                <div class="row-c custom-scrollbar">
                  <el-row v-for="(item, index) in task.subTaskLogList" :key="index" class="row" :class="[index % 2 == 0 ? 'row-eve' : 'row-odd']">
                    <el-col :span="2" class="col col-t">
                      <span>{{ index + 1 }}</span>
                    </el-col>
                    <el-col :span="16" class="col col-t">
                      <span>{{ item.details }}</span>
                    </el-col>
                    <el-col :span="6" class="col col-t">
                      <span>{{ item.createTime }}</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部按钮 -->
          <div class="foot">
            <!-- 只有当前任务为执行中的时候才显示取消订单 -->
            <el-button v-if="task.subtaskCancelFlag" class="p" @click="task_handleDrawerTaskDetailCancel">取消订单</el-button>
            <!-- 只有待执行的时候才有立即执行 -->
            <el-button v-if="task.subtaskExecuteFlag" class="p" @click="task_handleDrawerTaskDetailExecute">立即执行</el-button>
            <!-- 执行中，已完成，取消，也就是除了待执行的时候显示 -->
            <el-button v-if="task.subtaskViewFlag" class="p" @click="task_handleDrawerTaskDetailCoordination">协同视图查看</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 新建航线组件，航线编辑 -->
    <div class="create-line-panel" v-if="createLinePanelVisible">
      <CreateAirLine @close="handleCreatLinePanelCancel" :subtaskitem="lineCardItem" :landingFieldInfo="task.landingFieldInfo" :subTaskDetailAirLinePreviewFlag="task.subTaskDetailAirLinePreviewFlag">
      </CreateAirLine>
    </div>
    <!-- 飞行监控 协同视图 -->
    <div class="fly-uav-panel" v-if="flyUvaPanelVisible">
      <Uav :taskDetailData="task.taskDetailData" :whxfSubTaskAirLineList="task.whxfSubTaskAirLineList" :whxfXTSTAirLineSqrt="task.whxfXTSTAirLineSqrt">
      </Uav>
    </div>
    <!-- 警情任务弹出框 -->
    <div class="jq-notice-panel" v-show="jqPanelShowFlag">
      <el-row class="row" v-for="(item, index) in jqTaskNoticeList" :key="index">
        <el-col class="col col-time" :span="8">{{ item.createTime }}</el-col>
        <el-col class="col col-title" :span="11">
          <div class="circle" v-if="!item.needHandle"></div>
          <div class="content-text">
            <el-tooltip class="item" effect="dark" :content="item.noticeTitle" placement="top">
              <span>{{ item.noticeTitle }}</span>
            </el-tooltip>
          </div>
        </el-col>
        <el-col class="col col-btn" :span="5">
          <el-button class="btn" type="success" @click="handleJQTaskCreate(index, item)" v-if="item.needHandle">去处理</el-button>
          <!-- <el-button class="btn-no" type="success" disabled v-else>创建任务</el-button> -->
        </el-col>
      </el-row>
      <el-pagination v-if="0" small class="pagination" :current-page="jqParams.pageNo" @current-change="handleJQTaskPageChange" background layout="prev, pager, next" :total="jqNoticeCount">
      </el-pagination>
    </div>
    <!-- 警情报警页面弹出框 -->
    <div class="jq-notice-alert-panel" v-show="jqNoticeAlertPanelShowFlag">
      <NoticeAlert></NoticeAlert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import data from "./data";
import methods from "./methods";
import Header from "./components/header/index";
import API from "@/api";
// 侧边栏
import SideBar from "../home/components/sideBar";
import { Utils } from "@/lib/cesium";
import dayjs from "dayjs";
import CreateAirLine from "./components/createAirLine";
import Bus from "@/assets/ligature.js";
import _ from "lodash";
import Uav from "../fckernel/uav/index";
import NoticeAlert from "./components/noticeAlert";
// 定时刷新时间标志
let refreshTime = 0;
let beforeExpRow = null;
let cesiumViewer = null;
let cesiumViewerEventHandler = null;
let currentMainRow = null;
let q20UavNameList = [];
let td550UavNameList = [];
// 创建任务的时候，选择无人机列表组合（子任务+接口返回）
// let combinationUvaList = [];
export default {
  components: { Header, SideBar, CreateAirLine, Uav, NoticeAlert },
  inject: ["g_cesium_layer"],
  provide() {
    return {
      uav_This: this,
      findList: (search) => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
        }
      },
      change_page: () => { },
      fn: (...args) => this.uav_fn(...args),
    };
  },
  data() {
    return {
      ...data(),
      loading: true,
      isFullscreenBool: false, //是否是全屏
      formInline: {
        taskName: "",
        deviceName: "",
        status: "",
        taskType: "",
        startTime: "",
        endTime: "",
        rangTime: [],
        pageNo: 1,
        pageSize: 10,
      },
      mainTableTotal: 0,
      taskStatusList: [
        { value: "不限", label: "不限" },
        { value: "0", label: "待生效" },
        { value: "3", label: "执行中" },
        { value: "99", label: "已取消" },
        { value: "1", label: "已完成" },
      ],
      taskTypeSearchList: [
        { value: "不限", label: "不限" },
        { value: "once", label: "单次任务" },
        { value: "period", label: "周期任务" },
      ],
      taskTypeList: [
        { value: "once", label: "单次任务" },
        { value: "period", label: "周期任务" },
      ],
      taskPurposeList: [
        { value: "polling", label: "巡检" },
        { value: "mapping", label: "测绘" },
        { value: "fireFighting", label: "灭火" },
        { value: "search", label: "探查" },
      ],
      statusCount: {},
      drawerTaskDetail: false,
      drawDirection: "rtl",
      textarea: "",
      deviceListForLogisticsListOption: [],
      flyerList: [],
      isExpand: false,
      rowTdBorderBottomWidth: "0px",
      drawerCreateTaskVisible: false,
      // 新建任务表单
      createTaskForm: {
        taskName: "",
        taskType: "once",
        taskTarget: "polling",
        remark: "",
        flightlineConflictStrategy: {
          flightDistance: "",
          flightTime: "",
          destinationDistance: "",
        },
      },
      // 任务列表数据
      lineCardList: [{ id: 0 }],
      // 航线冲突处理数据
      lineConflictDeal: {
        flightSpacing: "",
        flightIntervalTime: "",
        distanceBetweenEndpoints: "",
      },
      // 整个无人机页面隐藏，显示出地图
      pageShow: true,
      // 创建航线面板
      createLinePanelVisible: false,
      tableData: [],
      newTableData: [],
      jingqingData: null,
      // 机巢列表
      nestList: [
        { value: "芜湖机巢1号", label: "芜湖机巢1号" },
        { value: "芜湖机巢2号", label: "芜湖机巢2号", disabled: true },
      ],
      // 无人机型号列表
      deviceTypeList: [],
      // 无人机列表
      deviceList: [],
      //创建任务的时候，当前子任务的索引
      currentLineCardIndex: 0,
      statusColor: {
        待生效: "#FEDF73",
        执行中: "#11FDC4",
        已取消: "#FFFFFF",
        已完成: "#6BD4FF",
      },
      // 周期任务航线id
      periodTaskAirLineId: "",
      // 创建任务/编辑任务标志 0 添加，1 编辑
      createOrEditMainTask: 0, //
      currentMainTaskId: "",
      currentMainTaskIndex: "",
      flyUvaPanelVisible: false,
      lineCardItem: null,
      // 展开行
      expandRows: [],
      // 警情任务列表
      jqTaskNoticeList: [],
      // 警情任务详情
      jqTaskNoticeDetail: {},
      // 创建警情任务标志 0 警情任务， 1 其他任务，默认是其他任务
      jqTaskFlag: 1,
      jqTaskPanelVisible: false,
      jqPanelShowFlag: false,
      jqOriginTaskData: {},
      jqParams: {
        pageNo: 1,
        pageSize: 10,
      },
      jqNoticeCount: 0,
      jqNoticeAlertPanelShowFlag: false,
      selectPurpose: '',
      hideHover: ''
    }
  },
  watch: {
    deep: true,
    jqTaskFlag(newVal, oldVal) {
      console.log("newVal: " + newVal);
      console.log("oldVal: " + oldVal);
      if (newVal == 0) {
        // 创建警情任务的时候，只能创建单次任务
        this.taskTypeList = [
          { value: "once", label: "单次任务" },
          { value: "period", label: "周期任务", disabled: true },
        ];
      } else {
        // 创建警情任务的时候，只能创建单次任务
        this.taskTypeList = [
          { value: "once", label: "单次任务" },
          { value: "period", label: "周期任务", disabled: false },
        ];
      }
    },
    async drawerCreateTaskVisible(newVal, oldVal) {
      if (newVal) {
        q20UavNameList = await API.WHXF.getDeviceListForLogistics(4); //20UAV
        td550UavNameList = await API.WHXF.getDeviceListForLogistics(18); //20UAV
      }
    },
    async jqPanelShowFlag(newVal, oldVal) {
      if (newVal) {
        q20UavNameList = await API.WHXF.getDeviceListForLogistics(4); //20UAV
        td550UavNameList = await API.WHXF.getDeviceListForLogistics(18); //20UAV
      }
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    uav_mounts() {
      let { mounts } = this.uav.control;
      return mounts
        .map((item) => {
          let find_item = this.mount.list.find(
            (m) => m.name === item.gimbalName
          );
          return find_item || undefined;
        })
        .filter((item) => item);
    },
    cssVars() {
      return { "--rowTdBorderBottomWidth": this.rowTdBorderBottomWidth };
    },
    // 实现创建任务的任务名称动态placeholder
    dynamicPlaceholder() {
      // 这里的实现方式真的蠢，但是想用对应的List用数组的find方法来匹配，会出现莫名其妙的错误
      // 果然最简单但是最稳定
      const purpose = this.createTaskForm.taskTarget
      if (purpose == 'polling') {
        this.selectPurpose = '巡检';
      } else if (purpose == 'mapping') {
        this.selectPurpose = '测绘';
      } else if (purpose == 'fireFighting') {
        this.selectPurpose = '灭火';
      } else {
        this.selectPurpose = '探查';
      }
      return `XXXXXX 飞行${this.selectPurpose}任务`;
    },
    // 过滤子任务列表中已经取消的子任务
    lineCardListFilter() {
      let a = this.lineCardList.filter((item) => {
        // console.log('item,过滤', item);
        if (!item?.taskStatus) { //此时为新建一条子任务
          return true;
        }
        if (item.taskStatus == 99) { //此时为编辑，且任务状态为取消，则不参与编辑，不显示
          return false;
        }
        return true;
      });
      return a;
    }
  },
  async mounted() {
    document.title = "任务订单";
    this.uav_list();
    // 主任务状态统计
    this.staticMainTaskStatus();
    // 任务列表
    // this.taskListPage();
    // 飞手列表
    this.getFlyerList();
    // 刷新主任务表格
    this.refreshTable();
    // 无人机起降场下拉列表查询
    this.task.uavLandingFieldList = await API.UAV.uavLandingFieldList();
    // console.log('this.uavLandingFieldList', this.task.uavLandingFieldList);
    // 无人机型号下拉列表
    this.task.uavModelList = await API.UAV.uavModelList();
    // console.log('this.uavModelList', this.task.uavModelList);
    // 赋值航线id
    Bus.$on("whxf_createairline_id", (id) => {
      console.log("新建航线id", id);
      if (this.createTaskForm.taskType == "period") {
        // 周期任务只有一个子任务
        this.periodTaskAirLineId = id;
        this.lineCardList[0].flightLineId = id;
        this.$set(this.lineCardList[0], 'flightLineId', id);
      } else {
        this.lineCardList.forEach((item, index) => {
          if (item.id == this.currentLineCardIndex) {
            item.flightLineId = id;
            // this.lineCardList[index].flightLineId = id;
            this.$set(this.lineCardList, index, item);
            // console.log("更新！！！");
          }
        });
      }
    });
    // 从协同视图返回到订单任务列表
    Bus.$on("backToTaskList", async () => {
      this.createLinePanelVisible = false;
      this.pageShow = true;
      this.task.drawerTaskDetailVisible = false;
      this.flyUvaPanelVisible = false;
      console.log("当前主任务ID", this.currentMainTaskId);
      let a = this.currentMainTaskId;
      // 根据主任务索引，获取主任务详情
      let mainRow = await API.WHXF.getSubTaskList(a);
      console.log("主任务详情", mainRow);
      let indexFlag = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        if (a == this.tableData[i].id) {
          indexFlag = i;
        }
      }
      console.log("indexFlag", indexFlag);
      let b = _.cloneDeep(mainRow.subtaskList);
      this.tableData[indexFlag] = mainRow;
      this.tableData[indexFlag].subtaskList = b;
      this.$set(this.tableData, indexFlag, mainRow);
    });
    // 从协同视图发送过来的主任务id，根据这个id查询主任务详情
    Bus.$on("coordinationViewerGetTaskDetail", async (v) => {
      console.log("完结之后的操作参数", v);
      let mainTaskId = v.mainTaskId;
      let subTask = v.subTask;
      let mainRow = await API.WHXF.getSubTaskList(mainTaskId);
      console.log("主任务详情", mainRow);
      console.log("原始子任务", this.task.taskDetailData.subtaskList);
      //
      this.task.taskDetailData.subtaskList = mainRow.subtaskList;
      this.$set(this.task.taskDetailData, "subtaskList", mainRow.subtaskList);
    });
    // 点击警情任务消息盒子
    Bus.$on("handleJQTaskNoticeShow", () => {
      if (!this.pageShow) {
        return;
      }
      this.jqPanelShowFlag = !this.jqPanelShowFlag;
      if (this.jqPanelShowFlag) {
        // 页码重置为第一页
        this.jqParams.pageNo = 1;
        this.getJQTaskNoticeList();
      }
    });
  },
  beforeDestroy() { },
  methods: {
    ...methods,
    cesium_layer_change_mode(fn, modes) {
      // 定位到芜湖
      Utils.initLocation2WUHU();
      return;
    },
    /**@Description :  ******************************************* 查询数据列表
     **@Date: 2024-03-21 15:55:17
     */
    onSubmitSearch() {
      this.taskListPage();
    },
    /**@Description :  ******************************************* 重置并搜索
     **@Date: 2024-05-29 10:48:14
     */
    onResetSearch() {
      this.$refs["searchForm"].resetFields();
      this.taskListPage();
    },
    /**@Description :  ******************************************* 表格，隔行变色
     **@Date: 2024-05-29 10:48:56
     */
    tableRowClassName({ row, rowIndex }) {
      row.cIndex = rowIndex;
      if ((rowIndex + 1) % 2 != 0) {
        return "oddRow";
      }
      return "evenRow";
    },
    subTableRowClassName({ row, rowIndex }) {
      if ((rowIndex + 1) % 2 != 0) {
        return "oddRow";
      }
      return "evenRow";
    },
    /**@Description :  ******************************************* 查询主任务数据列表
     **@Date: 2024-03-16 09:24:44
     */
    async taskListPage() {
      if (this.formInline.rangTime.length == 0) {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      } else {
        this.formInline.startTime = dayjs(this.formInline.rangTime[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.formInline.endTime = dayjs(this.formInline.rangTime[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (this.formInline.status == "不限") {
        this.formInline.status = "";
      }
      if (this.formInline.taskType == "不限") {
        this.formInline.taskType = "";
      }
      // 数据参数处理
      let data = {};
      if (this.formInline.startTime) {
        data.startTime = this.formInline.startTime;
      }
      if (this.formInline.endTime) {
        data.endTime = this.formInline.endTime;
      }
      if (this.formInline.status) {
        data.status = parseInt(this.formInline.status);
      }
      data.taskName = this.formInline.taskName;
      data.taskType = this.formInline.taskType;
      data.pageNo = this.formInline.pageNo;
      data.pageSize = this.formInline.pageSize;
      data.deviceName = this.formInline.deviceName;

      // 请求到最新的列表数据
      let res = await API.WHXF.List(data);
      let tempTableData = res.records;
      this.mainTableTotal = res.total;
      if (beforeExpRow) { // 要展开的行
        for (let i = 0; i < tempTableData.length; i++) {
          let id = tempTableData[i].id;
          if (id == beforeExpRow.id) {
            let res = await API.WHXF.getSubTaskList(id);
            tempTableData[i] = res;
            this.tableData = tempTableData;
          }
        }
      } else {
        this.tableData = tempTableData;
      }
    },
    /**@Description :  ******************************************* 获取无人机列表
     **@Date: 2024-03-16 14:56:06
     */
    async getDeviceListForLogistics() {
      let res = await API.TASKORDER.getDeviceListForLogistics();
      this.deviceListForLogisticsListOption = res;
    },
    /**@Description :  ******************************************* 获取飞手
     **@Date: 2024-03-16 21:22:48
     */
    async getFlyerList() {
      let res = await API.USER.DrdPilot();
      this.flyerList = res;
      this.task.flyerList = res;
    },
    /**@Description :  ******************************************* 分页-页码变化
     **@Date: 2024-03-18 17:28:10
     */
    handleCurrentChange(v) {
      this.formInline.pageNo = v;
      this.taskListPage();
    },
    /**@Description :  ******************************************* 分页，每一页数据条数变化
     **@Date: 2024-03-18 17:30:56
     */
    handleSizeChange(v) {
      this.formInline.pageNo = 1;
      this.formInline.pageSize = v;
      this.taskListPage();
    },
    /**@Description :  ******************************************* 10s刷新一次数据列表 和 统计数据
     **@Date: 2024-03-19 11:02:45
     */
    refreshTable() {
      let now = new Date().getTime();
      if (now - refreshTime > 1000) {
        this.taskListPage();
        refreshTime = now;
      }
      requestAnimationFrame(this.refreshTable);
    },
    /**@Description :  ******************************************* 点击表格的某一行展开或者关闭
     **@Date: 2024-05-11 09:53:29
     */
    async handleTableRowClick(row, column, event) {
      console.log("row, column, event", row, column, event);
      // 判断是否为周期任务
      // 周期任务不可展开
      if (row.taskType != "once") {
        // 周期任务点击任务类型进入任务详情页面
        // if (column.label == '任务类型') {
        // 根据主任务id先获取到主任务详情，包含子任务列表
        let res = await API.WHXF.getSubTaskList(row.id);
        row.subtaskList = res.subtaskList;
        console.log("res", res);
        console.log("res.subtaskList", res.subtaskList);
        if (res.subtaskList.length == 0) {
          this.$message({
            type: "warning",
            message: "该周期任务没有子任务",
          });
          return;
        } else {
          let firstSubTask = res.subtaskList[0];
          this.task_handleSubTableRowClick(
            0,
            firstSubTask,
            { label: "周期任务" },
            row.cIndex,
            row
          );
        }
        // }
        return;
      }
      if (column.label != "操作") {
        console.log("beforeExpRow", beforeExpRow);
        // 先关闭所有行
        this.tableData.forEach((v) => {
          this.$refs["mainTable"].toggleRowExpansion(v, false);
        });
        this.isExpand = !this.isExpand;
        if (beforeExpRow && row.id != beforeExpRow.id) {
          this.isExpand = true;
        }
        if (this.isExpand) {
          this.rowTdBorderBottomWidth = "0px";
          this.currentMainTaskId = row.id;
          // 根据主任务id获取主任务详情，包含主任务下面的子任务
          let res = await API.WHXF.getSubTaskList(row.id);
          this.tableData[row.cIndex] = res;
          row.subtaskList = res.subtaskList;
        } else {
          this.rowTdBorderBottomWidth = "1px";
        }
        this.$refs["mainTable"].toggleRowExpansion(row, this.isExpand);
        if (this.isExpand) {
          beforeExpRow = row;
        } else {
          beforeExpRow = null;
          this.rowTdBorderBottomWidth = "0px";
        }
      }
    },
    /**@Description :  ******************************************* 创建新任务，非警情任务
     **@Date: 2024-05-11 15:53:47
     */
    async handleCreateNewTask() {
      // 清空上次填好的表单
      this.jqTaskFlag = 1; // 创建非警情任务
      this.createOrEditMainTask = 0; // 新建标志
      this.createTaskForm.taskName = "";
      this.createTaskForm.taskType = "once";
      this.createTaskForm.taskTarget = "polling";
      this.createTaskForm.remark = "";
      // 航线冲突处理
      this.createTaskForm.flightlineConflictStrategy = {
        flightDistance: "",
        flightTime: "",
        destinationDistance: "",
      };
      // 默认必须有一个子任务
      this.lineCardList = [{ id: 0 }];
      this.currentLineCardIndex = 0;
      this.periodTaskAirLineId = "";
      this.drawerCreateTaskVisible = true;
      // 从第二个开始可以删除
      this.task.onceEditSubTaskCount = 1;
      //重置飞手列表，都可以选择
      this.task.flyerList.forEach((v, index) => { v.disabled = false; });
    },
    /**@Description :  ******************************************* 点击添加按钮，添加一个任务卡片
     **@Date: 2024-05-11 17:42:49
     */
    handleAddLineCard() {
      let id = Cesium.createGuid();
      this.lineCardList.push({ id: id });
      // console.log('this.task.onceEditSubTaskCount', this.task.onceEditSubTaskCount);
    },
    /**@Description :  ******************************************* 删除一个任务卡片
     **@Date: 2024-05-11 17:51:57
     */
    handleDeleteLineCard(index, item) {
      // 删除的 deviceHardId
      let deleteDeviceHardId = item.deviceHardId;
      // 删除卡片
      this.lineCardList.splice(index, 1);
    },
    /**@Description :  ******************************************* 点击新增航线
     **@Date: 2024-05-13 11:02:19
     */
    handleAddLine(item) {
      console.log('航线', item);
      // return;
      this.lineCardItem = item;
      this.currentLineCardIndex = item.id;
      // 获取起降场数据
      this.task.landingFieldInfo = item?.landingFieldId ? _.find(this.task.uavLandingFieldList, { 'id': item?.landingFieldId }) : undefined;
      this.drawerCreateTaskVisible = false;
      this.jqTaskPanelVisible = false;
      this.pageShow = false;
      this.createLinePanelVisible = true;
      if (this.createTaskForm.taskType == "once") {
        // 单次任务
        this.$nextTick(() => {
          window.localStorage.setItem("createTaskOnce", JSON.stringify(item));
          window.localStorage.setItem("jqTaskNoticeContent", this.jqOriginTaskData.noticeContent || "");
        });
      }
    },
    /**@Description :  ******************************************* 删除航线
     **@Date: 2024-05-16 11:40:17
     */
    handleDeleteLine(item) {
      console.log("item", item);
      this.$confirm(`确认要删除该航线吗，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // 根据任务id查询航线信息
          let flightLineId = item.flightLineId;
          if (!item.flightLineId) {
            flightLineId = this.periodTaskAirLineId;
          }
          let res = await API.AIRWAY.Delete({ id: flightLineId });
          if (res) {
            item.flightLineId = null;
            this.periodTaskAirLineId = null;
            this.$message({
              type: "success",
              message: "删除成功",
            });
          }
        })
        .catch(() => { });
    },
    /**@Description :  ******************************************* 创建航线面板点击取消
     **@Date: 2024-05-13 14:21:31
     */
    handleCreatLinePanelCancel() {
      // 航线编辑组件
      this.createLinePanelVisible = false;
      // 主界面显示
      this.pageShow = true;
      //如果是子任务详情进入航线编辑，则还需要把子任务给还原回去
      if (this.task.subTaskDetailAirLinePreviewFlag) {
        // 任务详情抽屉显示
        this.task.drawerTaskDetailVisible = true;
      } else {
        if (this.jqTaskFlag == 0) {
          this.jqTaskPanelVisible = true;
        } else {
          this.drawerCreateTaskVisible = true;
        }
      }
    },
    /**@Description :  ******************************************* 获取表格任务类型
     **@Date: 2024-05-13 15:45:44
     */
    getTableColumnTaskType(v) {
      let res = "";
      this.taskTypeList.forEach((item) => {
        if (item.value == v) {
          res = item.label;
        }
      });
      return res;
    },
    /**@Description :  ******************************************* 获取表格任务目的
     **@Date: 2024-05-13 16:29:15
     */
    getTableColumnTaskTarget(v) {
      let res = "";
      this.taskPurposeList.forEach((item) => {
        if (item.value == v) {
          res = item.label;
        }
      });
      return res;
    },
    /**@Description :  ******************************************* dayjs格式化时间
     **@Date: 2024-05-13 16:35:44
     */
    formatDate(v) {
      if (!v) return "";
      return dayjs(v).format("YYYY-MM-DD HH:mm:ss");
    },
    /**@Description :  ******************************************* 创建新建任务或编辑任务
     **@Date: 2024-05-13 18:18:43
     */
    async handleCreateNewTaskSubmit() {
      let createTaskFormCopy = _.cloneDeep(this.createTaskForm);
      console.log("createTaskFormCopy", createTaskFormCopy);
      console.log("this.lineCardList", this.lineCardList);
      // 设置子任务状态
      this.lineCardList.forEach((v) => {
        if (!v.taskStatus) {
          v.taskStatus = 0;
        }
      });
      // console.log("this.lineCardListAfter", this.lineCardList);
      // return;
      createTaskFormCopy.flightlineConflictStrategy = JSON.stringify(
        this.createTaskForm.flightlineConflictStrategy
      );
      createTaskFormCopy.taskRequestObjList = [];
      if (createTaskFormCopy.taskType == "once") {
        // 单次任务的子任务
        this.lineCardList.forEach((item) => {
          let itemCopy = _.cloneDeep(item);
          itemCopy.startedAt = dayjs(itemCopy.startedAt).format("YYYY-MM-DD HH:mm:ss");
          if (this.createOrEditMainTask == 0) {
            String(itemCopy.id) && delete itemCopy.id;
          }
          createTaskFormCopy.taskRequestObjList.push(itemCopy);
        });
      } else {
        //周期任务
        let itemCopy = _.cloneDeep(this.lineCardList[0]);
        let timeJson = {
          day: itemCopy.day,
          hour: itemCopy.hour,
          minute: itemCopy.minute,
        };
        console.log("itemCopy", itemCopy);
        console.log("timeJson", timeJson);
        //循环方式为必填项
        if (
          isNaN(Number(itemCopy.day)) ||
          isNaN(Number(itemCopy.hour)) ||
          isNaN(Number(itemCopy.minute))
        ) {
          this.$message({
            message: "循环方式为必填项且为数字，请重新填写",
            type: "warning",
          });
          return;
        }
        createTaskFormCopy.periodData = JSON.stringify(timeJson);
        createTaskFormCopy.validStartTime = dayjs(
          itemCopy.validStartTime
        ).format("YYYY-MM-DD HH:mm:ss");
        createTaskFormCopy.validEndTime = dayjs(itemCopy.validEndTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        let a = new Date(createTaskFormCopy.validStartTime).getTime();
        let b = new Date(createTaskFormCopy.validEndTime).getTime();
        if (a >= b) {
          this.$message({
            type: "warning",
            message: "起始任务时间不能大于终止任务时间",
          });
          return;
        }
        // "子任务"
        let temp = {
          startedAt: createTaskFormCopy.validStartTime,
          deviceHardId: itemCopy.deviceHardId,
          flyIds: itemCopy.flyIds,
          flightLineId: this.periodTaskAirLineId,
        };
        createTaskFormCopy.taskRequestObjList = [temp];
      }
      // 警情任务创建参数判断 jqTaskFlag 0 是警情任务
      if (this.jqTaskFlag == 1) { // 非警情任务
        createTaskFormCopy.source = "default";
      } else { // 警情任务
        createTaskFormCopy.source = "xiaofang";
        createTaskFormCopy.jingqingId = this.jqOriginTaskData.id;
        console.log("jqOriginTaskData", this.jqOriginTaskData);
        console.log("jqOriginTaskData", this.jqOriginTaskData.jingqingPositions);
        createTaskFormCopy.taskName = this.jqOriginTaskData.noticeContentJSON.alert_content;
        // 如果是警情任务，再添加警情数据！！！
        createTaskFormCopy.jingqingPositions = JSON.stringify(this.jqOriginTaskData);
      }
      let res = null;
      // 编辑
      if (this.createOrEditMainTask == 1) {
        createTaskFormCopy.id = this.currentMainTaskId;
        console.log("编辑任务最终参数", createTaskFormCopy);
        // 校验
        let resCheck = this.handleCreateNewTaskBeforeSubmitCheck(createTaskFormCopy);
        console.log("编辑任务校验结果", resCheck);
        if (resCheck) {
          console.log("createTaskFormCopy 编辑", createTaskFormCopy);
          // 把子任务中的id删除
          createTaskFormCopy.taskRequestObjList.forEach((v) => { v?.id && delete v.id; });
          res = await API.WHXF.editNewTask(createTaskFormCopy);
          console.log("reassess", res);
          // 编辑子任务成功，刷新数据
          let res1 = await API.WHXF.getSubTaskList(this.currentMainTaskId);
          currentMainRow.subtaskList = res1.subtaskList;
          this.$set(this.tableData, this.currentMainTaskIndex, currentMainRow);
          this.$message({
            type: "success",
            message: this.createOrEditMainTask == 0 ? "创建成功" : "修改成功",
          });
          // 关闭抽屉
          this.drawerCreateTaskVisible = false;
          this.jqTaskPanelVisible = false;
          // 如果是周期任务，则需要刷新表格
          // if (this.createTaskForm.taskType != 'once') {
          //   // 刷新任务列表
          //   this.taskListPage();
          // }
          this.taskListPage();
          return;
        } else {
          createTaskFormCopy.flightlineConflictStrategy = JSON.parse(
            this.createTaskForm.flightlineConflictStrategy
          );
          res = null;
        }
      } else {
        // 添加
        // 先校验
        let resCheck = this.handleCreateNewTaskBeforeSubmitCheck(createTaskFormCopy);
        if (resCheck) {
          console.log("createTaskFormCopy 添加", createTaskFormCopy);
          // 把子任务中的id删除
          createTaskFormCopy.taskRequestObjList.forEach((v) => {
            v?.id && delete v.id;
          });
          res = await API.WHXF.addNewTask(createTaskFormCopy);
        } else {
          res = null;
        }
      }
      if (res) {
        this.$message({
          type: "success",
          message: this.createOrEditMainTask == 0 ? "创建成功" : "修改成功",
        });
        // 关闭抽屉
        this.drawerCreateTaskVisible = false;
        this.jqTaskPanelVisible = false;
        // 刷新任务列表
        this.taskListPage();
      }
    },
    /**@Description :  ******************************************* 创建任务前检查参数 true 通过， false 参数有问题不通过
     **@Date: 2024-05-16 13:50:19
     */
    handleCreateNewTaskBeforeSubmitCheck(createTaskFormCopy) {
      // 1 非警情任务 检查任务名称是否存在
      // 2 非警情任务 检查任务类型，单次，循环是否选择
      // 3 子任务检查
      if (this.jqTaskFlag != 0) {
        if (!createTaskFormCopy.taskName) {
          this.$message({
            type: "warning",
            message: "任务名称为空，请输入",
          });
          return false;
        }
      }
      if (this.jqTaskFlag != 0) {
        if (!createTaskFormCopy.taskType) {
          this.$message({ type: "warning", message: "任务类型为空，请选择" });
          return false;
        }
      }
      for (let i = 0; i < createTaskFormCopy.taskRequestObjList.length; i++) {
        let item = createTaskFormCopy.taskRequestObjList[i];
        // 3.1 检查无人机deviceHardId
        if (!item.deviceHardId) {
          this.$message({
            type: "warning",
            message: "子任务无人机为空，请选择",
          });
          return false;
        }
        // 3.2 检查飞手 flyIds 飞手不是必填项
        // if (item.flyIds.length == 0) {
        //   this.$message({
        //     type: 'warning',
        //     message: '子任务飞手为空，请选择',
        //   });
        //   return false;
        // }
        // 3.2 检查起飞时间 startedAt
        if (!item.startedAt) {
          this.$message({
            type: "warning",
            message: "子任务起飞时间为空，请选择",
          });
          return false;
        }
        // 3.2.1 检查起飞时间不能在之前
        let now = new Date().getTime();
        let startedAt = new Date(item.startedAt).getTime();
        if (startedAt < now) {
          this.$message({
            type: "warning",
            message: "子任务起飞时间不能小于当前时间，请重新选择",
          });
          return false;
        }
        // 3.3 检查航线 flightLineId
        if (!item.flightLineId) {
          this.$message({
            type: "warning",
            message: "子任务航线为空，请创建",
          });
          return false;
        }
      }
      return true;
    },
    /**@Description :  ******************************************* 创建任务，起降场下拉选择事件
     **@Date: 2024-05-13 19:01:23
     */
    async handleSelectUavLand(v, item) {
      console.log("创建任务，机巢下拉选择事件", v, item);
    },
    /**@Description :  ******************************************* 创建任务，无人机型号选择下拉事件
     **@Date: 2024-05-13 19:04:17
     */
    async handleSelectUavModel(v, item) {
      console.log('创建任务，无人机型号选择下拉事件', v, item);
      // 无人机名称要重置
      item?.deviceHardId && (item.deviceHardId = '');
      // let flag = v == 'Q20' ? 4 : 18;
      // 如果是创建任务，则直接就是接口获取即可 如果是编辑任务，则需要拼接子任务已经占用的无人机
      // let freeList = await API.WHXF.getDeviceListForLogistics(v);
      // console.log('接口获取无人机列表', freeList);

      // if (this.createOrEditMainTask == 0) {
      //   this.task.combinationUvaList = this.task_handleCombinationUavList( freeList, this.lineCardList );
      // } else {
      //   this.task.combinationUvaList = this.task_handleCombinationUavList(freeList, this.lineCardList);
      // }
    },
    /**@Description :  ******************************************* 创建任务，无人机名称选择下拉事件
     **@Date: 2024-05-17 19:42:09
     */
    async handleSelectUavDevice(v, item) {
      console.log('创建任务，无人机名称选择下拉事件', v, item);
      // this.task.combinationUvaList = this.task_handleCombinationUavList(this.task.combinationUvaList, this.lineCardList);
      // return;
    },
    /**@Description :  ******************************************* 创建任务，飞手选择事件
     **@Date: 2024-05-17 23:32:27
     */
    handleSelectFlyer(v, item) {
      console.log('创建任务，飞手选择事件', v, item);
      console.log('this.task.flyerList', this.task.flyerList);
      // for (let i = 0; i < this.lineCardList.length; i++) {
      //   let selectedItem = this.lineCardList[i];
      //   let flyIds = selectedItem.flyIds;
      //   console.log('flyIds', flyIds);
      //   flyIds.forEach((flyId) => {
      //     let flyer = _.find(this.task.flyerList, { 'id': flyId });
      //     if (flyer) {
      //       flyer.disabled = true;
      //     }
      //   });
      // }
    },
    /**@Description :  ******************************************* 根据id查询子任务列表
     **@Date: 2024-05-13 20:24:50
     */
    async getSubTaskList(id) {
      let res = await API.WHXF.getSubTaskList(id);
    },
    /**@Description :  ******************************************* 新建任务，任务类型，选择单次任务还是周期任务
     **@Date: 2024-05-14 09:29:47
     */
    handleSelectTaskType(v) {
      this.lineCardList = [{ id: 0 }];
      this.currentLineCardIndex = 0;
      this.periodTaskAirLineId = "";
    },
    /**@Description :  ******************************************* 获取起点或者终点
     **@Date: 2024-05-14 13:50:14
     */
    getLonLatFromFlightCourseJson(v, flag) {
      console.log("获取起点或者终点", v, flag);
      let res = "";
      if (v) {
        let line = JSON.parse(v);
        let points = line.points;
        if (flag == 0) {
          res =
            "(" +
            parseFloat(points[0].lon).toFixed(4) +
            "," +
            parseFloat(points[0].lon).toFixed(4) +
            ")";
        }
        if (flag == 1) {
          let len = points.length;
          res =
            "(" +
            parseFloat(points[len - 1].lon).toFixed(4) +
            "," +
            parseFloat(points[len - 1].lon).toFixed(4) +
            ")";
        }
      }
      return res;
    },
    /**@Description :  ******************************************* 获取子任务的任务状态
     **@Date: 2024-05-14 14:54:18
     */
    getTaskStatus(row) {
      let val = row;
      let text = "";
      if (val.taskStatus == 0) {
        text = "待生效";
      } else if (val.taskStatus == 3) {
        text = "执行中";
      } else if (val.taskStatus == 99) {
        text = "已取消";
      } else if (val.taskStatus == 1) {
        text = "已完成";
      }
      return text;
    },
    /**@Description :  ******************************************* 编辑主任务
     **@Date: 2024-05-14 16:31:29
     */
    async handleDetailMainTask(index, row) {
      console.log('编辑主任务', index, row);
      // 先获取子任务列表
      let res = await API.WHXF.getSubTaskList(row.id);
      console.log('res', res);
      this.task.combinationUvaList = [];
      res.subtaskList.forEach((subTaskItem) => {
        let temp = { 'deviceHardId': subTaskItem.deviceHardId, 'deviceName': subTaskItem.deviceName };
        this.task.combinationUvaList.push(temp);
      });
      this.drawerCreateTaskVisible = true;
      if (row.source == "default") {
        this.jqTaskFlag = 1;
      } else {
        this.jqOriginTaskData.id = row.jingqingId;
        this.jqOriginTaskData.jingqingPositions = JSON.parse(res.jingqingPositions);
        this.jqTaskFlag = 0;
      }
      this.createOrEditMainTask = 1;
      this.currentMainTaskId = row.id;
      this.currentMainTaskIndex = index;
      currentMainRow = row;
      // 回显数据
      this.createTaskForm.taskName = row.taskName;
      this.createTaskForm.taskType = row.taskType;
      this.createTaskForm.taskTarget = row.taskTarget;
      this.createTaskForm.flightlineConflictStrategy = JSON.parse(row.flightlineConflictStrategy);
      this.createTaskForm.remark = row.remark;
      this.createTaskForm.source = row.source;
      if (row.taskType == "period") { //周期任务
        this.lineCardList = [];
        let temp = {};
        let deviceTypeList = [
          { value: "Q20", label: "Q20" },
          { value: "TD550", label: "TD550" },
        ];
        temp.deviceList = q20UavNameList;
        console.log("temp.deviceList", temp.deviceList);
        temp.deviceTypeList = deviceTypeList;
        temp.flyerList = this.flyerList;
        temp.validStartTime = row.validStartTime;
        temp.validEndTime = row.validEndTime;
        let timeJson = JSON.parse(row.periodData);
        temp.day = timeJson.day;
        temp.hour = timeJson.hour;
        temp.minute = timeJson.minute;
        temp.nest = "芜湖机巢1号"; //不需要写入数据库
        temp.deviceId = "Q20"; // 不需要写入数据库
        // 根据主任务id 获取子任务
        // let res = await API.WHXF.getSubTaskList(row.id);
        this.periodTaskAirLineId = res.subtaskList[0].flightLineId;
        temp.deviceHardId = res.subtaskList[0].deviceHardId;
        let flyIds = this.getFlyerIdByName(res.subtaskList[0].team);
        temp.flyIds = flyIds;
        temp.taskTitle = res.subtaskList[0].taskTitle,//把子任务的任务名称也添加进去，子任务列表需要
          this.lineCardList.push(temp);
      } else {
        // 单次任务
        // let res = await API.WHXF.getSubTaskList(row.id);
        this.task.onceEditSubTaskCount = res.subtaskList.length;
        this.lineCardList = [];
        res.subtaskList.forEach((item) => {
          let flyIds = this.getFlyerIdByName(item.team);
          let temp = {
            id: item.id,
            flyIds: flyIds,
            startedAt: item.startedAt,
            flightLineId: item.flightLineId,
            goodsId: item.goodsId,
            landingFieldId: item.landingFieldId,
            deviceHardId: item.deviceHardId,
            deviceName: item.deviceName, //把无人机对应的名称也添加进去，无人机下拉列表需要
            taskTitle: item.taskTitle,//把子任务的任务名称也添加进去，子任务列表需要
            taskStatus: item.taskStatus, //把子任务的任务状态也添加进去，子任务列表需要，子任务状态返回字段是taskStatus
          };
          console.log('temp', temp);
          this.lineCardList.push(temp);
        });
      }
    },
    /**@Description :  ******************************************* 主任务状态统计
     **@Date: 2024-05-15 09:09:41
     */
    async staticMainTaskStatus() {
      let res = await API.WHXF.staticMainTaskStatus();
      this.statusCount = res;
    },
    /**@Description :  ******************************************* 初始化cesium地图
     **@Date: 2024-05-15 13:42:36
     */
    initCesiumViewer(points, row) {
      this.$nextTick(() => {
        if (!cesiumViewer) {
          Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
            75.0,
            20.0,
            130.0,
            60.0
          );
          cesiumViewer = new Cesium.Viewer("cesiumMap", {
            animation: false, //是否显示动画控件
            shouldAnimate: true,
            homeButton: false, //是否显示Home按钮
            fullscreenButton: false, //是否显示全屏按钮
            baseLayerPicker: false, //是否显示图层选择控件
            geocoder: false, //是否显示地名查找控件
            timeline: false, //是否显示时间线控件
            sceneModePicker: true, //是否显示投影方式控件
            navigationHelpButton: false, //是否显示帮助信息控件
            infoBox: false, //是否显示点击要素之后显示的信息
            requestRenderMode: true, //启用请求渲染模式
            maximumRenderTimeChange: Infinity,
            scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
            sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
            fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
            selectionIndicator: false,
            imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
              url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33",
              layer: "img",
              style: "default",
              format: "tiles",
              tileMatrixSetID: "c",
              credit: new Cesium.Credit("天地图全球影像服务"),
              tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
              subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
              tileMatrixLabels: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
              ],
              // 最大层级改为17,避免在任务详情视口中无线滚动导致无法加载缩放级别的影像图
              maximumLevel: 17,
            }),
            skyBox: false, //不显示天空盒
            orderIndependentTranslucency: true, //如果为true，并且配置支持它，则使用顺序无关的半透明性。
            contextOptions: { webgl: { alpha: true } },
            shadows: false, // 关闭阴影
            targetFrameRate: 60, //设置最大频率数
            useBrowserRecommendedResolution: false,
          });
          cesiumViewerEventHandler = new Cesium.ScreenSpaceEventHandler(cesiumViewer.canvas);
        }
        // 变成手势
        cesiumViewer._element.style.cursor = 'pointer'; // 将鼠标样式设置为默认
        cesiumViewer.entities.removeAll();
        if (points.length == 0) {
          return;
        }
        let pList = [];
        points.forEach((item) => {
          // console.log('子任务详情item', item);
          let lon = item.lon;
          let lat = item.lat;
          let alt = item.alt;
          let position = Cesium.Cartesian3.fromDegrees(lon, lat, alt);
          pList.push(position);
          let en = new Cesium.Entity({
            position: position,
            point: {
              pixelSize: 8,
              color: Cesium.Color.WHITE,
              outlineColor: Cesium.Color.fromCssColorString("#2FA1C8"),
              outlineWidth: 3,
            },
          });
          cesiumViewer.entities.add(en);
        });
        // 修改为起降场
        // 从起降场下拉列表中获取起降场数据
        let landingField = _.find(this.task.uavLandingFieldList, { 'id': row.landingFieldId });
        if (landingField) {
          let p = Cesium.Cartesian3.fromDegrees(landingField.longitude || 0, landingField.latitude || 0);
          let en0 = new Cesium.Entity({
            position: pList[0],
            billboard: {
              image: `image/s3.png`,
              pixelOffset: new Cesium.Cartesian2(0, -22),
              width: 32,
              height: 41,
              scale: 1,
              // scaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
              // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
              //   90000,
              //   1.5,
              //   660000,
              //   0.4
              // ),
            },
          });
          cesiumViewer.entities.add(en0);
          let en01 = new Cesium.Entity({
            position: pList[0],
            billboard: {
              image: `image/s3bg.png`,
              pixelOffset: new Cesium.Cartesian2(0, -55),
              width: 80,
              height: 22,
              scale: 1.2,
              // scaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
              // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
              //   90000,
              //   1.5,
              //   660000,
              //   0.4
              // ),
            },
            label: {
              text: "芜湖机巢1号",
              font: "12px PangMenZhengDao bold", //字体样式
              fillColor: Cesium.Color.WHITE, //字体颜色
              // backgroundColor: Cesium.Color.fromCssColorString("#2FABFF 0%,#008AE7 72%"), //背景颜色
              // showBackground: true, //是否显示背景颜色
              style: Cesium.LabelStyle.FILL, //label样式
              pixelOffset: new Cesium.Cartesian2(0, -56),
              // scaleByDistance: new Cesium.NearFarScalar(90000, 1.5, 660000, 0.4),
              // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
              //   90000,
              //   1.5,
              //   660000,
              //   0.4
              // ),
            },
          });
          cesiumViewer.entities.add(en01);
        }
        let enLine = new Cesium.Entity({
          polyline: {
            positions: pList,
            width: 16,
            show: true,
            material: new Cesium.PolylineGlowMaterialProperty({
              glowPower: 0.1,
              color: Cesium.Color.fromCssColorString("#2FA1C8"),
            }),
          },
        });
        cesiumViewer.entities.add(enLine);
        // 如果是警情任务，还得把警情点添加上来
        this.addPointFromOriginJQTask(cesiumViewer);
        cesiumViewer.flyTo(enLine, {
          offset: new Cesium.HeadingPitchRange(
            Cesium.Math.toRadians(0.0),
            Cesium.Math.toRadians(-25.0),
            0
          ),
        });
        // 点击事件
        cesiumViewerEventHandler.setInputAction(async (event) => {
          //点击就跳转
          this.task_gotoAirLineEditor();

          // let pick = cesiumViewer.scene.pick(event.position);
          // if (Cesium.defined(pick)) {
          //   console.log('pick', pick);
          //   // 跳转到航线编辑
          //   this.task_gotoAirLineEditor();
          // }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      });
    },
    /**@Description :  ******************************************* 获取无人机列表
     **@Date: 2024-05-29 11:09:40
     */
    async getDeviceListForLogistics() {
      let res = await API.TASKORDER.getDeviceListForLogistics();
      this.deviceListForLogisticsListOption = res;
    },
    /**@Description :  ******************************************* 根据飞手名称获取飞手id
     **@Date: 2024-05-16 11:19:50
     */
    getFlyerIdByName(nameArray) {
      let res = [];
      for (let i = 0; i < nameArray.length; i++) {
        let a = _.find(this.flyerList, (item) => item.title == nameArray[i]);
        res.push(a.id);
      }
      return res;
    },
    /**@Description :  ******************************************* 控制展开行
     **@Date: 2024-05-29 11:10:36
     */
    handleExpandChange(row, expandedRows) {
      if (expandedRows.length > 0) {
        this.expandRows = [];
      }
      expandedRows.forEach((item) => {
        this.expandRows.push(item.id);
      });
    },
    /**@Description :  ******************************************* 获取警情任务列表
     **@Date: 2024-05-18 11:21:18
     */
    async getJQTaskNoticeList() {
      let res = await API.WHXF.getJQTaskNoticeList(this.jqParams);
      this.jqNoticeCount = res.total;
      this.jqTaskNoticeList = res.records;
    },
    /**@Description :  ******************************************* 创建警情任务按钮点击事件，去处理
     **@Date: 2024-05-18 11:48:39
     */
    handleJQTaskCreate(index, item) {
      //隐藏icon警情按钮背景
      this.hideHover = false
      Bus.$emit('closeBg', this.hideHover)
      // console.log("创建警情任务按钮点击事件", index, item);
      // 显示创建警情任务面板
      this.jqTaskPanelVisible = true;
      // 警情任务标志
      this.jqTaskFlag = 0;
      // 将该条警情任务赋值给原始警情数据
      this.jqOriginTaskData = item;
      // 隐藏顶部警情任务面板
      this.jqPanelShowFlag = false;
      // 解析警情任务信息
      this.jqOriginTaskData.noticeContentJSON = JSON.parse(item.noticeContent);
      // 清空上次填好的表单
      this.createOrEditMainTask = 0;
      this.createTaskForm.taskName = "";
      this.createTaskForm.taskType = "once";
      this.createTaskForm.taskTarget = "polling";
      this.createTaskForm.remark = "";
      this.createTaskForm.flightlineConflictStrategy = {
        flightDistance: "",
        flightTime: "",
        destinationDistance: "",
      };
      this.lineCardList = [{ id: 0 }];
      this.currentLineCardIndex = 0;
      this.periodTaskAirLineId = "";
      // 处理任务目的
      this.createTaskForm.taskTarget = this.jqOriginTaskData.noticeContentJSON.alert_type;
      // 从第二个开始可以删除
      this.task.onceEditSubTaskCount = 1;
      //重置飞手列表，都可以选择
      this.task.flyerList.forEach((v, index) => { v.disabled = false; });
    },
    /**@Description :  ******************************************* 警情消息分页
     **@Date: 2024-05-21 12:03:32
     */
    handleJQTaskPageChange(v) {
      this.jqParams.pageNo = v;
      this.getJQTaskNoticeList();
    },
    /**@Description :  ******************************************* 获取警情任务 alert_id alert_time
     **@Date: 2024-05-21 18:34:20
     */
    getJQAlertId(jqOriginTaskData) {
      // if (jqOriginTaskData.noticeContent) {
      //   return JSON.parse(jqOriginTaskData.noticeContent).alert_id
      // } else {
      //   return '';
      // }
      return jqOriginTaskData?.id || "暂无";
    },
    /**@Description :  ******************************************* 获取警情任务的工单时间
     **@Date: 2024-05-21 18:36:11
     */
    getJQDate(jqOriginTaskData) {
      if (jqOriginTaskData.noticeContent) {
        return JSON.parse(jqOriginTaskData.noticeContent).alert_time;
      } else {
        return "";
      }
    },
    /**@Description :  ******************************************* 获取警情任务的警情内容
     **@Date: 2024-05-21 18:41:20
     */
    getJQContent(jqOriginTaskData) {
      if (jqOriginTaskData.noticeContent) {
        return JSON.parse(jqOriginTaskData.noticeContent).alert_content;
      } else {
        return "";
      }
    },
    /**@Description :  ******************************************* 向地图上添加警情任务的坐标点
     **@Date: 2024-05-19 10:37:25
     */
    async addPointFromOriginJQTask(cesiumViewer) {
      // 根据警情任务id查警情详情
      let mainTable = this.tableData[this.currentMainTaskIndex];
      console.log("mainTable", mainTable);
      if (mainTable.source == "xiaofang") {
        let jingqingPositions = JSON.parse(mainTable.jingqingPositions);
        console.log("jingqingPositions", jingqingPositions);
        let noticeContent = jingqingPositions.noticeContent;
        noticeContent = JSON.parse(noticeContent);
        console.log("noticeContent", noticeContent);
        let alert_positions = noticeContent.alert_positions;
        alert_positions.forEach((v, index) => {
          let pos = v.value.split(",");
          let lon = parseFloat(pos[0]);
          let lat = parseFloat(pos[1]);
          let c1 = Cesium.Cartesian3.fromDegrees(lon, lat);
          let en1 = new Cesium.Entity({
            position: c1,
            label: {
              text: v.label,
              font: "8px PingFang bold", //字体样式
              fillColor: Cesium.Color.WHITE, //字体颜色
              backgroundColor: Cesium.Color.fromCssColorString(
                "rgba(184, 78, 47, 0.88)"
              ), //背景颜色
              showBackground: true, //是否显示背景颜色
              style: Cesium.LabelStyle.FILL, //label样式
              backgroundPadding: new Cesium.Cartesian2(5, 3),
              pixelOffset: new Cesium.Cartesian2(0, -42),
              scaleByDistance: new Cesium.NearFarScalar(
                90000,
                1.5,
                660000,
                0.4
              ),
              pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
                90000,
                1.5,
                660000,
                0.4
              ),
            },
            billboard: {
              image: `image/s2.png`,
              pixelOffset: new Cesium.Cartesian2(0, -20),
              width: 32,
              height: 41,
              scale: 0.5,
              scaleByDistance: new Cesium.NearFarScalar(
                90000,
                1.5,
                660000,
                0.4
              ),
              pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
                90000,
                1.5,
                660000,
                0.4
              ),
            },
          });
          cesiumViewer.entities.add(en1);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/uavWHXFScoped.scss";
</style>
<style lang="scss">
@import "~@/styles/wuhuCommon.scss";

.r-table {
  .oddRow {
    background-color: #27385a !important;
  }

  .evenRow {
    background-color: #2d446a !important;
  }
}

.drawer-task {
  background-color: #0a293a;

  .header {
    height: 54px;
    background: #224d68;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .l {
      height: 100%;
      width: 30%;
      // border: 1px solid #ff0000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 0 30px;

      img {
        width: 21px;
        height: 21px;
        opacity: 100%;
      }

      span {
        width: 64px;
        height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .r {
      height: 100%;
      width: 20%;
      // border: 1px solid #ff0000;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 0 30px;

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  .body {
    width: 100%;
    height: calc(100% - 163px);
    // border: 1px solid #ff0000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 18px;
    padding: 0 12px;
    box-sizing: border-box;
    background-color: #0a293a;

    .order-info,
    .task-schedu {
      width: 100%;
      height: 44%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid #9b4b4b;
      background-color: #163b53;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
      padding: 10px 20px;
      flex-direction: column;

      .h {
        width: 100%;
        height: 40px;
        border-radius: 0px 0px 0px 0px;
        // border: 1px solid #ff0000;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-image: url("~@/assets/img/i153.png");
        background-repeat: no-repeat;
        background-size: 100% 1%;
        background-position: 0px 38px;

        img {
          width: 3px;
          height: 16px;
        }

        span {
          width: 64px;
          height: 22px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-left: 5px;
        }
      }

      .b {
        width: 100%;
        height: calc(100% - 40px);
        border-radius: 0px 0px 0px 0px;
        // border: 1px solid #ff0000;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        .ll {
          width: 70%;
          height: 100%;

          // border: 1px solid #ff0000;
          .info-text {
            color: #ffffff;
          }

          .row {
            width: 100%;
            height: 18%;
            display: flex;
            align-items: center;
            justify-content: center;

            .label {
              // width: 60px;
              // height: 17px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.68);
              line-height: 14px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }

            .text-v {
              // width: 56px;
              // height: 20px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              line-height: 16px;
              text-align: right;
              font-style: normal;
              text-transform: none;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }

          .row-remark {
            height: 25%;
            // border: 1px solid #ff0000;

            /deep/ .el-textarea__inner {
              background: rgba(10, 41, 58, 0.36) !important;
              border-radius: 4px 4px 4px 4px !important;
              border: 1px solid rgba(255, 255, 255, 0.2) !important;
            }
          }
        }

        .m {
          width: 28%;
          height: 100%;
          // border: 1px solid #ff0000;

          #orderInfoMapId,
          #taskScheduMapId,
          #detailOrderInfoMapId {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .task-schedu {
      height: 55%;

      .line {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        height: 100%;

        img {
          width: 24px;
          height: 24px;
        }

        .btn-p {
          width: 44px;
          height: 29px;
          background: #495e93;
          border-radius: 4px 4px 4px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 14px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          border: 0;
        }

        .btn-d {
          width: 68px;
          height: 29px;
          background: rgba(58, 212, 224, 0.08);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(58, 212, 224, 0.55);
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #3ad4e0;
          line-height: 14px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          // border: 0;
        }

        .btn-v {
          width: 56px;
          height: 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(58, 212, 224, 0.91);
          line-height: 16px;
          text-align: right;
          font-style: normal;
          text-transform: none;
          background-color: transparent;
          border: 0;
        }
      }
    }

    .detail-task-schedu {
      .f {
        width: 66px;
        height: 28px;
        // background: rgba(0, 255, 163, 0.13);
        border-radius: 4px 4px 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        span {
          // font-family: PingFang SC, PingFang SC;
          // font-weight: 400;
          // font-size: 14px;
          // color: #19E210;
          // text-align: center;
          // font-style: normal;
          // text-transform: none;
          // display: flex;
          // align-items: center;
          // justify-content: center;
        }
      }

      .line-1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
          width: 40px;
          margin-top: -22px;
        }
      }

      .b {
        .ll {
          // border: 1px solid #ff0000;
          width: 35% !important;
        }

        .m {
          width: 62%;
          // border: 1px solid #ff0000;

          .o-l-g {
            width: 100%;
            height: 100%;
            background-color: #133144;
            box-sizing: border-box;
            padding: 10px;

            .h {
              width: 100%;
              height: 10%;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              line-height: 16px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              letter-spacing: 2px;
            }

            .c-h {
              width: 100%;
              height: 10%;
              // border: 1px solid #ce9a9a;

              .h-r {
                width: 100%;
                height: 100%;
                // border: 1px solid #ff0000;
                background: rgba(99, 177, 245, 0.1);

                .h-c {
                  // border: 1px solid #ff0000;
                  // width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .h-t {
                    color: #ffffff;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    width: 100% !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }

            .c-b {
              width: 100%;
              height: 80%;
              // border: 1px solid #ff0000;
              overflow: auto;

              .h-r {
                width: 100%;
                height: 15%;
                // border: 1px solid #ff0000;
                background: rgba(99, 177, 245, 0.1);

                .h-c {
                  // border: 1px solid #ff0000;
                  // width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .h-t {
                    color: #ffffff;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }

              .r-odd {
                background-color: #1b3e56;
              }

              .r-ever {
                background-color: #133144;
              }
            }
          }
        }
      }

      .taskStatus {
        // width: 62px;
        // height: 28px;
        // border-radius: 23px 23px 23px 23px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }

      .taskStatus-label {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }

      // 待审核
      .taskStatus-2 {
        background: rgba(255, 184, 0, 0.13);
        color: #ff9a0e;
      }

      // 待派发
      .taskStatus-1 {
        background: rgba(255, 214, 0, 0.13);
        color: #ffde6b;
      }

      // 待执行
      .taskStatus-0 {
        background: rgba(0, 255, 163, 0.13);
        color: #19e210;
      }

      // 执行中
      .taskStatus-3 {
        background: rgba(0, 255, 194, 0.13);
        color: #00ffc2;
      }

      // 执行完成
      .taskStatus-11 {
        background: rgba(0, 194, 255, 0.13);
        color: #4ecaff;
      }

      // 待收货
      .taskStatus-5 {
        background: rgba(230, 255, 74, 0.13);
        color: #e6ff4a;
      }

      // 已取消
      .taskStatus-99 {
        background: rgba(255, 254, 249, 0.12);
        color: rgba(255, 255, 255, 0.76) !important;
      }
    }
  }

  .foot {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 36px;

    .c,
    .p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      border: 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      border-radius: 4px 4px 4px 4px;
    }

    .c {
      background: #133d54;
    }

    .p {
      background: #007aff;
    }
  }
}

.draw-create-task {
  .create-task {
    width: 100%;
    height: 100%;
    // border: 0.1px solid red;
    position: relative;

    .task-n-t-d {
      width: 896px;
      // height: 130px;
      // border: 0.1px solid red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // flex-direction: column;
      margin: 16px 24px 0px 24px;
      box-sizing: border-box;

      .row {
        width: 100%;
        // height: 36%;
        height: 38px;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .row-title {
        height: 15%;
        margin-bottom: 5px;
      }

      .col {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // border: 1px solid red;
        width: 32%;
      }

      .input-name {
        // width: 30%;
        // margin-left: 12px;
        margin-right: 12px;
        width: auto;
      }

      .input-task {
        // width: 60%;
        // margin-left: 12px;
        // margin-right: 12px;
        width: auto;
      }

      .form-label {
        color: #ffffff;
        font-size: 14px;
        // width: 85px;
        width: auto;
        margin-right: 8px;
      }
    }

    .task-card-c {
      max-height: calc(100% - 254px);
      // width: 96%;
      width: 896px;
      // border: 1px solid red;
      overflow-y: auto;
      overflow-x: hidden;
      margin-left: 24px;
      box-sizing: border-box;
      padding-bottom: 10px;
      // background-color: #1b506e;
      margin-top: 20px;

      .sub-title {
        height: 20px;
        width: 100%;
        // border:1px solid #ff0000;
        color: #ffffff;
        font-size: 15px !important;
        margin-bottom: 15px;
      }

      .card-list {
        width: 100%;
        height: 136px;

        // border: 1px solid rgb(255, 255, 255);
        border-radius: 4px;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding: 10px 0;
        position: relative;
        background-color: #163b53;
        display: flex;
        align-items: center;
        justify-content: center;

        .left {
          width: 37px;
          height: 136px;
          // background-color: #007AFF;
          // background-color: #15465d;
          background: rgba(0, 245, 255, 0.06);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-family: Arial, Arial;
          font-weight: bold;
          font-size: 18px;
          color: rgba(255, 255, 255, 0.88);
          text-align: left;
          font-style: normal;
          text-transform: none;
          position: absolute;
          left: 0;
          border-radius: 4px 0px 0px 4px;
        }

        .right {
          width: calc(100% - 37px);
          height: 95%;
          // background-color: #00FFC2;
        }

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50%;

          .col {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid red;
          }
        }

        .delete-card {
          height: 15px;
          width: 15px;
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: #c0c0c0;
          color: #ffffff;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          box-sizing: border-box;
          padding: 5px;
        }
        // 添加子任务编号
        .task-title {
          height: 15px;
          width: 315px;
          position: absolute;
          top: 8px;
          left: 5px;
          // border:1px solid #ff0000;
          color: #ffffff;
          font-size: 12px;
        }
      }

      .create-btn {
        height: 20px;
        height: 30px;
        // border: 1px dashed #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
        background-color: #163b53;
        border-radius: 4px;
      }

      .line-conflict-deal {
        height: 30px;
        width: 100%;
        // background-color: #19E210;
        margin-top: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          width: 15%;
          // border: 1px solid red;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.88);
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .d-c {
          width: 80%;

          // border: 1px solid red;
          .d-c-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }

      .form-label {
        color: #ffffff;
        font-size: 12px;
        margin-right: 10px;
      }

      .add-line-btn {
        width: 74px;
        height: 29px;
        background: #235675;
        border-radius: 4px 4px 4px 4px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #00f5ff;
        line-height: 29px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .del-line-btn {
        // width: 48px;
        // height: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.76);
        // line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .task-remark {
      height: 120px;
      // width: 100%;
      // width: 96%;
      width: 896px;
      margin-left: 24px;
      // border: 1px solid red;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .form-label {
        position: relative;
        width: 80px;
        // height: 100%;
        // border: 1px solid red;
        margin-top: -50px;

        span {
          // position: absolute;
          top: 3px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.88);
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }

    .el-input.is-disabled .el-input__inner {
      color: #ffffff;
      border: 1px solid #1f6888;
      background-color: #163b53;
    }
  }
}

.draw-create-detail {
  .body {
    height: calc(100% - 74px);
    width: 100%;
    box-sizing: border-box;
    // border: 1px solid #ff0000;
    background-color: #0a293a;

    .main-task-info {
      // height: 80px;
      min-height: 80px;
      width: 100%;
      // border: 1px solid #ff0000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .row {
        // height: 50%;
        // border:1px solid #ff0000;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .label {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.68);
          line-height: 14px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .v {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }

    .main-sub-task-c {
      height: calc(100% - 140px);
      width: 100%;
      // border: 1px solid #5eff00;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #0a293a;

      .sub-task-list {
        width: 39%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        // border: 1px solid #ff0000;
        background-color: #133144;

        .card {
          width: 100%;
          height: 166px;
          background-color: #133144;

          margin: 10px 0;
          // border: 1px solid red;
          box-sizing: border-box;
          padding: 0 10px;

          .r-card {
            width: 100%;
            height: 100%;

            .card-uav {
              height: 25%;
              width: 100%;
              // border:1px solid #ff0000;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background: #163b53;
              box-sizing: border-box;
              padding: 0 10px;
            }

            .card-uav-header {
              height: 25%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .card-uav-header1 {
                width: 339px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                .left {
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #ffffff;
                  line-height: 16px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                }

                .right {
                  margin-right: 0px;
                }
              }
            }

            .card-uav-info {
              .n {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ffffff;
                line-height: 16px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-left: 10px;
              }
            }

            .card-uav-date {
              background-color: #234d69;

              .n {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ffffff;
                line-height: 16px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-left: 10px;
              }
            }

            .card-uav-plan {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              line-height: 14px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }
        }

        .sub-task-selected {
          //border: 2px solid rgba(40, 120, 255, 0.8);
          border: 1px solid rgba(255, 255, 255, 0.7);
          // border-radius: 4px;
          background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 0.12),
            rgba(255, 255, 255, 0)
          );
        }
      }

      .sub-task-info {
        width: 60%;
        height: 100%;

        // border: 1px solid #ff0000;
        .sub-task-map {
          width: 100%;
          height: 40%;

          // border: 1px solid #ff0000;
          .cesium-map {
            width: 100%;
            height: 100%;
          }
        }

        .sub-task-log {
          width: 100%;
          height: 60%;
          box-sizing: border-box;
          padding: 5px 15px;
          background-color: #133144;

          // border: 1px solid #ff0000;
          .s-h {
            width: 100%;
            height: 60px;
            color: #ffffff;
            // background-color: #00FFC2;
            line-height: 60px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            font-style: normal;
            text-transform: none;
          }

          .row-h {
            height: 40px;
            // border: 1px solid #ff0000;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .col {
              // width: 100%;
              height: 100%;
              text-align: center;
              font-family: PingFang SC, PingFang SC;
              color: #ffffff;
              line-height: 16px;
              font-style: normal;
              text-transform: none;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .col-h {
              font-weight: 600;
              font-size: 16px;
            }
          }

          .row-c {
            height: calc(100% - 100px);
            overflow-y: auto;
            overflow-x: hidden;

            .row {
              height: 40px;
              // border: 1px solid #ff0000;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .col {
                text-align: center;
                font-family: PingFang SC, PingFang SC;
                color: #ffffff;
                line-height: 16px;
                font-style: normal;
                text-transform: none;
              }

              .col-t {
                font-weight: 500;
                font-size: 14px;
              }
            }
          }

          .row-odd {
            background-color: #1b3e56;
          }

          .row-eve {
            background-color: #133144;
          }
        }
      }
    }

    .foot {
      height: 60px;
      width: 100%;
      // border: 1px solid #ff0000;
    }
  }
}
</style>
